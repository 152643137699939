import { useEffect, useState } from "react";
import styles from "./table.module.scss";
import { v4 as uuidv4 } from 'uuid';

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { columns, createColumns } from "./columns";
import { FooterCell } from "./FooterCell";
import useStudents from "./useStudents";
import { Button } from "../../components";
import { ReactComponent as SaveIcon } from "../../../assests/svg/save_close.svg";
import { ReactComponent as CopyIcon } from "../../../assests/svg/copy_data.svg";
import { ReactComponent as NextArrowIcon } from "../../../assests/svg/next_arrow_fill.svg";
import { ReactComponent as InfoIcon } from "../../../assests/svg/i_icon.svg";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  ASSESSMENT_MODAL_NAMES,
  ASSESSMENT_STATUS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../utils/constants";
import { API_ROUTE } from "../../../utils/api";
import LoadingHoc from "../../components/LoadingHoc";
import { useQueryClient } from "react-query";
import { useNavigate, useNavigation } from "react-router-dom";
import Tooltip from "../ToolTip";
// import DeleteProcess from "../ModalPopups/AssessmentModals/DeleteProcess";

export const EditableQuestionTable = ({
  questions,
  answers,
  articleIndex,
  articlesLength,
  setEntryComplete,
  setArticleIndex,
  handleChangeArticleDropdown,
  article,
  assessmentData
}: any) => {
  console.log("answers in editale question table", answers);

  const assessmentStatus:any = assessmentData?.assessment_status ? assessmentData?.assessment_status : "Pending";
  const assessment_id:any = assessmentData?.assessment_id;
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const {
    //   data: originalData,
    //   isValidating,
    addRow,
    updateRow,
    deleteRow,
  }: any = useStudents();

  const [data, setData] = useState<any[]>([]);
  const [editedRows, setEditedRows] = useState({});
  const [validRows, setValidRows] = useState({});
  const [submitType, setSubmitType] = useState("");

  const [showDeleteModal, setDeleteModal] = useState<any>({
    state: false,
    modalName: "",
    data: null,
    type: "DELETE",
  });

  console.log("data in editable table", data);
  const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const orgData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  const { data: processesData, isLoading: processesIsLoading }: any =
    useApiQuery({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_PROCESSES}/${orgData?.org_id}/${assessment_id}`,
      queryKey: RQ_KEYS.ALL_PROCESSES,
    });

  console.log("processess data", processesData, processesIsLoading);

  const tableHeaders =
    answers?.length > 0
      ? Object.keys(answers[0])
          ?.filter((header: any) => {
            return header !== "process_name" && header !== "id" && header !== "assessment_id";
          })
          .map((header: any) => {
            const foundQues = questions.find(
              (ques: any) => ques.question === header
            );

            if (foundQues && foundQues?.options?.length > 0) {
              return { header, options: foundQues.options };
            } else if (foundQues?.question_type === "LIST_PROCESS") {
              return {
                header,
                options: processesData?.map((process: any) => ({
                  option_text: process.process_name,
                })),
              };
            } else {
              return { header, options: [] };
            }
          })
      : [];

  console.log("table Headers", tableHeaders);

  useEffect(() => {
    // if (processesData?.length > 0) {
    //   const newData = processesData
    //     .toSorted((a: any, b: any) => a.row_no - b.row_no)
    //     .map((tData: any) => ({
    //       process_id: tData.process_id,
    //       "process name": tData.process_name,
    //       description: tData.process_description,
    //       "number of employees": tData.no_of_employees,
    //     }));
    // setData(newData);
    // setData(answers);
    // }

    console.log("answers =>", answers);
    if (answers?.length > 0) {
      let newData = answers?.toSorted((a: any, b: any) => a.row_no - b.row_no);

      console.log("new Data +++>", newData, article);

      if (article?.article_question_type === "TABLE_TYPE_ADD_ROW") {
        newData = newData.filter((data: any) => data.id);
        setData(newData || []);
      } else {
        setData(Object.values(newData[0])[1] ? newData : []);
      }
    }
  }, [answers, article]);

  const table = useReactTable({
    data,
    columns: createColumns(tableHeaders, assessmentStatus),
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
    meta: {
      editedRows,
      setEditedRows,
      validRows,
      setValidRows,
      revertData: (rowIndex: number) => {
        setData((old) => old.map((row: any, index: number) => row));
      },
      updateRow: (rowIndex: number) => {
        updateRow(data[rowIndex].id, data[rowIndex]);
        setData((old: any) =>
          old.map((r: any, index: number) => {
            if (index === rowIndex) {
              return data[rowIndex];
            } else {
              return r;
            }
          })
        );
      },
      updateData: (
        rowIndex: number,
        columnId: string,
        value: string,
        isValid: boolean
      ) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
        setValidRows((old: any) => ({
          ...old,
          [rowIndex]: { ...old[rowIndex], [columnId]: isValid },
        }));
      },
      addRow: () => {
        console.log("in add row", data);
        // const id = Math.floor(Math.random() * 10000);
        const id = uuidv4();
        const newRow: any = {
          id,
        };
        addRow(newRow);
        setData([...data, newRow]);
      },
      removeRow: (rowIndex: number) => {
        setDeleteModal({
          state: true,
          modalName: ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT,
          type: "DELETE",
          data: {
            id: data[rowIndex].id,
            process_id: data[rowIndex].process_id,
            deleteRow,
            tableData: data,
            setData,
            rowIndex,
          },
        });
      },
    },
  });

  const meta: any = table?.options?.meta;

  const { mutate: postTableAnswers, isLoading: postTableLoading } =
    _useMutation({
      onSuccess: (data: any) => {
        setSubmitType("");
        console.log("data in on success posttableAnswer", data);
        // queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_PROCESSES }).then();
        queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS }).then();
        queryClient.refetchQueries({ queryKey: RQ_KEYS.CHAPTER_BY_IDS }).then();
        queryClient.refetchQueries({ queryKey: RQ_KEYS.QUESTIONS }).then();
        queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_PROCESSES }).then();

        if (data?.data === "next") {
          if (articleIndex === articlesLength - 1) {
            // if (data.data === "submit") {
            //   setEntryComplete(true);
            // }
            navigate(
              `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
                questions?.[0]?.assessment_id
              )} `
            );
          } else {
            // setArticleIndex((prev: any) => {
            //   // localStorage.setItem("articleIndex", prev + 0);
            //   return prev + 1;
            // });
            handleChangeArticleDropdown(articleIndex + 1);
          }
        }
      },
      queryKey: RQ_KEYS.POST_TABLE_ANSWERS,
    });

  const handleSubmitTable = (type: string) => {
    console.log("handle submit table", data, userData, orgData, questions);
    setSubmitType(type);
    const question = questions[0];

    const allHeaders = tableHeaders.map(headerObj => headerObj.header);
  
    const updatedData = data.map((row: any) => {
      const newRow = { ...row };
  
      allHeaders.forEach(header => {
        if (!newRow.hasOwnProperty(header) || newRow[header] === "" || newRow[header] === null || newRow[header] === undefined) {
          newRow[header] = "-"; // Add "-" for missing or empty values
        }
      });
  
      return {
        ...newRow,
        process_name: newRow[allHeaders[0]],
      };
    });

    const dataToSend = {
      assign_assessment_id: question?.assessment_id,
      assign_chapter_id: question?.chapter_id,
      assign_article_id: question?.article_id,
      assigned_by_user_id: question?.assigned_by_user_id,
      assigned_to_user_id: question?.assigned_to_user_id,
      assigned_status: question?.assigned_status,
      user_org_id:userData?.user_org_id,
      // answer: data.map((row: any) => {
      //   return {
      //     ...row,
      //     process_name: Object.values(row)[1],
      //   };
      // }),
      answer: updatedData,
      answer_created_at: new Date(),
      due_date: question?.due_date,
      assigned_at: question?.assigned_at,
    };

    console.log("Data to send:",dataToSend);

    const isListProcess = questions.every(
      ({ question_type }: any) => question_type === "LIST_PROCESS"
    );

    postTableAnswers({
      url: API_ROUTE.POST_TABLE_ANSWERS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        tableData: dataToSend,
        isListProcess,
        type,
        assigned_status: "Saved",
      },
    });
  };

  const handleAddRow = () => {
    meta?.addRow();

    setEditedRows((prev) => ({ ...prev, [data.length]: true }));
  };

  const shouldShowTooltip = (header: any) => {
    const question = questions.find((q: any) => q.question === header);
    return question?.question_details || false;
  };

  // Function to get tooltip text for a header
  const getTooltipText = (header: any) => {
    const question = questions.find((q: any) => q.question === header);
    return question?.question_details || "";
  };

  return (
    <LoadingHoc isLoading={processesIsLoading}>
      <article className={styles.tableContainer}>
        <div className={styles.tableQuestionContainer}>
          <div>
            This section includes questions {questions[0]?.question_number} -{" "}
            {questions[questions.length - 1]?.question_number}.
          </div>
          <table>
            <tfoot>
              <tr>
                {article?.article_question_type === "TABLE_TYPE_ADD_ROW" && (
                  <th
                    colSpan={table.getCenterLeafColumns().length}
                    align="right"
                  >
                   {(userData?.role!==APP_ROLES.AUDITOR && userData?.role!==APP_ROLES.OBOEDIO_ADMIN && assessmentStatus !== ASSESSMENT_STATUS.COMPLETED ) && <FooterCell table={table} handleAddRow={handleAddRow} />}
                  </th>
                )}
              </tr>
            </tfoot>
          </table>
        </div>
        <table className={styles.table}>
          <thead className={styles.tableHeading}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      {shouldShowTooltip(header.column.columnDef.header) && (
                        <Tooltip text={getTooltipText(header.column.columnDef.header)} tooltipStyle={{float:'right'}} rightSide>
                          <InfoIcon style={{ height: "12px", marginLeft: "2px", marginBottom: "4px", }} />
                        </Tooltip>
                      )}  
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.tableBody}>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
        {data?.length <= 0 && (userData?.role !== APP_ROLES.AUDITOR && userData?.role!==APP_ROLES.OBOEDIO_ADMIN)  &&(
          <p
            style={{
              paddingTop: 24,
              textAlign: "center",
            }}
          >
            Click 'Add New Row' to add data.
          </p>
        )}
      </article>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 10,
          // position: "fixed",
          right: "10px", // Adjust position as needed
          bottom: "10px", // Adjust position as needed
          zIndex: 1000,
        }}
      >
        {article?.article_question_type === "TABLE_TYPE_ADD_ROW" && (userData?.role!==APP_ROLES.AUDITOR && userData?.role!==APP_ROLES.OBOEDIO_ADMIN) && assessmentStatus !== ASSESSMENT_STATUS.COMPLETED &&(
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              padding: "10px 0px",
            }}
          >
            
              
               <Button
              id="addNewBtn"
              label="+ Add New Row"
              background="var(--secondaryColor)"
              color="var(--whiteColor)"
              onClick={handleAddRow}
            />
            
          </div>
        )}
        {data?.length > 0 && (userData?.role!==APP_ROLES.AUDITOR && userData?.role!==APP_ROLES.OBOEDIO_ADMIN) && assessmentStatus !== ASSESSMENT_STATUS.COMPLETED && (
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              padding: "10px 0px",
              gap: 10,
            }}
          >
            <Button
              id="saveNextQuestionBtn"
              label="Save & Next"
              background="var(--secondaryColor)"
              color="var(--whiteColor)"
              disabled={postTableLoading && submitType === "next"}
              loading={
                (processesIsLoading || postTableLoading) &&
                submitType === "next"
              }
              Icon={<NextArrowIcon fill="#ffffff" className="svg_image_icon" />}
              onClick={() => handleSubmitTable("next")}
            />
            <Button
              id="saveQuestionBtn"
              label="Save"
              background="var(--secondaryColor)"
              color="var(--whiteColor)"
              disabled={postTableLoading && submitType === "save"}
              loading={
                (processesIsLoading || postTableLoading) &&
                submitType === "save"
              }
              Icon={<CopyIcon fill="white" className="svg_image_icon" />}
              onClick={() => handleSubmitTable("save")}
            />
          </div>
        )}

      </div>
      {/* {showDeleteModal &&
        showDeleteModal.state &&
        showDeleteModal.modalName ===
          ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT && (
          <DeleteProcess
            openState={showDeleteModal}
            heading="Delete Process"
            type={showDeleteModal?.type}
            data={showDeleteModal.data}
            onClickCancel={() => {
              setDeleteModal((prev: any) => ({ ...prev, state: false }));
            }}
            onSuccess={onDeleteSuccess}
          />
        )} */}
    </LoadingHoc>
  );
};
