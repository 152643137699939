import React, { useEffect, useMemo, useState } from "react";
import styles from "./style.module.scss";
import { MultiSelect, InputField } from "../../../components";
import { IInviteUser } from "../../../../interfaces/IAssessmentModals";
import { Button } from "../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { AssessmentInitialValue, AddAssessmentSchema } from "./validator";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS, generateYears, APP_ROLES } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { useQueryClient } from "react-query";
import Toast from "../../../components/Toast";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import LoadingHoc from "../../../components/LoadingHoc";
import { Modal } from "@mui/material";
import {
getAllCountries,
getUSstate,
} from "../../../../utils/countriesContenent";

const AddAssessmentByAdmin: React.FC<IInviteUser> = ({
  onClickCancel,
  openState,
  heading,
  user_org_id,
  // data: orgData,
  data,
  assignedAssessmentIds,
}) => {
  console.log(
    "user_org_id <><><>",
    data
  );
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const userList: any = _getQueryState({ key: RQ_KEYS.USER_LIST });
  const { mutate: getUserList } = _useMutation({ queryKey: RQ_KEYS.USER_LIST });

  const regionList = useApiQuery({
      queryKey: RQ_KEYS.REGIONS,
      requestType: REQUEST_TYPE._GET,
      url: API_ROUTE.GET_REGIONS,
    });
  
  const getAssessmentsByRegion = _useMutation({
      queryKey: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
    });

    useEffect(() => {
      getAssessmentsByRegion.mutate({
        url: API_ROUTE.GET_FILTERED_ASSESSMENT_LIST,
        requestType: REQUEST_TYPE._POST,
        requestData: {
          filterRegion: null,
          filterJudiriction: null,
          filterCategory: null,
          filterIndustries: null,
        },
      });
    }, []); 
    
  const { mutate: assignAssessmentToUser , isLoading:assignedAssessmentLoading} = _useMutation({
      onSuccess: ({ message }: any) => {
        // queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        //   message: message,
        //   toastType: "SUCCESS",
        // });
        // onClickCancel();
        onCloseModal();
      },
    });

  const onSuccess = async (response:any) => {
    console.log("<< response >>", response);
    if(response?.response?.data.status === false){
      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: response?.response?.data?.message,
        toastType: "ERROR",
      });
    }
    queryClient.resetQueries({ queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID });
    queryClient.invalidateQueries({ queryKey: RQ_KEYS.ORG_ADMIN_BY_ID });
    queryClient.invalidateQueries({
      queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID_CATEGORIZED,
    });
    // queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
    if(response?.response?.data.status === false){
      setTimeout(() => {
        queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
        // onClickCancel();
        onCloseModal();
      }, 2000)
    }else{
      queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
      // onClickCancel();
      onCloseModal();
    }
  };
  const addAssessment = _useMutation({ onSuccess });
  // const getAssessmentsByRegion = _useMutation({
  //   queryKey: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
  // });

  let organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  organizationData = organizationData
    ? organizationData
    : { ...data, branch_locations: data?.location };

  console.log({ organizationData });

  const organizationDataLoading = _getQueryState({
    key: `${RQ_KEYS.USER_ORGANIZATION}_loading`,
  });

  const categoryList = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_CATEGORIES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_CATEGORY,
  });
  let assessments: any = _getQueryState({
    key: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
  });

  const departments = useApiQuery({
      queryKey: RQ_KEYS.DEPARTMENT_LIST,
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_DEPARTMENT_LIST}`,
    });

  // assessments = assessments?.filter(
  //   (assessment: any) =>
  //     !assignedAssessmentIds?.includes(assessment.assessment_id)
  // );

  const renderRoles = useMemo(() => {
      if (
        userAuthData &&
        userAuthData.role &&
        (userAuthData.role === APP_ROLES.ORG_ADMIN || userAuthData.role === APP_ROLES.OBOEDIO_ADMIN)
      ) {  
        return [
          { label: "Org User", value: APP_ROLES.ORG_USER },
          { label: "Branch Admin", value: APP_ROLES.BRANCH_ADMIN },
          { label: "Vendor User", value: APP_ROLES.VENDOR_USER },
        ];
      } 
      else {
        return [];
      }
    }, [userAuthData]);

  console.log("{assessments", assessments);

  const isLoading: any = _getQueryState({
    key: RQ_KEYS.LOADING,
  });
  const onChangeFields = (values: any) => {
    getAssessmentsByRegion.mutate({
      url: API_ROUTE.GET_FILTERED_ASSESSMENT_LIST,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        filterRegion: values?.region ? [values?.region] : null,
        filterJudiriction: values?.country
          ? [{ country: values?.country, state: values?.state }]
          : null,
        filterCategory: values?.assessment_catgory
          ? [values?.assessment_catgory]
          : null,
        filterIndustries: values?.assessment_industry
          ? [values?.assessment_industry]
          : null,
      },
    });
  };

  const onCloseModal = () =>{
    getAssessmentsByRegion.mutate({
      url: API_ROUTE.GET_FILTERED_ASSESSMENT_LIST,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        filterRegion: null,
        filterJudiriction: null,
        filterCategory: null,
        filterIndustries: null,
      },
    });

    onClickCancel();
  }

  return (
    <Modal
      open={openState}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.invitePopupRow1}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onCloseModal}
            ></img>
          </div>
          <Formik
            initialValues={AssessmentInitialValue}
            validationSchema={AddAssessmentSchema}
            // onSubmit={async (values) => {
            //   console.log("<< On Submit values of Assign Assessment: >>",values);
            //   await addAssessment.mutate({
            //     url: API_ROUTE.ASSIGN_ORG_ASSESSMENT,
            //     requestType: REQUEST_TYPE._PATCH,
            //     requestData: {
            //       assessment_ids: values.assessments.map(
            //         (assessment: any) => assessment.value
            //       ),
            //       assessment_type_ids: values.assessments.map(
            //         (assessment: any) =>
            //           assessments.find((a: any) => a.assessment_id === assessment.value)
            //             ?.assessment_type_id
            //       ).filter((id: any) => id !== undefined),
            //       org_id:
            //         organizationData.user_org_id || organizationData.org_id,
            //       assigned_to_user_id: organizationData?.org_admin_id,
            //       department_id:values.department,
            //       department_name: departments?.data?.data?.find(
            //         (dept:any) => dept.department_id === values.department
            //       )?.department_name,
            //       assessment_year: values.assessmentYear,
            //     },
            //   });
            //   // onClickCancel();
            // }}

            onSubmit={async (values) => {
              console.log("<< On Submit values of Assign Assessment: >>", values);
              await addAssessment.mutate({
                url: API_ROUTE.ASSIGN_ORG_ASSESSMENT,
                requestType: REQUEST_TYPE._PATCH,
                requestData: {
                  assessment_ids: values.assessments.map(
                    (assessment: any) => assessment.value
                  ),
                  assessment_type_ids: values.assessments.map(
                    (assessment: any) =>
                      assessments.find((a: any) => a.assessment_id === assessment.value)
                        ?.assessment_type_id
                  ).filter((id: any) => id !== undefined),
                  org_id:
                    organizationData.user_org_id || organizationData.org_id,
                  assigned_to_user_id: organizationData?.org_admin_id,
                  department_id: values.department ? values.department : 0,
                  department_name: departments?.data?.data?.find(
                    (dept: any) => dept.department_id === values.department
                  )?.department_name,
                  assessment_year: values.assessmentYear,
                },
              }, {
                onSuccess: (response: any) => {
                  console.log("ASSIGN_ORG_ASSESSMENT response:", response, values);
                  if (response?.data?.assessmentData && values?.assigned_to_user_id) {
                    console.log("<<< Enterd in if condition: >>>", response?.data?.assessmentData);
                    // For each assessment in the response data
                    const assignmentPromises = response?.data?.assessmentData.map((item: any) => {
                      return assignAssessmentToUser({
                        url: API_ROUTE.ASSSIGN_ASSESSMENT_TO_USER,
                        requestType: REQUEST_TYPE._POST,
                        requestData: {
                          assigned_to_user_id: values.assigned_to_user_id,
                          due_date: values.due_date,
                          assessment_id: item.assign_assessment_id
                        },
                      });
                    });
                    
                    Promise.all(assignmentPromises)
                      .then(() => {
                        onSuccess(response);
                      })
                      .catch((error) => {
                        console.error("Error assigning assessments to users:", error);
                        queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
                          message: "Failed to assign assessments to users",
                          toastType: "ERROR",
                        });
                      });
                  } else {
                    onSuccess(response);
                  }
                }
              });
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <div className={styles.invitePopupRow2}>
                <Form>
                  <div className={styles.userLogin}>
                    {/* {JSON.stringify(values)} */}
                    {toastState && toastState.message && (
                      <Toast toastState={toastState} />
                    )}
                    <div className={`${styles.userLoginInner} ${styles.full}`}>
                      <div className={styles.orgRegionsParent}>
                        <LoadingHoc isLoading={organizationDataLoading}>
                          <div className={styles.orgRegions}>
                            <div className={styles.editField}>
                              <MultiSelect
                                label="Region"
                                name="region"
                                placeholder="Select"
                                isRequired={false}
                                // options={
                                //   data &&
                                //   data?.location?.length > 0 && [
                                //     { value: "Worldwide", label: "Worldwide" },
                                //     ...data?.location
                                //       ?.map(({ region }: any) => {
                                //         return {
                                //           value: region,
                                //           label: region,
                                //         };
                                //       })
                                //       .reduce(
                                //         (accumulator: any, current: any) => {
                                //           if (
                                //             !accumulator.find(
                                //               (item: any) =>
                                //                 item.value === current.value
                                //             )
                                //           ) {
                                //             accumulator.push(current);
                                //           }
                                //           return accumulator;
                                //         },
                                //         []
                                //       )
                                //   ]
                                // }
                                options={regionList?.data?.length > 0
                                  ? regionList.data
                                  : []}
                                values={
                                  values.region
                                    ? {
                                        value: values?.region,
                                        label: values?.region,
                                      }
                                    : ""
                                }
                                setFieldValue={setFieldValue}
                                onChange={(selectedValue: any) => {
                                  onChangeFields({
                                    ...values,
                                    region: selectedValue,
                                    country:null
                                  });
                                  setFieldValue("country", null);
                                  setFieldValue("assessments", []);
                                }}
                                isMulti={false}
                                isDisabled={!data}
                              ></MultiSelect>
                            </div>
                            {/* {values?.region && ( */}
                            <div className={styles.editField}>
                              <MultiSelect
                                label="Country"
                                name="country"
                                placeholder="Select"
                                isRequired={false}
                                isDisabled={!values.region}
                                // options={
                                //   data?.branch_locations?.length > 0
                                //     ? data?.branch_locations
                                //         ?.filter(
                                //           ({ region, country}: any) =>
                                //             region === values.region && 
                                //             country && 
                                //             country !== "N/A"
                                //         )
                                //       .map(({ country }: any) => {
                                //           return {
                                //             value: country,
                                //             label: country,
                                //           };
                                //         })
                                //         .reduce(
                                //           (accumulator: any, current: any) => {
                                //             if (
                                //               !accumulator.find(
                                //                 (item: any) =>
                                //                   item.value === current.value
                                //               )
                                //             ) {
                                //               accumulator.push(current);
                                //             }
                                //             return accumulator;
                                //           },
                                //           []
                                //         )
                                //     : []
                                // }

                                options={values.region
                                  ? getAllCountries(values.region)?.map(({ value, name }: { value: string; name: string }) => ({
                                    value,
                                    label: name,
                                  })) || []
                                  : []}
                                values={
                                  values.country
                                    ? {
                                        value: values?.country,
                                        label: values?.country,
                                      }
                                    : ""
                                }
                                setFieldValue={setFieldValue}
                                onChange={(selectedValue: any) => {
                                  onChangeFields({
                                    ...values,
                                    country: selectedValue,
                                  });
                                }}
                                isMulti={false}
                              ></MultiSelect>
                            </div>
                            {values?.country === "United States" && (
                              <div className={styles.editField}>
                                <MultiSelect
                                  label="State"
                                  name="state"
                                  placeholder="Select"
                                  isRequired={false}
                                  isDisabled={!values.region}
                                  options={organizationData?.branch_locations
                                    ?.filter(
                                      ({ region }: any) =>
                                        region === values.region
                                    )
                                    .map(({ state }: any) => {
                                      return {
                                        value: state,
                                        label: state,
                                      };
                                    })
                                    .reduce(
                                      (accumulator: any, current: any) => {
                                        if (
                                          !accumulator.find(
                                            (item: any) =>
                                              item.value === current.value
                                          )
                                        ) {
                                          accumulator.push(current);
                                        }
                                        return accumulator;
                                      },
                                      []
                                    )}
                                  values={
                                    values.state
                                      ? {
                                          value: values?.state,
                                          label: values?.state,
                                        }
                                      : ""
                                  }
                                  setFieldValue={setFieldValue}
                                  onChange={(selectedValue: any) => {
                                    onChangeFields({
                                      ...values,
                                      state: selectedValue,
                                    });
                                  }}
                                  isMulti={false}
                                ></MultiSelect>
                              </div>
                            )}
                            {/* )} */}
                            <div className={styles.editField}>
                              <MultiSelect
                                label="Category"
                                name="assessment_catgory"
                                placeholder="Select"
                                isRequired={false}
                                isDisabled={!values.region}
                                options={
                                  categoryList &&
                                  categoryList.data &&
                                  categoryList.data.length > 0
                                    ? categoryList.data
                                    : []
                                }
                                values={
                                  values?.assessment_catgory
                                    ? {
                                        label: values?.assessment_catgory,
                                        value: values?.assessment_catgory,
                                      }
                                    : null
                                }
                                setFieldValue={setFieldValue}
                                onChange={(selectedValue: any) => {
                                  onChangeFields({
                                    ...values,
                                    assessment_catgory: selectedValue,
                                  });
                                }}
                                isMulti={false}
                              ></MultiSelect>
                            </div>
                            
                            <div className={styles.addField} >                            
                            

                            <div className={styles.editField}>
                              <MultiSelect
                                label="Select Assessment Year"
                                name="assessmentYear"
                                placeholder="Select Year"
                                isRequired={true}
                                options={generateYears(20)}
                                values={
                                  values?.assessmentYear
                                    ? { value: values?.assessmentYear, label: values?.assessmentYear }
                                    : null
                                }
                                setFieldValue={setFieldValue}
                                isMulti={false}
                              />
                            </div>
                              {userAuthData.role !== APP_ROLES.OBOEDIO_ADMIN && <div className={styles.editField}>
                                <MultiSelect
                                  label="Select Department"
                                  name="department"
                                  placeholder="Select Department"
                                  menuPlacement="top"
                                  includeNone={true}
                                  options={
                                    departments?.data?.data && departments?.data?.data.length > 0
                                      ? departments?.data?.data?.map(
                                        ({
                                          department_name,
                                          department_id,
                                        }: any) => {
                                          console.log("Department Details:", department_name, department_id);
                                          return {
                                            value: department_id,
                                            label: department_name,
                                          };
                                        }
                                      )
                                      : []
                                  }
                                  values={
                                    departments?.data?.data && values?.department && values?.department != 0
                                      ? {
                                        value: values.department,
                                        label: departments?.data?.data.find(
                                          (dept: any) => String(dept.department_id) === String(values.department)
                                        )?.department_name || ''
                                      }
                                      : 0
                                  }
                                  setFieldValue={setFieldValue}
                                  onChange={(selectedDepartment: any) => {
                                    console.log("<<< values.role >>>", values.role);
                                    if (values.role) {
                                      getUserList({
                                        url: `${API_ROUTE.GET_ORG_USERS_BY_ROLE}?role=${values.role}&&department=${selectedDepartment}&&org_id=${data?.org_id}`,
                                        requestType: REQUEST_TYPE._GET,
                                      });
                                    }
                                    setFieldValue("assigned_to_user_id", '');
                                  }}
                                  // onChange={handleChange}
                                  isMulti={false}
                                ></MultiSelect>
                              </div>}
                            </div>
                            {userAuthData.role !== APP_ROLES.OBOEDIO_ADMIN && <div className={styles.addField}>
                              <div className={styles.editField}>
                                <MultiSelect
                                  label="Select Role"
                                  name="role"
                                  placeholder="Select"
                                  isRequired={values.assigned_to_user_id || values.due_date}
                                  includeNone={true}
                                  options={renderRoles}
                                  onChange={(selectedRole: any) => {
                                    setFieldValue("role", selectedRole);
                                    setFieldValue("assigned_to_user_id", "");
                                    getUserList({
                                      url: `${API_ROUTE.GET_ORG_USERS_BY_ROLE}?role=${selectedRole}&&department=${values.department}&&org_id=${data?.org_id}`,
                                      requestType: REQUEST_TYPE._GET,
                                    });

                                  }}
                                  // values={
                                  //   values.role
                                  //     ? { value: values.role, label: values.role }
                                  //     : null
                                  // }
                                  isMulti={false}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                              <div className={styles.editField}>
                                <MultiSelect
                                  label="Select Assignee"
                                  name="assigned_to_user_id"
                                  placeholder="Select"
                                  isRequired={values.role || values.due_date}
                                  options={
                                    Array.isArray(userList) && userList.length > 0
                                      ? userList.map(({ user_name, user_id }) => ({
                                        label: user_name,
                                        value: user_id,
                                      }))
                                      : []
                                  }
                                  values={
                                    values.assigned_to_user_id
                                      ? {
                                        value: values.assigned_to_user_id,
                                        label: Array.isArray(userList)
                                          ? userList.find(user => user.user_id === values.assigned_to_user_id)?.user_name || ''
                                          : '',
                                      }
                                      : null
                                  }
                                  isDisabled={!values.role || !Array.isArray(userList) || userList.length === 0}
                                  setFieldValue={setFieldValue}
                                  isMulti={false}
                                />
                              </div>
                            </div>}
                            {userAuthData.role !== APP_ROLES.OBOEDIO_ADMIN &&
                              <div className={styles.addField}>
                                <div className={styles.editField}>
                                  <InputField
                                    type="Date"
                                    placeholder={"Select Due Date"}
                                    isRequired={Boolean(values.role) || Boolean(values.assigned_to_user_id)}
                                    name="due_date"
                                    label="Due Date"
                                    display="flex"
                                    onChange={handleChange}
                                    alignItems="flex-start"
                                    gap="5px"
                                    fontWeight="400"
                                    onBlur={handleBlur}
                                    min={new Date().toISOString().split("T")[0]}
                                    value={values.due_date}
                                  />
                                </div>
                              </div>}
                            

                            <MultiSelect  
                              label="Select Assessment"
                              name="assessments"
                              placeholder="Select"
                              menuPlacement='top'
                              isRequired={true}
                              isDisabled={
                                !assessments ||
                                assessments?.length === 0 
                                // || !values.region
                              }
                              options={
                                assessments && assessments.length > 0
                                  ? assessments?.map(
                                      ({
                                        assessment_name,
                                        assessment_id,
                                      }: any) => {
                                        return {
                                          value: assessment_id,
                                          label: assessment_name,
                                        };
                                      }
                                    )
                                  : []
                              }
                              values={values?.assessments}
                              setFieldValue={setFieldValue}
                              // onChange={handleChange}
                              isMulti
                            ></MultiSelect>
                            {/* )} */}
                          </div>
                        </LoadingHoc>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onCloseModal}
                    />
                    <Button
                      label={`+ ${heading}`}
                      id="saveAssessmentbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      // maxWidth="180px"
                      fontSize="var(--secondaryFont)"
                      border="none"
                      type="submit"
                      // Icon={
                      //   <EditIcon fill="#ffffff" className="svg_image_icon" />
                      // }
                      loading={addAssessment.isLoading}
                      disabled={addAssessment.isLoading}
                    />
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default AddAssessmentByAdmin;
