// import * as Yup from "yup";
// const AddAssessmentSchema = Yup.object().shape({
//   region: Yup.string().required("This field is required"),
//   assessments: Yup.array().min(1, "Please select atleast one assessment"),
//   department: Yup.number(),
//   assessmentYear: Yup.number().required("This field is required"),
// });

// const AssessmentInitialValue = {
//   region: "",
//   country: "",
//   state: "",
//   assessments: [],
//   assessment_catgory: "",
//   assessment_industry: "",
//   department: 0,
//   assessmentYear: null,
// };
// export { AddAssessmentSchema, AssessmentInitialValue };

import * as Yup from "yup";

const checkInterconnectedFields = (value:any, context:any) => {
  const { role, assigned_to_user_id, due_date } = context.parent;
  const anyFieldFilled = role || assigned_to_user_id || due_date;
  
 
  if (anyFieldFilled) {
    return value ? true : false;
  }
  
  return true;
};

const AddAssessmentSchema = Yup.object().shape({
  region: Yup.string(),
  assessments: Yup.array().min(1, "Please select atleast one assessment"),
  department: Yup.number(),
  assessmentYear: Yup.number().required("This field is required"),
  
  role: Yup.string().test(
    'interconnected-role',
    'Role is required.',
    checkInterconnectedFields
  ),
  
  assigned_to_user_id: Yup.string().test(
    'interconnected-assignee',
    'Assignee is required.',
    checkInterconnectedFields
  ),
  
  due_date: Yup.string().test(
    'interconnected-due-date',
    'Due Date is required.',
    checkInterconnectedFields
  ),
});

// Initial values remain the same
const AssessmentInitialValue = {
  region: "",
  country: "",
  state: "",
  assessments: [],
  assessment_catgory: "",
  assessment_industry: "",
  department: 0,
  assessmentYear: null,
  role: "",
  assigned_to_user_id: "",
  due_date: "",
};

export { AddAssessmentSchema, AssessmentInitialValue };
