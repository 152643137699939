import React, { useState } from "react";
import { Menu, MenuItem, MenuProps } from "@mui/material";
import styled from "@emotion/styled";
import Button from "../Button";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Pdf from "../AssessmentPdfFormat";
import { ReactComponent as ThreeDotIcon } from "../../../assests/svg/three_dots.svg";
import { Spinner } from "react-bootstrap";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    // anchorOrigin={{
    //   vertical: "bottom",
    //   horizontal: "left",
    // }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    position: "absolute",
    top: 0,
    left: 0,
    marginTop: 1.5,
    minWidth: "fit-content",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "transparent",

    "&::before": {
      content: '""',
      position: "absolute",
      top: "-7px",
      left: "5px",
      borderWidth: "10px",
      borderStyle: "solid",
      borderColor: "transparent transparent var(--secondaryColor) transparent",
      zIndex: 1,
      width: 0,
      height: 0,
    },
  },
  "& ul": {
    display: "flex",
    flexDirection: "column",
    gap: "1px",
    padding: "0px",
  },

  "& a": {
    display: "flex",
    flexDirection: "column",
    gap: "1px",
  },
  "& .MuiMenuItem-root": {
    display: "flex",
    alignItems: "center",
    padding: "0px",
    "&:first-of-type": {
      paddingTop: "12px",
    },
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
}));

interface CustomizedMenusProps {
  onClick?: () => void;
  pdfData?: any;
  isDownloadPdf?: any;
  disableCondition?: boolean;
  actions: Array<{
    label: string;
    icon: JSX.Element;
    onClick: () => void;
    condition?: boolean | unknown;
    disabled?: boolean | unknown;
    isDownloadLink?: boolean;
    isDownloadPdf?: boolean;
  }>;
}

export default function CustomizedMenus({
  onClick,
  pdfData,
  actions,
  // isDownloadPdf,
  // disableCondition
}: CustomizedMenusProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [exportDisabled, setExportDisabled] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onClick && onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div onClick={handleClick}>
        <ThreeDotIcon
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{ maxWidth: "16px", width: '20px' }}
        />
      </div>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{ "aria-labelledby": "customized-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions.map(
          ({ label, icon, onClick, condition, isDownloadPdf, disabled }) =>{
            console.log({condition})
            console.log("<< disabled >>",disabled)
            return(
            <MenuItem
              key={label}
              onClick={() => {
                onClick();
                handleClose();
              }}
              style={{ background: "none" }}
            >
              { condition ? <> <div style={{ overflow:"hidden", display:'flex' }} > <Button
                    id={label}
                    label={label}
                    Icon={icon}
                    maxWidth="10"
                    disabled={condition}
                    loading={true}
                    opacity='1'
                  />
                   {/* <Spinner animation="border" variant="primary" />  */}
                   </div>  </> : isDownloadPdf ? (
                <PDFDownloadLink
                  document={<Pdf assessmentData={pdfData} />}
                  fileName={`${pdfData?.assessment_name}.pdf`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    id={label}
                    label={label}
                    Icon={icon}
                    maxWidth="10"
                    disabled={condition}
                  />
                </PDFDownloadLink>
              ) : (
                <Button id={label} label={label} Icon={icon} maxWidth="10" disabled={disabled} />
              )}
            </MenuItem>
          )}
        )}
      </StyledMenu>
    </div>
  );
}
