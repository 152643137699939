import { MouseEvent } from "react";
import { ReactComponent as Edit } from "../../../assests/svg/edit_icon.svg";
import { ReactComponent as DeleteBtn } from "../../../assests/svg/delete_icon.svg";
import { ReactComponent as CancelBtn } from "../../../assests/svg/close.svg";
import { ReactComponent as SaveIcon } from "../../../assests/svg/save_close.svg";
import styles from "./table.module.scss";
import Tooltip from "../ToolTip";

export const EditCell = ({ row, table }: any) => {
  console.log("row in edit cell", row, table);
  const meta = table.options.meta;
  const validRow = meta?.validRows[row.id];
  const disableSubmit = validRow
    ? Object.values(validRow)?.some((item) => !item)
    : false;

  const setEditedRows = (e: MouseEvent<HTMLButtonElement>) => {
    const elName = e.currentTarget.name;
    meta?.setEditedRows((old: []) => ({
      ...old,
      [row.id]: !old[row.id],
    }));
    if (elName !== "edit") {
      e.currentTarget.name === "cancel"
        ? meta?.revertData(row.index)
        : meta?.updateRow(row.index);
    }
  };

  const removeRow = () => {
    meta?.removeRow(row.index);
  };

  return (
    <div className="edit-cell-container">
      {meta?.editedRows[row.id] ? (
        <div className={styles.editCellContainer}>
          {/* <button
            onClick={setEditedRows}
            name="cancel"
            className={styles.btnContainer}
          >
            <CancelBtn fill="#ffffff" style={{ maxWidth: "12px" }} />
            Cancel
          </button>{" "}
          <button
            onClick={setEditedRows}
            name="done"
            disabled={disableSubmit}
            className={styles.btnContainer}
          >
            <SaveIcon fill="#ffffff" className="svg_image_icon" />
            Save
          </button> */}
        </div>
      ) : (
        <div className={styles.editCellContainer}>
          <button
            onClick={setEditedRows}
            name="edit"
            className={styles.iconContainer}
          >
            <Tooltip text="Edit" rightSide={false}>
              <Edit className="svg-image-icon" width={16} height={16} />
            </Tooltip>
          </button>
          {/* <button
            onClick={removeRow}
            name="remove"
            className={styles.iconContainer}
          >
            <Tooltip text="Remove" rightSide={true}>
              <DeleteBtn className="svg-image-icon" width={16} height={16} />
            </Tooltip>
          </button> */}
        </div>
      )}
      {/* <input
        type="checkbox"
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      /> */}
    </div>
  );
};
