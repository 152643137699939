// import React, { useState, useEffect } from "react";
// import styles from "./styles.module.scss";
// import { Button, Dropdown } from "../../../../common/components";
// import {
//   _getQueryState,
//   _useMutation,
//   useApiQuery,
// } from "../../../../services/useAxiosQuery";
// import {
//   APP_PATHS,
//   ASSESSMENT_TYPE_IDS,
//   REQUEST_TYPE,
//   RQ_KEYS,
// } from "../../../../utils/constants";
// import { API_ROUTE } from "../../../../utils/api";
// import LoadingHoc from "../../../../common/components/LoadingHoc";
// import { exportAssessmentComparisonToExcel } from "../ComparisonReports/DownloadExcel";
// import { exportTableAssessmentComparisonToExcel } from "./DownloadExcelTable";

// const AssessmentComparisonPage = () => {
//   // States for selections
//   const [assessmentType, setAssessmentType] = useState<any>(null);
//   const [firstAssessment, setFirstAssessment] = useState<any>(null);
//   const [secondAssessment, setSecondAssessment] = useState<any>(null);
//   const [assessmentsList, setAssessmentsList] = useState([]);
//   const [comparisonData, setComparisonData] = useState<any>(null);
//   const [loading, setLoading] = useState(false);
//   const [showComparisonButton, setShowComparisonButton] = useState(false);
//   const [noDataError, setNoDataError] = useState(false);
//   const [isDownloading, setIsDownloading] = useState(false);


//   const { data: assessmentTypes, isLoading: isLoadingTypes  } = useApiQuery({
//       queryKey: RQ_KEYS.ASSESSMENT_TYPES,
//       requestType: REQUEST_TYPE._GET,
//       url: API_ROUTE.GET_ASSESSMENT_TYPES,
//     });

//     const formattedAssessmentTypes = React.useMemo(() => {
//       if (!assessmentTypes) return [];
      
//       return assessmentTypes.map((type:any) => ({
//         name: type.assessment_type,
//         value: type.assessment_type_id.toString()
//       }));
//     }, [assessmentTypes]);  

//     const { mutate: getAssessmentsByType, isLoading: isLoadingAssessments } = _useMutation({
//       queryKey: RQ_KEYS.ASSESSMENTS_BY_TYPE_ID,
//       onSuccess: (data:any) => {
//         if (data) {
//           console.log(">> data <<",data);
//           setAssessmentsList(
//             data?.data?.map((assessment:any) => ({
//               name: assessment.assessment_name,
//               value: assessment.assign_assessment_id.toString(),
//               year: assessment.assessment_year
//             }))
//           );
//         } else {
//           setAssessmentsList([]);
//         }
//       },
//     });
  
//     // Function to load assessments based on selected type
//     const handleSelectAssessmentType = (selected:any) => {
//       setAssessmentType(selected);
//       setFirstAssessment(null);
//       setSecondAssessment(null);
//       setComparisonData(null);
//       setShowComparisonButton(false);
//       setNoDataError(false);
      
//       // Call API to get assessments for the selected type
//       getAssessmentsByType({
//         queryKey: RQ_KEYS.ASSESSMENTS_BY_TYPE_ID,
//         url: `${API_ROUTE.GET_ASSESSMENT_BY_TYPE_ID}/${selected.value}`,
//         requestType: REQUEST_TYPE._GET,
//       });
//     };
  
//     const handleSelectFirstAssessment = (selected:any) => {
//       console.log(">> First Assessment Selected <<", selected);
//       setFirstAssessment(selected);
//       checkIfCanCompare(selected, secondAssessment);
//     };
  
//     const handleSelectSecondAssessment = (selected:any) => {
//       console.log(">> Second Assessment Selected <<", selected);
//       setSecondAssessment(selected);
//       checkIfCanCompare(firstAssessment, selected);
//     };
  
//     // Check if we can enable the compare button
//     const checkIfCanCompare = (first:any, second:any) => {
//       if (first && second && first.value !== second.value) {
//         setShowComparisonButton(true);
//       } else {
//         setShowComparisonButton(false);
//       }
//     };

//     const { mutate: compareAssessments, isLoading: isLoadingComparison } = _useMutation({
//       queryKey: RQ_KEYS.COMPARISON_DATA,
//       onSuccess: async (data:any) => {
//         console.log("<< report data <<", data);
//         setIsDownloading(true);
//         if (data?.data) {
//           setComparisonData(data.data);
//           setNoDataError(false);

//           console.log(">> data?.data <<",data?.data);
//           console.log(">> isDownloading<<", isDownloading);
          
//           // Trigger Excel export
//           // if (isDownloading) {
//           if(Number(assessmentType.value) === ASSESSMENT_TYPE_IDS.BIA){
//             await exportTableAssessmentComparisonToExcel(data?.data?.first_assessment_data, data?.data?.second_assessment_data, assessmentType?.name)
//             setIsDownloading(false);
//           }else{
//             await exportAssessmentComparisonToExcel(data?.data, assessmentType?.name, firstAssessment, secondAssessment);
//             setIsDownloading(false);
//           }
//           // }
//         } else {
//           setComparisonData(null);
//           setNoDataError(true);
//           setIsDownloading(false);
//         }
//       },
//       onError: () => {
//         setComparisonData(null);
//         setNoDataError(true);
//         setIsDownloading(false);
//       }
//     });

//     const handleCompareAssessments = () => {
//       if (!firstAssessment || !secondAssessment) return;
      
//       setNoDataError(false);
//       setIsDownloading(true);
  
//       if(Number(assessmentType.value) === ASSESSMENT_TYPE_IDS.BIA){
//         compareAssessments({
//           url: API_ROUTE.GET_ASSESSMENT_COMPARISON_TABLE,
//           requestType: REQUEST_TYPE._POST,
//           requestData: {
//             assessment_type_id: assessmentType.value,
//             first_assessment_id: firstAssessment.value,
//             second_assessment_id: secondAssessment.value
//           }
//         });
//       }else{
//         compareAssessments({
//           url: API_ROUTE.GET_ASSESSMENT_COMPARISON,
//           requestType: REQUEST_TYPE._POST,
//           requestData: {
//             assessment_type_id: assessmentType.value,
//             first_assessment_id: firstAssessment.value,
//             second_assessment_id: secondAssessment.value
//           }
//         });
//       }
//     };

//   return (
//     <React.Fragment>
//       <div className={`assessmentComparisonContainer ${styles.assessmentComparisonContainer}`}>
//         <div className={styles.assessmentHeader}>
//           <h4>Comparison Assessment</h4>
//           {/* <p>Compare results between two assessments</p> */}
//         </div>

//         <div className={styles.assessmentBody}>
//           {/* Assessment Type Selection */}
//           <div className={styles.assessmentTypeRow}>
//             <div className={styles.filterDropdown}>
//               <Dropdown
//                label={assessmentType?.name ? assessmentType.name : "Select Assessment Type"}
//                dropdownData={formattedAssessmentTypes}
//                onClick={handleSelectAssessmentType}
//                readOnly={isLoadingTypes}
//               />
//             </div>
            
//           </div>

//           {/* Assessment Selections */}
//           <LoadingHoc isLoading={loading || isLoadingTypes }>
//             {assessmentType && (
//               <div className={styles.comparisonSelections}>
//                 <div className={styles.selectionRow}>
//                   <div className={styles.filterDropdown}>
//                   <Dropdown
//                        key={firstAssessment?.value || 'first-assessment'}
//                       label={firstAssessment?.name ? firstAssessment.name : "First Comparison Assessment"}
//                       dropdownData={assessmentsList}
//                       onClick={handleSelectFirstAssessment}
//                       readOnly={!assessmentsList.length || isLoadingAssessments}
//                     />
//                   </div>

//                   <div className={styles.filterDropdown}>
//                   <Dropdown
//                        key={secondAssessment?.value || 'second-assessment'} 
//                       label={secondAssessment?.name ? secondAssessment.name : "Second Comparison Assessment"}
//                       dropdownData={assessmentsList.filter((item:any) => 
//                         !firstAssessment || item.value !== firstAssessment.value)}
//                       onClick={handleSelectSecondAssessment}
//                       readOnly={!assessmentsList.length || isLoadingAssessments || !firstAssessment}
//                     />
//                   </div>
//                 </div>

//                 {/* Error Message */}
//                 {noDataError && (
//                   <div className={styles.errorMessage}>
//                     No comparison data available for these assessments.
//                   </div>
//                 )}

//                 {showComparisonButton && (
//                   <div className={styles.actionButtons}>
//                     <Button
//                       label="Download Comparison Report"
//                       id="submit"
//                       onClick={handleCompareAssessments}
//                       disabled={!showComparisonButton || isLoadingComparison || isDownloading}
//                       loading={isLoadingComparison || isDownloading}
//                     />
//                   </div>
//                 )}

//               </div>
              
//             )}
//           </LoadingHoc>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default AssessmentComparisonPage;



import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Button, Dropdown } from "../../../../common/components";
import {
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  ASSESSMENT_TYPE_IDS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import LoadingHoc from "../../../../common/components/LoadingHoc";
import { exportAssessmentComparisonToExcel } from "../ComparisonReports/DownloadExcel";
import { exportTableAssessmentComparisonToExcel } from "./DownloadExcelTable";

interface AssessmentType {
  name: string;
  value: string;
}

interface Assessment {
  name: string;
  value: string;
  year?: string;
}

const AssessmentComparisonPage: React.FC = () => {
  const [assessmentType, setAssessmentType] = useState<AssessmentType | null>(null);
  const [firstAssessment, setFirstAssessment] = useState<Assessment | null>(null);
  const [secondAssessment, setSecondAssessment] = useState<Assessment | null>(null);
  const [assessmentsList, setAssessmentsList] = useState<Assessment[]>([]);
  const [comparisonData, setComparisonData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showComparisonButton, setShowComparisonButton] = useState(false);
  const [noDataError, setNoDataError] = useState(false);
  const [noAssessmentsError, setNoAssessmentsError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { data: assessmentTypes, isLoading: isLoadingTypes } = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_TYPES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_TYPES,
  });

  const formattedAssessmentTypes = React.useMemo(() => {
    if (!assessmentTypes) return [];
    
    return assessmentTypes.map((type: any) => ({
      name: type.assessment_type,
      value: type.assessment_type_id.toString()
    }));
  }, [assessmentTypes]);  

  // Fetch Assessments by Type
  const { mutate: getAssessmentsByType, isLoading: isLoadingAssessments } = _useMutation({
    queryKey: RQ_KEYS.ASSESSMENTS_BY_TYPE_ID,
    onSuccess: (data: any) => {
      if (data && data.data && data.data.length > 0) {
        setAssessmentsList(
          data.data.map((assessment: any) => ({
            name: assessment.assessment_name,
            value: assessment.assign_assessment_id.toString(),
            year: assessment.assessment_year
          }))
        );
        setNoAssessmentsError(false);
      } else {
        setAssessmentsList([]);
        setNoAssessmentsError(true);
      }
    },
    onError: () => {
      setAssessmentsList([]);
      setNoAssessmentsError(true);
    }
  });

  // Handle Assessment Type Selection
  const handleSelectAssessmentType = (selected: AssessmentType) => {
    setAssessmentType(selected);
    setFirstAssessment(null);
    setSecondAssessment(null);
    setComparisonData(null);
    setShowComparisonButton(false);
    setNoDataError(false);
    setNoAssessmentsError(false);
    
    getAssessmentsByType({
      queryKey: RQ_KEYS.ASSESSMENTS_BY_TYPE_ID,
      url: `${API_ROUTE.GET_ASSESSMENT_BY_TYPE_ID}/${selected.value}`,
      requestType: REQUEST_TYPE._GET,
    });
  };

  const handleSelectFirstAssessment = (selected: Assessment) => {
    if(selected.value){
      setFirstAssessment(selected);
      setSecondAssessment(null);
      checkIfCanCompare(selected, null);
    }else{
      setShowComparisonButton(false);
    }
  };

  const handleSelectSecondAssessment = (selected: Assessment) => {
    console.log(">> Second Assessment Selected <<", selected);
    if(selected.value){
      setSecondAssessment(selected);
      checkIfCanCompare(firstAssessment, selected);
    }else{
      setShowComparisonButton(false);
    }
  };

  const checkIfCanCompare = (first: Assessment | null, second: Assessment | null) => {
    if (first && second && first.value !== second.value) {
      setShowComparisonButton(true);
    } else {
      setShowComparisonButton(false);
    }
  };

  // Compare Assessments
  const { mutate: compareAssessments, isLoading: isLoadingComparison } = _useMutation({
    queryKey: RQ_KEYS.COMPARISON_DATA,
    onSuccess: async (data: any) => {
      setIsDownloading(true);
      if (data?.data) {
        setComparisonData(data.data);
        setNoDataError(false);
        
        try {
          if (Number(assessmentType?.value) === ASSESSMENT_TYPE_IDS.BIA) {
            await exportTableAssessmentComparisonToExcel(
              data?.data?.first_assessment_data, 
              data?.data?.second_assessment_data, 
              assessmentType?.name
            );
          } else {
            await exportAssessmentComparisonToExcel(
              data?.data, 
              assessmentType?.name, 
              firstAssessment, 
              secondAssessment,
              assessmentType?.value
            );
          }
        } catch (error) {
          console.error("Export error:", error);
        } finally {
          setIsDownloading(false);
        }
      } else {
        setComparisonData(null);
        setNoDataError(true);
        setIsDownloading(false);
      }
    },
    onError: () => {
      setComparisonData(null);
      setNoDataError(true);
      setIsDownloading(false);
    }
  });

  const handleCompareAssessments = () => {
    if (!firstAssessment || !secondAssessment || !assessmentType) return;
    
    setNoDataError(false);
    setIsDownloading(true);

    const requestData = {
      assessment_type_id: assessmentType.value,
      first_assessment_id: firstAssessment.value,
      second_assessment_id: secondAssessment.value
    };

    compareAssessments({
      url: Number(assessmentType.value) === ASSESSMENT_TYPE_IDS.BIA 
        ? API_ROUTE.GET_ASSESSMENT_COMPARISON_TABLE 
        : API_ROUTE.GET_ASSESSMENT_COMPARISON,
      requestType: REQUEST_TYPE._POST,
      requestData
    });
  };

  // Get dropdown data for first assessment
  const getFirstAssessmentDropdownData = () => {
    if (noAssessmentsError) {
      return [{ name: "None", value: "none" }];
    }

    // If a second assessment is selected, filter it out
    return assessmentsList.filter((item) => 
      !secondAssessment || item.value !== secondAssessment.value
    );
  };

  // Get dropdown data for second assessment
  const getSecondAssessmentDropdownData = () => {
    if (noAssessmentsError || assessmentsList.length === 1) {
      return [{ name: "None", value: "none" }];
    }

    // If a first assessment is selected, filter it out
    return assessmentsList.filter((item) => 
      !firstAssessment || item.value !== firstAssessment.value
    );
  };

  return (
    <div className={`assessmentComparisonContainer ${styles.assessmentComparisonContainer}`}>
      <div className={styles.assessmentHeader}>
        <h4>Assessment Comparison </h4>
      </div>

      <div className={styles.assessmentBody}>
        {/* Assessment Type Selection */}
        <div className={styles.assessmentTypeRow}>
          <div className={styles.filterDropdown}>
            <Dropdown
              key={assessmentType?.value || 'assessment-type'}
              label={assessmentType?.name || "Select Assessment Type"}
              dropdownData={formattedAssessmentTypes}
              onClick={handleSelectAssessmentType}
              readOnly={isLoadingTypes}
            />
          </div>
        </div>

        <LoadingHoc isLoading={loading || isLoadingTypes || isLoadingAssessments}>
          {assessmentType && (
            <div className={styles.comparisonSelections}>
              <div className={styles.selectionRow}>
                <div className={styles.filterDropdown}>
                  <Dropdown
                    key={firstAssessment?.value || 'first-assessment'}
                    label={
                      noAssessmentsError 
                        ? "None" 
                        : (firstAssessment?.name || "First Comparison Assessment")
                    }
                    dropdownData={
                      noAssessmentsError 
                        ? [{ name: "None", value: "" }] 
                        : getFirstAssessmentDropdownData()
                    }
                    onClick={handleSelectFirstAssessment}
                    readOnly={noAssessmentsError || !assessmentsList.length}
                  />
                </div>

                <div className={styles.filterDropdown}>
                  <Dropdown
                    key={secondAssessment?.value || 'second-assessment'}
                    label={
                      noAssessmentsError 
                        ? "None" 
                        : (secondAssessment?.name || "Second Comparison Assessment")
                    }
                    dropdownData={
                      noAssessmentsError || assessmentsList.length === 1
                        ? [{ name: "None", value: "" }] 
                        : getSecondAssessmentDropdownData()
                    }
                    onClick={handleSelectSecondAssessment}
                    readOnly={
                      noAssessmentsError || 
                      !assessmentsList.length || 
                      !firstAssessment
                    }
                  />
                </div>
              </div>

              {noAssessmentsError && (
                <div className={styles.errorMessage}>
                  No Assessments found for Comparison
                </div>
              )}

              {noDataError && (
                <div className={styles.errorMessage}>
                  No comparison data available for these assessments.
                </div>
              )}

              {showComparisonButton && (
                <div className={styles.actionButtons}>
                  <Button
                    label="Download Report"
                    id="submit"
                    onClick={handleCompareAssessments}
                    disabled={!showComparisonButton || isLoadingComparison || isDownloading}
                    loading={isLoadingComparison || isDownloading}
                  />
                </div>
              )}
            </div>
          )}
        </LoadingHoc>
      </div>
    </div>
  );
};

export default AssessmentComparisonPage;