import * as Yup from "yup";
const DepartmentSchema = Yup.object().shape({
  deleteStr: Yup.string()
    .required("This field is required.")
    .matches(/^DELETE$/, 'Please enter "DELETE" to delete'),
});

const InitialDepartmentValue = {
  deleteStr: "",
};
export { DepartmentSchema, InitialDepartmentValue };
