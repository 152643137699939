import Excel from 'exceljs';
import { AWS_DOCUMENT_BASE, ASSESSMENT_TYPE_IDS } from '../../../../utils/constants';
import { AnyCnameRecord } from 'dns';

interface AssessmentComparisonData {
  question_number: string;
  question: string;
  first_answer: { chapter_id: number, chapter_number: number, answer: string | null }[];
  second_answer: { chapter_id: number, chapter_number: number, answer: string | null }[];
  first_assessment_comments: {comment_text: string, created_at: string, chapter_number: any}[];
  second_assessment_comments: {comment_text: string, created_at: string, chapter_number: any}[];
  first_assessment_doc: { file_signed_url: string, file_name: string }[];
  second_assessment_doc: { file_signed_url: string, file_name: string }[];
}

export const exportAssessmentComparisonToExcel = async (data: AssessmentComparisonData[], assessment_name:any,firstAssessment:any ,secondAssessment:any, assessment_type_id:any) => {
  const firstAssessmentYear = firstAssessment.year;
  const secondAssessmentYear = secondAssessment.year;  
  const sortedData = [...data].sort((a, b) => 
    parseInt(a.question_number) - parseInt(b.question_number)
  );

  console.log("<< assessment_type_id >>",assessment_type_id);

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(`${firstAssessmentYear} - ${secondAssessmentYear}`);

  worksheet.columns = [
    { header: 'Question Number', key: 'questionNumber', width: 15 },
    { header: 'Question', key: 'question', width: 40 },
    { header: `${firstAssessmentYear} - Answer`, key: 'firstAnswer', width: 30 },
    { header: `${secondAssessmentYear} - Answer`, key: 'secondAnswer', width: 30 },
    { header: `${firstAssessmentYear} - Comments`, key: 'firstComments', width: 40 },
    { header: `${secondAssessmentYear} - Comments`, key: 'secondComments', width: 40 },
    { header: `${firstAssessmentYear} - Documents`, key: 'firstDocs', width: 50 },
    { header: `${secondAssessmentYear} - Documents`, key: 'secondDocs', width: 50 }
  ];

  sortedData.forEach((item, rowIndex) => {
    // const formatComments = (comments: {comment_text: string, created_at: string, chapter_number: any}[]) => {
    //   const sortedComments = comments.sort((a, b) => 
    //     new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    //   ); 

    //   return sortedComments.map((comment, index) => 
    //     `${index + 1}. ${comment.comment_text}${index < sortedComments.length - 1 ? ',' : ''}`
    //   ).join('\n');
    // };

    const formatComments = (comments: {comment_text: string, created_at: string, chapter_number: any}[]) => {
      if (assessment_type_id == ASSESSMENT_TYPE_IDS.ROPA) {
        const commentsByChapter = comments.reduce((acc, comment) => {
          const chapterNum = comment.chapter_number || 'Unknown';
          
          if (!acc[chapterNum]) {
            acc[chapterNum] = [];
          }
          
          acc[chapterNum].push(comment);
          return acc;
        }, {} as Record<string, typeof comments>);
        
        const sortedChapters = Object.keys(commentsByChapter)
          .filter(chapter => chapter !== 'Unknown')
          .sort((a, b) => parseInt(a) - parseInt(b));
        
        if (commentsByChapter['Unknown']?.length > 0) {
          sortedChapters.push('Unknown');
        }
        
        return sortedChapters.map(chapter => {
          const chapterComments = commentsByChapter[chapter].sort((a, b) => 
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
          
          const formattedComments = chapterComments.map((comment, index) => 
            `${index + 1}. ${comment.comment_text}${index < chapterComments.length - 1 ? ',' : ''}`
          ).join('\n');
          
          return `Chapter ${chapter}:\n${formattedComments}`;
        }).join('\n\n');
      } 
      else {
        const sortedComments = comments.sort((a, b) => 
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        ); 
    
        return sortedComments.map((comment, index) => 
          `${index + 1}. ${comment.comment_text}${index < sortedComments.length - 1 ? ',' : ''}`
        ).join('\n');
      }
    };

    const formatAnswers = (answers: { chapter_id: number, chapter_number: number, answer: string | null }[]) => {
      const validAnswers = answers.filter(item => item.answer !== null);
      
      if (validAnswers.length === 0) {
        return "";
      }
      
      const sortedAnswers = validAnswers.sort((a, b) => a.chapter_id - b.chapter_id);
      
      if (assessment_type_id == ASSESSMENT_TYPE_IDS.ROPA) {
        return sortedAnswers.map(item => 
          `Chapter ${item.chapter_number}: ${item.answer}`
        ).join(',\n');
      } 
      else {
        if (sortedAnswers.length === 1) {
          return sortedAnswers[0].answer;
        }
        return sortedAnswers.map((item, index) => 
          `${index + 1}. ${item.answer}`
        ).join(',\n');
      }
    };

    // const formatDocuments = (docs: {file_signed_url: string, file_name: string}[]) => {
    //   return docs.map((doc, index) => {
    //     const fullUrl = `${AWS_DOCUMENT_BASE}${doc.file_signed_url}`;
    //     return {
    //       text: `${doc.file_name}`,
    //       hyperlink: fullUrl
    //     };
    //   });
    // };

    const formatDocuments = (docs: {file_signed_url: string, file_name: string, chapter_number?: number}[]) => {
      if (assessment_type_id == ASSESSMENT_TYPE_IDS.ROPA) {
        // Group documents by chapter number
        const docsByChapter = docs.reduce((acc, doc) => {
          const chapterNum = doc.chapter_number || 'Unknown';
          
          if (!acc[chapterNum]) {
            acc[chapterNum] = [];
          }
          
          acc[chapterNum].push(doc);
          return acc;
        }, {} as Record<string, typeof docs>);
        
        const sortedChapters = Object.keys(docsByChapter)
          .filter(chapter => chapter !== 'Unknown')
          .sort((a, b) => parseInt(a) - parseInt(b));
        
        if (docsByChapter['Unknown']?.length > 0) {
          sortedChapters.push('Unknown');
        }
        
        let result: {text: string, hyperlink?: string}[] = [];
        
        sortedChapters.forEach(chapter => {
          result.push({ text: `Chapter ${chapter}:` });
          
          const chapterDocs = docsByChapter[chapter];
          chapterDocs.forEach((doc, index) => {
            const fullUrl = `${AWS_DOCUMENT_BASE}${doc.file_signed_url}`;
            result.push({
              text: `${index+1}. ${fullUrl}`,
              hyperlink: fullUrl
            });
          });
        });
        
        return result;
      } 
      else {
        return docs.map((doc, index) => {
          const fullUrl = `${AWS_DOCUMENT_BASE}${doc.file_signed_url}`;
          return {
            text: `${index+1}. ${fullUrl}`,
            hyperlink: fullUrl
          };
        });
      }
    };

    const firstComments = formatComments(item.first_assessment_comments);
    const secondComments = formatComments(item.second_assessment_comments);
    const firstDocs = formatDocuments(item.first_assessment_doc);
    const secondDocs = formatDocuments(item.second_assessment_doc);
    const firstAnswers = formatAnswers(item.first_answer);
    const secondAnswers = formatAnswers(item.second_answer);

    const row = worksheet.addRow({
      questionNumber: item.question_number,
      question: item.question,
      firstAnswer: firstAnswers,
      secondAnswer: secondAnswers,
      firstComments: firstComments,
      secondComments: secondComments,
      firstDocs: '',
      secondDocs: ''
    });

    // if (firstDocs.length > 0) {
    //   const firstDocsCell = worksheet.getCell(`G${rowIndex + 2}`);
    //   firstDocsCell.value = {
    //     richText: firstDocs.map((doc:any, index:any) => ({
    //       text: `${index+1}. ${doc.hyperlink}\n`,
    //       hyperlink: doc.hyperlink,
    //       tooltip: doc.hyperlink,
    //       font: { color: { argb: 'FF0000FF' }, underline: true }
    //     }))
    //   };
    // }

    // if (secondDocs.length > 0) {
    //   const secondDocsCell = worksheet.getCell(`H${rowIndex + 2}`);
    //   secondDocsCell.value = {
    //     richText: secondDocs.map((doc:any, index:any) => ({
    //       text: `${index+1}. ${doc.hyperlink}\n`,
    //       hyperlink: doc.hyperlink,
    //       tooltip: doc.hyperlink, 
    //       font: { color: { argb: 'FF0000FF' }, underline: true }
    //     }))
    //   };
    // }

    if (firstDocs.length > 0) {
      const firstDocsCell = worksheet.getCell(`G${rowIndex + 2}`);
      
      if (assessment_type_id == ASSESSMENT_TYPE_IDS.ROPA ) {
        firstDocsCell.value = {
          richText: firstDocs.map((doc: any, index: any) => ({
            text: `${doc.text}\n`,
            hyperlink: doc.hyperlink,
            tooltip: doc.hyperlink,
            font: { 
              color: doc.hyperlink ? { argb: 'FF0000FF' } : undefined, 
              underline: doc.hyperlink ? true : false,
              bold: !doc.hyperlink // Bold for chapter headers
            }
          }))
        };
      } else {
        firstDocsCell.value = {
          richText: firstDocs.map((doc: any, index: any) => ({
            text: `${doc.text}\n`,
            hyperlink: doc.hyperlink,
            tooltip: doc.hyperlink,
            font: { color: { argb: 'FF0000FF' }, underline: true }
          }))
        };
      }
    }
    
    // Similarly for secondDocs
    if (secondDocs.length > 0) {
      const secondDocsCell = worksheet.getCell(`H${rowIndex + 2}`);
      
      if (assessment_type_id == ASSESSMENT_TYPE_IDS.ROPA) {
        secondDocsCell.value = {
          richText: secondDocs.map((doc: any, index: any) => ({
            text: `${doc.text}\n`,
            hyperlink: doc.hyperlink,
            tooltip: doc.hyperlink,
            font: { 
              color: doc.hyperlink ? { argb: 'FF0000FF' } : undefined, 
              underline: doc.hyperlink ? true : false,
              bold: !doc.hyperlink // Bold for chapter headers
            }
          }))
        };
      } else {
        secondDocsCell.value = {
          richText: secondDocs.map((doc: any, index: any) => ({
            text: `${doc.text}\n`,
            hyperlink: doc.hyperlink,
            tooltip: doc.hyperlink,
            font: { color: { argb: 'FF0000FF' }, underline: true }
          }))
        };
      }
    }

    
  });

  worksheet.getRow(1).font = { bold: true };
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F0F0F0' }
  };

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      row.height = 60;
    }
  });

  worksheet.columns.forEach(column => {
    column.alignment = { 
      vertical: 'middle', 
      horizontal: 'left', 
      wrapText: true 
    };
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${assessment_name} ${firstAssessmentYear}-${secondAssessmentYear}.xlsx`;
  link.click();
};
