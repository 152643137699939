import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as AssignTask } from "../../../../../assests/svg/assign.svg";
import { ReactComponent as BackIcon } from "../../../../../assests/svg/backArrow.svg";
import { ReactComponent as BackArrow } from "../../../../../assests/svg/back_btn.svg";
import { ReactComponent as CancelIcon } from "../../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assests/svg/delete_icon.svg";
import { ReactComponent as DocIcon } from "../../../../../assests/svg/docIcon.svg";
import { ReactComponent as PdfIcon } from "../../../../../assests/svg/pdf.svg";
import {ReactComponent as ImageIcon} from "../../../../../assests/svg/image.svg"
import { ReactComponent as SaveIcon } from "../../../../../assests/svg/save_close.svg";
import { ReactComponent as NextArrow } from "../../../../../assests/svg/right.svg";
import { ReactComponent as SendIcon } from "../../../../../assests/svg/send.svg";
import { ReactComponent as UploadIcon } from "../../../../../assests/svg/upload_icons.svg";
import { ReactComponent as ViewIcon } from "../../../../../assests/svg/view_icon.svg";
import { ReactComponent as DownIcon } from "../../../../../assests/svg/down_arrow.svg";
import { ReactComponent as UpIcon } from "../../../../../assests/svg/up_arrow.svg";
import { ReactComponent as PlusIcon } from "../../../../../assests/svg/plus.icon.svg";
import Tooltip from "../../../../../common/Parts/ToolTip";
import { ReactComponent as InfoIcon } from "../../../../../assests/svg/i_icon.svg";

import { ReactComponent as NextArrowIcon } from "../../../../../assests/svg/next_arrow_fill.svg";
import {
  Button,
  Checkbox,
  InputField,
  MultiSelect,
  Textarea,
} from "../../../../../common/components";
import styles from "./style.module.scss";
import { ReactComponent as CopyIcon } from "../../../../../assests/svg/copy_data.svg";
import queryString from "query-string";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteDocument from "../../../../../common/Parts/ModalPopups/AssessmentModals/DeleteDocument";
import AssignQuestion from "../../../../../common/Parts/ModalPopups/QuestionScreenModals/AssignAssessmentAndOptions";
import UserTaskDetail from "../../../../../common/Parts/SidePopups/UserTaskDetail";
import Comment, { ICommentObj } from "../../../../../common/components/Comment";
import FileInput from "../../../../../common/components/FileInput/input";
import LoadingHoc from "../../../../../common/components/LoadingHoc";
import { IAssignModal, IModal } from "../../../../../interfaces/IModal";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../../utils/api";
import {
  APP_PATHS,
  APP_ROLES,
  ASSESSMENT_MODAL_NAMES,
  ASSESSMENT_STATUS,
  ASSIGNED_STATUS,
  AWS_DOCUMENT_BASE,
  QUESTION_SCREEN_MODAL_NAMES,
  REQUEST_TYPE,
  RQ_KEYS,
  USER_ROLES,
} from "../../../../../utils/constants";
import PriorityBadge from "../../../../../common/components/PriorityBadge";
import RadioInput from "../../../../../common/components/RadioInput";
import UploadFile from "../../../../../common/Parts/ModalPopups/UploadFile";
import { v4 as uuidv4 } from "uuid";
import QuestionSuccessScreen from "../QuestionSuccessScreen";
import FollowupQuestions from "./FollowupQuestions";
import ListQuestions from "./ListQuestions";
import ProcessesTable from "./ProcessesTable";
import QuestionTable from "./QuestionTable";
import { formControlClasses } from "@mui/material";
import ViewArticle from "../../../../../common/Parts/ModalPopups/AssessmentModals/ManageArticles/ViewArticle";
import { data } from "../../../../../common/Parts/EditableProcessesTable/db";

interface AnswerList {
  id: string;
  text: string;
}
// interface Exceptions {
//   isException: boolean;
//   exceptionValue: "Yes" | "No" | null;
//   exceptionText: string;
// }

interface IState {
  selectedChapters: any;
  selectedArticles: any;
  selectedQuestions: any;
  selectedTask: any;
}
interface Answer {
  question_type?:
    | "Subjective"
    | "Objective"
    | "List"
    | "Multi-Option Selection";
  answer_option_id?: number | null;
  answer_subjective?: string;
  answer_objective?: string | number | null;
  answer_list?: AnswerList[];
  // exceptions: Exceptions;
  multi_option_answer: number[];
}

const initialAnswerList = [
  {
    id: uuidv4(),
    text: "",
  },
];

const QuestionScreen: React.FC = () => {
  console.log("Entered in Question Screen");
  const [state, setState] = React.useState<IState | null>(null);
  const [taskDetailModal, setTaskDetailModal] = useState<any>({
    state: false,
    task: null,
  });
  const [showDocument, setShowDocument] = React.useState(false);
  // const [isException, setIsException] = useState(false);
  // const [exceptionValue, setExceptionValue] = useState<"Yes" | "No" | null>(
  //   null
  // );
  // console.log("exceptionValue", exceptionValue);
  const [nextEnable, setNextEnable] = useState("Disabled");
  const [saveEnable, setSaveEnable] = useState("Disabled");
  // const [exceptionText, setExceptionText] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [answerList, setAnswerList] = useState<AnswerList[]>(initialAnswerList);
  const [commentText, setCommentText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [answerOptionId, setAnswerOptionId] = useState<number | null>(null);
  const [answerCheckId, setAnswerCheckId] = useState<number[]>([]);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  console.log("answercheckid", answerCheckId);
  const [currentFollowup, setCurrentFollowup] = useState([]);
  console.log("current followup ", currentFollowup);
  const [currentFollowupAnswers, setCurrentFollowupAnswers] = useState([]);
  const [entryComplete, setEntryComplete] = useState(false);
  const [showQuestionTable, setShowQuestionTable] = useState(true);
  const [submitType, setSubmitType] = useState("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQuery = queryString.parse(window.location.search);
  console.log("parsed query", parsedQuery, window.location.search);
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  let includeMyTaskFlag: boolean = true; // We sending this flag to get all task (Assigned to others and self also)

  const assessment_id =
    parsedQuery?.assessment_id && atob(parsedQuery?.assessment_id as string);
  const chapter_id =
    parsedQuery?.chapter_id && atob(parsedQuery?.chapter_id as string);
  const org_admin_id = 
    parsedQuery?.org_admin_id && atob(parsedQuery?.org_admin_id as any);

  // on page leave reset questionIndex localstorage
  useEffect(() => {
    setNextEnable("Disabled");
    setSaveEnable("Disabled");
    return () => {
      localStorage.setItem("questionIndex", "0");
    };
  }, []);

  // if (userAuthData?.role === APP_ROLES.ORG_ADMIN) {
  //   includeMyTaskFlag = true;
  // }

  useApiQuery({
    queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
    url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?assigned_status=Assigned&includeMyTaskFlag=${includeMyTaskFlag}`,
    requestType: REQUEST_TYPE._GET,
  });

  useApiQuery({
    queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS,
    url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?assigned_status=Completed&includeMyTaskFlag=${includeMyTaskFlag}`,
    requestType: REQUEST_TYPE._GET,
  });

  useApiQuery({
    queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS,
    url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?assigned_status=Under_Review&includeMyTaskFlag=${includeMyTaskFlag}`,
    requestType: REQUEST_TYPE._GET,
  });

  const completedTasks: any = _getQueryState({
    key: RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS,
  });
  // const completedTasksLoadingState = _getQueryState({
  //   key: `${RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS}_loading`,
  // })

  const pendingTasks: any = _getQueryState({
    key: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
  });
  // const pendingTasksLoadingState = _getQueryState({
  //   key: `${RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK}_loading`,
  // })

  const reviewTasks: any = _getQueryState({
    key: RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS,
  });
  // const reviewTasksLoadingState = _getQueryState({
  //   key: `${RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS}_loading`,
  // })

  const allTasks = pendingTasks &&
    completedTasks &&
    reviewTasks && [...pendingTasks, ...reviewTasks, ...completedTasks];
  console.log("pending in question screen", pendingTasks, completedTasks);

  console.log("<< pendingTasks >>", pendingTasks);
  console.log("<< reviewTasks >>", reviewTasks);
  console.log("<< completedTasks >>", completedTasks);
  console.log("<< All Tasks: >>", allTasks);
  const allTaskData = allTasks?.flatMap((assessment:any) => assessment.tasks_data)
  console.log("<< allTaskData >>", allTaskData);

  const { mutate: getQuestions } = _useMutation({
    queryKey: RQ_KEYS.QUESTIONS,
  });
  const { mutate: getCompleteQuestions } = _useMutation({
    queryKey: RQ_KEYS.COMPLETE_QUESTIONS,
  });
  const { mutate: getTasksRelatedWithQuestion } = _useMutation({
    queryKey: RQ_KEYS.REMEDIATION_TASKS,
  });

  const onSuccessRemediation = () => {
    getTasksRelatedWithQuestion({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_TASKS_WITH_RELATED_QUESTIONS}?article_id=${chapterData.articles[articleIndex]["article_id"]}&question_id=${fetchedQuestions?.data?.[questionIndex]?.["question_id"]}`,
    });
    queryClient.refetchQueries({
      queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
    });
    queryClient.refetchQueries({
      queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS,
    });
    queryClient.refetchQueries({
      queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS,
    });
    getQuestions({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS}?assessment_id=${assessment_id}&chapter_id=${chapter_id}&org_admin_id=${org_admin_id}`,
    });
    getCompleteQuestions({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_ALL_ASSIGNED_QUESTIONS}?assessment_id=${assessment_id}`,
    });
  };

  const { mutate: getDocuments } = _useMutation({
    queryKey: RQ_KEYS.DOCUMENTS,
  });

  const { mutate: submitAnswer, isLoading: submitAnswerLoading } = _useMutation(
    {
      onSuccess: (data: any) => {
        console.log("data in submit answer", data);
        setNextEnable("Enabled");
        setSubmitType("");
        getQuestions({
          requestType: REQUEST_TYPE._GET,
          url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS}?assessment_id=${assessment_id}&chapter_id=${chapter_id}&org_admin_id=${org_admin_id}`,
        });
        getCompleteQuestions({
          requestType: REQUEST_TYPE._GET,
          url: `${API_ROUTE.GET_ALL_ASSIGNED_QUESTIONS}?assessment_id=${assessment_id}`,
        });
        onSuccessRemediation();

        // here you will have current question Index
        const currentQuestion = fetchedQuestions?.data[questionIndex];

        let currentOptionText:string = '';
        if(currentQuestion?.question_type === "Objective"){
          console.log("currentFollowupAnswers in next",currentFollowupAnswers);

        const currentAnswer:any = currentFollowupAnswers[0];
        console.log("currentAnswer",currentAnswer);

        currentOptionText = currentQuestion.options.find(
          (option: any) =>
            option.assign_option_id.toString() === currentAnswer?.answer
        )?.option_text;

        console.log("currentOptionText",currentOptionText);
        }
        
        const allAnswered = fetchedQuestions?.data
          ?.filter(
            (question: any) =>
              question.org_assign_assessment_id !==
              currentQuestion.org_assign_assessment_id
          )
          .every((question: any) => question.assigned_status === "Completed");

        if (data?.data?.type === "next") {
          if (allAnswered && chapterData?.chapter_name !== "BIA") {
            if(currentOptionText && currentOptionText !== '' &&  String(currentOptionText).trim().startsWith("No") && tasks?.length > 0 && (questionIndex === fetchedQuestions?.data?.length - 1)){
              setEntryComplete(false);
            }else{
              setEntryComplete(true);
              navigate(
                `${APP_PATHS.QUESTIONAIRE_SUCCESS}?assessment_id=${
                  assessment_id && btoa(assessment_id)
                }&chapter_id=${
                  chapter_id &&
                  btoa(chapter_id)
                }`
              );
            }
          } else {
            setQuestionIndex(questionIndex + 1);
            console.log("data in on submit =>", articleIndex, questionIndex);
            handleChangeQuestionIndex("FORWARD");
          }
        }
      },
    }
  );

  const { mutate: uploadImage } = _useMutation({
    onSuccess: () => {
      getDocuments({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_DOCUMENTS}/${fetchedQuestions?.data?.[questionIndex]?.["org_assign_assessment_id"]}`,
      });
    },
    queryKey: RQ_KEYS.UPLOAD_DOCUMENT,
  });

  const onUploadDeleteSuccess = () => {
    console.log("delete doc successfull");
    getDocuments({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_DOCUMENTS}/${fetchedQuestions?.data?.[questionIndex]?.["org_assign_assessment_id"]}`,
    });
  };

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: RQ_KEYS.CHAPTER_BY_IDS });
      queryClient.removeQueries({ queryKey: RQ_KEYS.ASSESSMENT_BY_IDS });
    };
  }, []);
  // const assessment_id =
  //   parsedQuery?.assessment_id && atob(parsedQuery?.assessment_id as string);
  // const chapter_id =
  //   parsedQuery?.chapter_id && atob(parsedQuery?.chapter_id as string);
  // const org_admin_id = 
  //   parsedQuery?.org_admin_id && atob(parsedQuery?.org_admin_id as any);

  console.log("chapter_id", chapter_id, parsedQuery);

  const question_id =
    parsedQuery?.question_id && atob(parsedQuery?.question_id as string);
  const isAssignAssessment = parsedQuery?.isAssignAssessment as string;
  // let url;
  // if(userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN){
  //  url = `${API_ROUTE.GET_ASSESSMENT_BY_ID}/${assessment_id}/true`
  // }else{
  //  url = `${API_ROUTE.GET_ASSESSMENT_BY_ID}/${assessment_id}`
  // }

  let apiUrl =
    isAssignAssessment && isAssignAssessment === "true"
      ? `${API_ROUTE.GET_ASSESSMENT_BY_ID}/${assessment_id}/${isAssignAssessment}`
      : `${API_ROUTE.GET_ASSESSMENT_BY_ID}/${assessment_id}`;
  const { data: assessment }: any = useApiQuery({
    requestType: REQUEST_TYPE._GET,
    url: apiUrl,
    queryKey: RQ_KEYS.ASSESSMENT_BY_IDS,
  });

  const get_assessment_name = assessment?.assessment_name;
  const assessment_type_id = assessment?.assessment_type_id;
  console.log("🚀 ~ get_assessment_name:", get_assessment_name);

  // useEffect(() => {
  //   if (
  //     assessment &&
  //     assessment.assessment_name === "Service Organization Control Type 2"
  //   ) {
  //     setIsException(true);
  //   }
  // }, [assessment]);

  console.log("assessment in questions", assessment);

  const { data: chapterData }: any = useApiQuery({
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_CHAPTER_BY_ID}/${assessment_id}/${chapter_id}`,
    queryKey: RQ_KEYS.CHAPTER_BY_IDS,
  });
  const chapterLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.CHAPTER_BY_IDS}_loading`,
  });
  const questionLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.QUESTIONS}_loading`,
  });
  const completeQuestionLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.COMPLETED_QUESTIONS}_loading`,
  });
  const taskLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.REMEDIATION_TASKS}_loading`,
  });
  console.log("task loading status ", taskLoadingStatus);
  const documentsLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.DOCUMENTS}_loading`,
  });

  const uploadLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.UPLOAD_DOCUMENT}_loading`,
  });
  console.log("uploadLoadingstatus", uploadLoadingStatus);

  let questions: any = _getQueryState({ key: RQ_KEYS.QUESTIONS });
  let allQuestions: any = _getQueryState({ key: RQ_KEYS.COMPLETE_QUESTIONS });

  const tasks: any = _getQueryState({ key: RQ_KEYS.REMEDIATION_TASKS });
  console.log("tasks in question screen", tasks);
  const documents: any = _getQueryState({ key: RQ_KEYS.DOCUMENTS });
  const documentIds = documents
    ?.map((doc: any) => doc?.assessment_document_id)
    .filter(Boolean);
  console.log("chapterLoadingStatus", chapterLoadingStatus);
  const [articleIndex, setArticleIndex] = React.useState<number>(0);
  const [questionIndex, setQuestionIndex] = React.useState<number>(
    Number(localStorage.getItem("questionIndex")) || 0
  );
  useEffect(() => {
    setNextEnable("Disabled");
    setSaveEnable("Disabled");
  }, [questionIndex]);
  const [fetchedQuestions, setFetchedQuestions] = React.useState<any>({
    data: null,
    followUp: null,
    length: 0,
  });
  const [completeQuestions, setCompleteQuestions] = React.useState<any>({
    data: null,
    length: 0,
  });
  const [showModal, setModal] = React.useState<IAssignModal>({
    state: false,
    modalName: "",
    data: null,
    type: "Task",
  });

  const [showDeleteModal, setDeleteModal] = React.useState<IModal>({
    state: false,
    modalName: "",
    data: null,
    type: "DELETE",
  });

  // useEffect(() => {
  //   const documentIds = documents.map((doc:any) => doc.assessment_document_id);
  // }, [documents])

  console.log("showDeleteModal", showDeleteModal);

  // const checkQuestionLength = () => {
  //   console.log("questions in check qeustion length", questions);
  //   let newQuestions =
  //     questions &&
  //     questions?.length > 0 &&
  //     (userAuthData ||
  //       userAuthData?.role === APP_ROLES.ORG_USER)
  //       ? questions?.filter(
  //           (question: any) =>
  //             Number(question?.assigned_to_user_id) ===
  //             Number(userAuthData?.user_id)
  //         )
  //       : questions;

  //   const followupQuestions = newQuestions?.filter(
  //     (ques: any) => ques.is_follow_up === "YES"
  //   );

  //   newQuestions = newQuestions?.filter(
  //     (ques: any) => ques.is_follow_up === "NO"
  //   );
  //   console.log("newQuestions in check qeustion length", newQuestions);

  //   if (newQuestions && newQuestions.length > 0) {
  //     return {
  //       data: newQuestions.toSorted(
  //         (a: any, b: any) => a.question_number - b.question_number
  //       ),
  //       followUp: followupQuestions,
  //       length: newQuestions.length,
  //     };
  //   } else if (questions && questions.length > 0) {
  //     return {
  //       data: questions,
  //       followUp: null,
  //       length: questions.length,
  //     };
  //   } else {
  //     return { data: null, length: 0 };
  //   }
  // };

  useMemo(() => {
    console.log("fetchedQuestions", questions);
    if (questions && questions?.length > 0) {
      let newQuestions =
        userAuthData?.role === APP_ROLES.VENDOR_USER ||
        userAuthData?.role === APP_ROLES.ORG_USER
          ? questions?.filter(
              (question: any) =>
                Number(question?.assigned_to_user_id) ===
                Number(userAuthData?.user_id)
            )
          : questions;

      const followupQuestions = newQuestions?.filter(
        (ques: any) => ques.is_follow_up === "YES"
      );

      console.log(">>>>>>>>> followupQuestions", followupQuestions);

      newQuestions = newQuestions?.filter(
        (ques: any) => ques.is_follow_up === "NO"
      );
      console.log("newQuestions in check qeustion length", newQuestions);

      if (newQuestions && newQuestions?.length > 0) {
        setFetchedQuestions({
          data: newQuestions.toSorted(
            (a: any, b: any) => a.question_number - b.question_number
          ),
          followUp: followupQuestions,
          length: newQuestions?.length,
        });
        // return {
        //   data: newQuestions.toSorted(
        //     (a: any, b: any) => a.question_number - b.question_number
        //   ),
        //   followUp: followupQuestions,
        //   length: newQuestions.length,
        // };
      } else if (questions && questions.length > 0) {
        setFetchedQuestions({
          data: questions,
          followUp: null,
          length: questions?.length,
        });
        // return {
        // data: questions,
        // followUp: null,
        // length: questions.length,
        // };
      } else {
        setFetchedQuestions({ data: null, followUp: null, length: 0 });
        // return { data: null, length: 0 };
      }
    }

    if (allQuestions && allQuestions?.length > 0) {
      console.log("allQuestions", allQuestions);
      let allNewQuestions =
        userAuthData?.role === APP_ROLES.VENDOR_USER ||
        userAuthData?.role === APP_ROLES.ORG_USER
          ? allQuestions?.filter(
              (question: any) =>
                Number(question?.assigned_to_user_id) ===
                Number(userAuthData?.user_id)
            )
          : allQuestions;

      const followupQuestions = allNewQuestions?.filter(
        (ques: any) => ques.is_follow_up === "YES"
      );

      allNewQuestions = allNewQuestions?.filter(
        (ques: any) => ques.is_follow_up === "NO"
      );
      console.log("newQuestions in check qeustion length", allNewQuestions);

      if (allNewQuestions && allNewQuestions?.length > 0) {
        setCompleteQuestions({
          data: allNewQuestions.toSorted(
            (a: any, b: any) => a.question_number - b.question_number
          ),
          followUp: followupQuestions,
          length: allNewQuestions?.length,
        });
      } else if (allQuestions && allQuestions?.length > 0) {
        setCompleteQuestions({
          data: allQuestions,
          followUp: null,
          length: allQuestions?.length,
        });
      } else {
        setCompleteQuestions({ data: null, followUp: null, length: 0 });
      }
    }
  }, [questions, allQuestions, userAuthData]);

  console.log("completeQuestions", allQuestions);

  const isMyAssessment =
    userAuthData?.user_id ===
      fetchedQuestions?.data?.[questionIndex]?.["assigned_to_user_id"] ||
    userAuthData?.user_id ===
      fetchedQuestions?.data?.[questionIndex]?.["assigned_by_user_id"] ||
    userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN ||
    userAuthData?.role === APP_ROLES.AUDITOR;

  const onChangeQuestionIndex = (index: number) => {
    localStorage.setItem("questionIndex", index.toString());
    setQuestionIndex(index);
    setCommentText('');
  };

  React.useMemo(() => {
    if (
      chapterData &&
      chapterData.articles &&
      chapterData.articles[articleIndex]
    ) {
      console.log("CHAPTER_DATA", chapterData);
      getQuestions({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS}?assessment_id=${assessment_id}&chapter_id=${chapter_id}&org_admin_id=${org_admin_id}`,
      });
      getCompleteQuestions({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_ALL_ASSIGNED_QUESTIONS}?assessment_id=${assessment_id}`,
      });
    }
  }, [chapterData]);
  React.useMemo(() => {
    console.log(" fetchedQuestions", fetchedQuestions);
    if (
      fetchedQuestions?.data?.length > 0 &&
      chapterData &&
      chapterData.articles &&
      chapterData.articles[articleIndex]
    ) {
      getTasksRelatedWithQuestion({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_TASKS_WITH_RELATED_QUESTIONS}?article_id=${
          chapterData?.articles[articleIndex]["article_id"] || undefined
        }&question_id=${
          fetchedQuestions?.data?.[questionIndex]?.["question_id"] || undefined
        }`,
      });

      console.log("check qqqq", fetchedQuestions);

      getDocuments({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_DOCUMENTS}/${fetchedQuestions?.data?.[questionIndex]?.["org_assign_assessment_id"]}`,
      });
    }
  }, [chapterData, questions, questionIndex, fetchedQuestions]);

  console.log("checkquestion length", fetchedQuestions);

  const handleDeleteImage = (document_id: any) => {
    const org_assign_assessment_id =
      fetchedQuestions?.data?.[questionIndex]?.["org_assign_assessment_id"];

    setDeleteModal({
      state: true,
      modalName: ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT,
      type: "DELETE",
      data: { org_assign_assessment_id, document_id },
    });
  };

  // const isAnswerListValid = (answerList: AnswerList[] | undefined): boolean => {
  //   if (!answerList) return false;
  //   return answerList.every((item) => item.text.trim() !== "");
  // };

  const recursiveValidator = (
    referenceQuestions: any,
    currentFollowupAnswers: any,
    currentQuestion: any,
    list: any
  ): boolean => {
    const currAns = currentFollowupAnswers?.find(
      (ans: any) =>
        Number(ans?.org_assign_assessment_id) ===
        Number(currentQuestion?.org_assign_assessment_id)
    );

    if (currAns) {
      if (currAns?.question_type === "Objective") {
        if (currAns.answer?.length <= 0) return false;
        const selectedOption = currentQuestion?.options.find(
          (opt: any) => Number(opt.assign_option_id) === Number(currAns.answer)
        );
        console.log("selectedOption", selectedOption);

        if (selectedOption?.child_assign_question_ids) {
          let res: boolean[] = [];
          for (let id of JSON.parse(
            selectedOption.child_assign_question_ids
          ) as any) {
            const cQues = referenceQuestions.find(
              (ques: any) => Number(ques.question_id) === Number(id)
            );
            console.log("cQues", id, cQues, referenceQuestions);

            const resLoop = recursiveValidator(
              referenceQuestions,
              currentFollowupAnswers,
              cQues,
              list
            );
            res.push(resLoop);
          }
          if (res.every((o: any) => o)) {
            list.push(currAns);
            return true;
          } else return false;
        } else {
          list.push(currAns);
          return true;
        }
      } else if (currAns?.question_type === "Subjective") {
        if (currAns?.answer_subjective?.length <= 0) {
          return false;
        } else {
          list.push(currAns);
          return true;
        }
      } else if (currAns?.question_type === "List") {
        if (currAns?.answer_list.every((item: any) => item.text?.length > 0)) {
          list.push(currAns);
          return true;
        } else return false;
      } else if (currAns?.question_type === "Multi-Option Selection") {
        if (currAns?.multi_option_answer?.length <= 0) {
          return false;
        } else {
          list.push(currAns);
          return true;
        }
      } else {
        list.push(currAns);
        return true;
      }
    } else return true;
  };

  const isValidAnswer = () => {
    console.log(
      "current followup answers in validation",
      currentFollowupAnswers,
      fetchedQuestions?.data,
      fetchedQuestions?.followUp
    );
    let referenceQuestions: any = [];
    if (
      fetchedQuestions &&
      fetchedQuestions?.data &&
      fetchedQuestions?.data?.length > 0 &&
      fetchedQuestions?.followUp &&
      fetchedQuestions?.followUp?.length > 0
    ) {
      referenceQuestions = [
        ...fetchedQuestions?.data,
        ...fetchedQuestions?.followUp,
      ];
    } else if (
      fetchedQuestions &&
      fetchedQuestions?.data &&
      fetchedQuestions?.data?.length > 0
    ) {
      referenceQuestions = [...fetchedQuestions?.data];
    }

    let list: any = [];
    const isValidated = recursiveValidator(
      referenceQuestions,
      currentFollowupAnswers,
      fetchedQuestions?.data?.[questionIndex],
      list
    );

    console.log("is list validated", list, isValidated);

    console.log("isValidated", isValidated);
    // validation requires recursion
    return { isValidated, list };
  };



  const onSubmitAnswerDynamic = (
    answer: Answer,
    isCompleteOld: boolean,
    type: string,
    currentFollowupAnswers: any
  ) => {
    let isComplete = true;
    let isSimilar = false;

    console.log(
      "Current Question before",
      isComplete,
      answer,
      currentFollowupAnswers
    );

    // if (isException && exceptionValue === "No") {
    //   isComplete = false;
    // }

    if (
      currentFollowupAnswers &&
      currentFollowupAnswers?.length > 0 &&
      currentFollowupAnswers[0]?.answer_text &&
      String(currentFollowupAnswers[0]?.answer_text).trim().startsWith("No") &&
      tasks?.length > 0
    ) {
      isComplete = false;
    }

    setSubmitType(type);
    const { isValidated, list } = isValidAnswer();

    console.log("isValidated:",isValidated);

    if (!isValidated) {
      setErrorText("Please answer all the questions");
      return;
    }

    const currentQuestion = fetchedQuestions?.data?.[questionIndex];

    const currQuestionNumber = currentQuestion?.question_number;

    const allAnswered = fetchedQuestions?.data
      ?.filter(
        (question: any) =>
          question.org_assign_assessment_id !==
          currentQuestion.org_assign_assessment_id
      )
      .every((question: any) => question.assigned_status === "Completed");

    if (allAnswered && questionIndex === fetchedQuestions?.data?.length - 1) {
      type = "next";
    }

    setErrorText("");

    // const currentQuestion = fetchedQuestions?.data?.[questionIndex];
    // console.log("Current Question", currentQuestion);

    if (
      currentQuestion &&
      currentQuestion.options &&
      currentQuestion?.question_type === "Objective"
    ) {
      // if (!answer.answer_option_id) {
      answer.answer_option_id = currentFollowupAnswers[0]?.answer;

      answer.answer_objective = currentQuestion.options.find(
        (option: any) =>
          option.assign_option_id.toString() === answer.answer_option_id
      )?.option_text;
      // }

      const currentOptionText = currentQuestion.options.find(
        (option: any) =>
          option.assign_option_id.toString() === answer.answer_option_id
      )?.option_text;

      console.log("Current Option Text:", currentOptionText);

      if (
        currentOptionText &&
        String(currentOptionText).trim().startsWith("No") &&
        tasks?.length > 0
      ) {
        isComplete = false;
      }

      if (currentOptionText) {
        // Iterate through all questions to find those with the same similar_question_id
        completeQuestions?.data.forEach((question: any) => {
          if (
            question.similar_question_id &&
            question.similar_question_id !== "null" &&
            question.similar_question_id ===
              currentQuestion.similar_question_id &&
            question !== currentQuestion
          ) {
            console.log("Other Matched Questions:", question);
            // Find the option in the other question that matches the option_text
            const matchedOption = question.options.find(
              (option: any) => option.option_text === currentOptionText
            );

            if (matchedOption) {
              const matchedAnswerPayload = {
                answer: {
                  question_type: question?.question_type,
                  answer_option_id: matchedOption?.assign_option_id,
                  answer_subjective: answerText,
                  answer_objective: matchedOption?.option_text,
                  answer_list: [],
                  multi_option_answer: answerCheckId,
                  // exceptions: {
                  //   isException,
                  //   exceptionValue,
                  //   exceptionText,
                  // },
                },
                org_assign_assessment_id: question?.org_assign_assessment_id,
                isComplete: isComplete,
                type,
                isSimilar: true,
              };

              // Call submitAnswer for the matched question
              submitAnswer({
                url: API_ROUTE.SUBMIT_ASSESSMENT_ANSWER_DYNAMIC,
                requestType: REQUEST_TYPE._PATCH,
                requestData: matchedAnswerPayload,
              });
            }
          }
        });
      }
    } else if (
      currentQuestion &&
      currentQuestion?.question_type === "Subjective"
    ) {
      completeQuestions?.data.forEach((question: any) => {
        if (
          question.similar_question_id &&
          question.similar_question_id !== "null" &&
          question.similar_question_id ===
            currentQuestion.similar_question_id &&
          question !== currentQuestion
        ) {
          console.log("Other Matched Questions:", question);
          if (question) {
            const matchedAnswerPayload = {
              answer: {
                question_type: question?.question_type,
                answer_option_id: null,
                answer_subjective: answer?.answer_subjective,
                answer_objective: "",
                answer_list: [],
                multi_option_answer: [],
                // exceptions: {
                //   isException,
                //   exceptionValue,
                //   exceptionText,
                // },
              },
              org_assign_assessment_id: question?.org_assign_assessment_id,
              isComplete:isComplete,
              type,
              isSimilar: true,
            };

            // Call submitAnswer for the matched question
            submitAnswer({
              url: API_ROUTE.SUBMIT_ASSESSMENT_ANSWER_DYNAMIC,
              requestType: REQUEST_TYPE._PATCH,
              requestData: matchedAnswerPayload,
            });
          }
        }
      });
    }

    // Submit the answer for the current question
    submitAnswer({
      url: API_ROUTE.SUBMIT_ASSESSMENT_ANSWER_DYNAMIC,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        answer,
        org_assign_assessment_id:
          fetchedQuestions?.data?.[questionIndex]?.["org_assign_assessment_id"],
        isComplete: isComplete,
        followupAnswers: list,
        type,
        chapter_id,
        currQuestionNumber,
        get_assessment_name,
        isSimilar: false,
        assessment_type_id
      },
    });
   if(type === 'next') setNextIndex(answer);
  };

  const setNextIndex = (answer:Answer) => {
    console.log(">>>>>>>>>>>>>>>answer in next",answer);
    const currentQuestion = fetchedQuestions?.data[questionIndex];

    const allAnswered = fetchedQuestions?.data
      ?.filter(
        (question: any) =>
          question.org_assign_assessment_id !==
          currentQuestion.org_assign_assessment_id
      )
      .every((question: any) => question.assigned_status === "Completed");

        let currentOptionText:string = '';
        if(currentQuestion?.question_type === "Objective"){
          console.log("currentFollowupAnswers in next",currentFollowupAnswers);

        const currentAnswer:any = currentFollowupAnswers[0];
        console.log("currentAnswer",currentAnswer);

        currentOptionText = currentQuestion.options.find(
          (option: any) =>
            option.assign_option_id.toString() === currentAnswer?.answer
        )?.option_text;

        console.log("currentOptionText",currentOptionText);
        }

    setSubmitType("next");
    if (allAnswered && chapterData?.chapter_name !== "BIA") {
      // setEntryComplete(true);
      if(currentOptionText && currentOptionText !== '' &&  String(currentOptionText).trim().startsWith("No") && tasks?.length > 0 && (questionIndex === fetchedQuestions?.data?.length - 1) ){
        setEntryComplete(false);
        navigate(
          `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
            questions?.[0]?.assessment_id
          )} `
        );
      }else{
        setEntryComplete(true);
        navigate(
              `${APP_PATHS.QUESTIONAIRE_SUCCESS}?assessment_id=${
                assessment_id && btoa(assessment_id)
              }&chapter_id=${
                chapter_id &&
                btoa(chapter_id)
              }`
            );
      }
    } else {
      if(questionIndex ===
        fetchedQuestions?.data?.length - 1){
          navigate(
            `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
              questions?.[0]?.assessment_id
            )} `
          );
        }else{
          setQuestionIndex(questionIndex + 1);
          handleChangeQuestionIndex("FORWARD");
        }
    }
    setNextEnable("Disabled");
  };

  const handleButtonState = (currentFollowupAnswers: any) => {
    console.log(">>currentFollowupAnswers>>", currentFollowupAnswers);
    const currentQuestion = fetchedQuestions?.data?.[questionIndex];

    console.log("<<< questionIndex >>>",questionIndex)

    //   if (!Array.isArray(currentFollowupAnswers)) {
    //     console.warn("Expected an array for currentFollowupAnswers, received:", currentFollowupAnswers);
    //     setNextEnable("Disabled");
    //     setSaveEnable("Disabled");
    //     return;
    // }

    let currentOptionText: any;
    if (
      currentQuestion &&
      currentQuestion?.question_type === "Objective" &&
      currentFollowupAnswers
    ) {
      currentOptionText = currentQuestion?.options?.find(
        (option: any) =>
          option.assign_option_id.toString() ===
          currentFollowupAnswers[0]?.answer
      )?.option_text;
      console.log(">>>>>>>currentOptionText", currentOptionText);
    }

    if (currentQuestion && currentQuestion?.question_type === "Objective") {
      if (
        (currentFollowupAnswers && currentFollowupAnswers[0]?.answer_text) ||
        currentOptionText
      ) {
        if (
          ((currentFollowupAnswers &&
            currentFollowupAnswers[0]?.answer_text?.trim().startsWith("No")) ||
          currentOptionText?.trim().startsWith("No")) && tasks?.length > 0
        ) {
          console.log("<<< Entered in if condition >>>")
          setNextEnable("Disabled");
          setSaveEnable("Enabled");
        } else {
          const { isValidated, list } = isValidAnswer();
          if(!isValidated){
            setNextEnable("Disabled");
            setSaveEnable("Disabled");
          }else{
            setNextEnable("Enabled");
            setSaveEnable("Enabled");
          }
        }
      } else {
        setNextEnable("Disabled");
        setSaveEnable("Disabled");
      }
    } else if (
      currentQuestion &&
      currentQuestion?.question_type === "Subjective"
    ) {
      if (
        currentFollowupAnswers &&
        currentFollowupAnswers[0]?.answer_subjective !== ""
      ) {
          setNextEnable("Enabled");
          setSaveEnable("Enabled");
        // if (isException && exceptionValue === "No") {
        //   setNextEnable("Disabled");
        //   setSaveEnable("Enabled");
        // } else {
        //   setNextEnable("Enabled");
        //   setSaveEnable("Enabled");
        // }
      } else {
        setNextEnable("Disabled");
        setSaveEnable("Disabled");
      }
    } else if (
      currentQuestion &&
      currentQuestion?.question_type === "Multi-Option Selection"
    ) {
      if (
        currentFollowupAnswers &&
        currentFollowupAnswers[0]?.multi_option_answer?.length > 0
      ) {
        setNextEnable("Enabled");
        setSaveEnable("Enabled");
      } else {
        setNextEnable("Disabled");
        setSaveEnable("Disabled");
      }
    }
  };

  useEffect(() => {
    handleButtonState(currentFollowupAnswers);
  }, [currentFollowupAnswers, fetchedQuestions?.data, tasks ]);  
  //  exceptionValue

  const onSubmitAnswer = (answer: string, isComplete: boolean) => {
    if (comments?.length === 0 && documents?.length === 0) {
      if (answer === "Yes") {
        setErrorText("Please upload a document or add a comment");
        return;
      } else {
        setErrorText("");
      }
      setErrorText("");
    }
    setErrorText("");
    submitAnswer({
      url: API_ROUTE.SUBMIT_ASSESSMENT_ANSWER,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        answer: answer,
        org_assign_assessment_id:
          fetchedQuestions?.data?.[questionIndex]?.["org_assign_assessment_id"],
        isComplete,
        followupAnswers: currentFollowupAnswers,
        get_assessment_name,
      },
    });
  };

  const { mutate: updateAssignedStatus } = _useMutation({
    onSuccess: () => {
      queryClient
        .refetchQueries({
          queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
        })
        .then();
      queryClient
        .refetchQueries({
          queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS,
        })
        .then();
      queryClient
        .refetchQueries({
          queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS,
        })
        .then();
      queryClient
        .refetchQueries({
          queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_TASK_COUNT,
        })
        .then();
      setTaskDetailModal({ state: false, task: null });
    },
    queryKey: RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS,
  });

  const handleMarkAsComplete = ({
    org_assign_assessment_id,
    comment_thread_id,
  }: any) => {
    updateAssignedStatus({
      url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        org_assign_assessment_id,
        assigned_status: ASSIGNED_STATUS.COMPLETED,
        comment_thread_id,
      },
    });
  };

  const handleMarkAsAssigned = ({ org_assign_assessment_id, comment_thread_id, }: any) => {
    // console.log("org_assign_assessment_id", assignee_notes);
    updateAssignedStatus({
      url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        org_assign_assessment_id,
        assigned_status: ASSIGNED_STATUS.ASSIGNED,
        comment_thread_id,
      },
    });
  };

  const updateTaskAssignedStatusLoading = _getQueryState({
    key: `${RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS}_loading`,
  });

  const comments: any = _getQueryState({ key: RQ_KEYS.COMMENTS });

  const { mutate: getComments } = _useMutation({
    queryKey: RQ_KEYS.COMMENTS,
  });

  const commentsLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.COMMENTS}_loading`,
  });
  const goBack = () => {
    if (
      userAuthData?.role === APP_ROLES.ORG_ADMIN ||
      userAuthData?.role === APP_ROLES.AUDITOR ||
      userAuthData?.role === APP_ROLES.BRANCH_ADMIN ||
      userAuthData?.role === APP_ROLES.VENDOR_USER ||
      userAuthData?.role === APP_ROLES.ORG_USER ||
      userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN
    ) {
      navigate(-1);
    } else {
      navigate("/assigned-assessments", {
        state: {
          offset: location?.state?.offset || 0,
          selectedAssessmentId: location?.state?.selectedAssessmentId || 1,
        },
      });
    }
  };

  const comment_thread_id =
    (fetchedQuestions?.length > 0 &&
      fetchedQuestions?.data?.[questionIndex]?.["comment_thread_id"]) ||
    "";

  const { mutate: addComment } = _useMutation({
    onSuccess: () => {
      getComments({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_COMMENTS}/${comment_thread_id}`,
      });
    },
    queryKey: RQ_KEYS.ADD_COMMENT,
  });

  const handleAddComment = () => {
    if (commentText?.length > 0) {
      addComment({
        url: API_ROUTE.ADD_COMMENT,
        requestType: REQUEST_TYPE._POST,
        requestData: {
          comment_thread_id,
          text: commentText,
        },
      });
    }
    setCommentText("");
  };

  useEffect(() => {
    if (comment_thread_id)
      getComments({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_COMMENTS}/${comment_thread_id}`,
      });
  }, [getComments, comment_thread_id, questionIndex]);

  useEffect(() => {
    if (
      fetchedQuestions?.data?.[questionIndex]?.["question_type"] ===
      "Subjective"
    ) {
      if (fetchedQuestions?.data?.[questionIndex]?.["answer_subjective"]) {
        setAnswerText(
          fetchedQuestions?.data?.[questionIndex]?.["answer_subjective"]
        );

        // if (fetchedQuestions?.data?.[questionIndex]?.["exception_value"]) {
        //   // setExceptionValue(
        //   //   fetchedQuestions?.data?.[questionIndex]?.["exception_value"]
        //   // );
        // }
        // //  else {
        // //   setExceptionValue(null);
        // // }

        // if (fetchedQuestions?.data?.[questionIndex]?.["exception_text"]) {
        //   // setExceptionText(
        //   //   fetchedQuestions?.data?.[questionIndex]?.["exception_text"]
        //   // );
        // } 
        // else {
        //   setExceptionText("");
        // }
      } else {
        setAnswerText("");
        // setExceptionValue(null);
        // setExceptionText("");
      }
    } else if (
      fetchedQuestions?.data?.[questionIndex]?.["question_type"] === "Objective"
    ) {
      Number(
        setAnswerOptionId(fetchedQuestions?.data?.[questionIndex]?.["answer"])
      );

      const option = fetchedQuestions.data?.[questionIndex]["options"]?.find(
        (option: any) =>
          Number(option.assign_option_id) ===
          Number(fetchedQuestions?.data?.[questionIndex]?.["answer"])
      );
      console.log("option in use effect", option);
    } else if (
      fetchedQuestions?.data?.[questionIndex]?.["question_type"] ===
      "Multi-Option Selection"
    ) {
      setAnswerCheckId(
        JSON.parse(
          fetchedQuestions?.data?.[questionIndex]?.["multi_option_answer"]
        ) || []
      );
    } else {
      if (fetchedQuestions?.data?.[questionIndex]?.["answer_list"]) {
        setAnswerList(
          JSON.parse(fetchedQuestions?.data?.[questionIndex]?.["answer_list"])
        );
      } else {
        setAnswerList(initialAnswerList);
      }
    }
    if (fetchedQuestions?.data?.length > 0) {
      console.log("in use effect", fetchedQuestions);
      const currentArticleId =
        fetchedQuestions?.data?.[questionIndex]?.["article_id"];

      console.log("checkQuesdion lengt", fetchedQuestions);
      let allCurrentQuestions: any = [];
      if (fetchedQuestions?.followUp?.length > 0) {
        allCurrentQuestions = [
          fetchedQuestions?.data?.[questionIndex],
          ...fetchedQuestions?.followUp?.filter(
            (ques: any) => ques.article_id === currentArticleId
          ),
        ];
      } else if (fetchedQuestions?.data?.length > 0) {
        allCurrentQuestions = [fetchedQuestions?.data?.[questionIndex]];
      }

      console.log("all current question", allCurrentQuestions);
      allCurrentQuestions &&
        allCurrentQuestions?.length > 0 &&
        setCurrentFollowupAnswers(
          allCurrentQuestions?.map((ques: any) => {
            if (ques?.question_type === "List") {
              return {
                org_assign_assessment_id: ques?.org_assign_assessment_id,
                comment_thread_id: ques?.comment_thread_id,
                question_type: ques?.question_type,
                answer: ques?.answer || "",
                answer_subjective: ques?.answer_subjective || "",
                answer_list: ques?.answer_list || [{ id: uuidv4(), text: "" }], // => [{id: ...,text: ...}]
                multi_option_answer: ques?.multi_option_answer || [], // => [id]
                show: ques?.answer_list?.length > 0,
              };
            } else {
              return {
                org_assign_assessment_id: ques?.org_assign_assessment_id,
                comment_thread_id: ques?.comment_thread_id,
                question_type: ques?.question_type,
                answer: ques?.answer || "",
                answer_subjective: ques?.answer_subjective || "",
                answer_list: ques?.answer_list || [], // => [{id: ...,text: ...}]
                multi_option_answer: ques?.multi_option_answer || [], // => [id]
                show:
                  ques?.answer ||
                  ques?.multi_option_answer?.length > 0 ||
                  ques?.answer_subjective,
              };
            }
          })
        );
    }

    setErrorText("");
  }, [questionIndex, fetchedQuestions]);

  useEffect(() => {
    const currentQuestionArticle =
      fetchedQuestions?.data?.[questionIndex]?.article_id;

    const currentArticle = chapterData?.articles?.find(
      (article: any) => article.assign_article_id === currentQuestionArticle
    );

    console.log("currentArticle =>", currentQuestionArticle, currentArticle);

    if (
      currentArticle &&
      (currentArticle?.article_question_type === "TABLE_TYPE" ||
        currentArticle?.article_question_type === "TABLE_TYPE_ADD_ROW")
    ) {
      setShowQuestionTable(true);
    } else {
      setShowQuestionTable(false);
    }
  }, [chapterData, fetchedQuestions, questionIndex]);

  const assignedStatusDynamic =
    fetchedQuestions &&
    fetchedQuestions.data &&
    (fetchedQuestions?.data?.[questionIndex]?.["assigned_status"] ===
      "Incomplete" ||
      fetchedQuestions?.data?.[questionIndex]?.["assigned_status"] ===
        "Assigned")
      ? "Incomplete"
      : "Completed";
  const assignedStatus = () => {
    const answer = fetchedQuestions?.data[questionIndex]["answer"];
    if (fetchedQuestions?.data && (answer === "Yes" || answer === "NA")) {
      return `Completed`;
    } else if (answer === "No" && tasks?.length === 0) {
      return `Completed`;
    } else if (
      answer === "No" &&
      tasks?.every(
        (task: any) => task.assigned_status === ASSIGNED_STATUS.COMPLETED
      )
    ) {
      return `Completed`;
    } else if (
      answer === "No" &&
      tasks?.every(
        (task: any) => task.assigned_status !== ASSIGNED_STATUS.COMPLETED
      )
    ) {
      return `In Progress`;
    } else if (
      answer === "No" &&
      tasks?.every(
        (task: any) => task.assigned_status === ASSIGNED_STATUS.COMPLETED
      )
    ) {
      return `In Progress`;
    } else if (answer === "Assigned") {
      return `In Progress`;
    } else {
      return `Not Started`;
    }
  };

  console.log("datachapterData", chapterData, "questions", questions);
  console.log("fetchedQuestions.data123", fetchedQuestions.data);
  console.log("fetchedQuestions.data1234", fetchedQuestions);
  const [showGuidance, setShowGuidance] = useState<number[]>([]);

  const handleGuidance = (assign_option_id: number) => {
    const foundGuidance = showGuidance.includes(assign_option_id);
    let newShowGuidance = showGuidance;
    if (foundGuidance) {
      newShowGuidance = showGuidance.filter((n) => n !== assign_option_id);
    } else {
      newShowGuidance = [...showGuidance, assign_option_id];
    }
    setShowGuidance(newShowGuidance);
  };

  const { mutate: uploadImageAssessment, isLoading: uploadImageLoading } =
    _useMutation({
      onSuccess: () => {
        queryClient
          .refetchQueries({ queryKey: RQ_KEYS.DOCUMENT_LIB })
          .then((data: any) => {
            getDocuments({
              requestType: REQUEST_TYPE._GET,
              url: `${API_ROUTE.GET_DOCUMENTS}/${fetchedQuestions?.data?.[questionIndex]?.["org_assign_assessment_id"]}`,
            });
            queryClient.refetchQueries({ queryKey: RQ_KEYS.DOCUMENTS }).then();
            setShowDocument(false);
          });

        queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
          message: "",
          toastType: "",
        });
      },
      queryKey: RQ_KEYS.UPLOAD_DOCUMENT,
    });

  const { mutate: selectDoc, isLoading: uploadSelectLoading } = _useMutation({
    onSuccess: () => {
      queryClient
        .refetchQueries({ queryKey: RQ_KEYS.DOCUMENT_LIB })
        .then((data: any) => {
          getDocuments({
            requestType: REQUEST_TYPE._GET,
            url: `${API_ROUTE.GET_DOCUMENTS}/${fetchedQuestions?.data?.[questionIndex]?.["org_assign_assessment_id"]}`,
          });
          queryClient.refetchQueries({ queryKey: RQ_KEYS.DOCUMENTS }).then();
          setShowDocument(false);
        });

      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: "",
        toastType: "",
      });
    },
    queryKey: RQ_KEYS.SELECT_DOCUMENT,
  });

  const handleListItemChange = (value: string, id: string) => {
    console.log("newAnswerlist value", value);
    setAnswerList((prev) => {
      const foundIdx = prev?.findIndex((el) => el.id === id);

      console.log("value in handleListItemChange ", foundIdx);
      let newAnswerList = [...prev];

      if (foundIdx >= 0) {
        newAnswerList[foundIdx] = { id, text: value };

        console.log("newAnswerlist", newAnswerList);
        return newAnswerList;
      } else {
        return prev;
      }
    });
  };

  const handleDeleteFromAnswerList = (id: string) => {
    setAnswerList((prev) => prev.filter((el) => el.id !== id));
  };

  const handleChangeQuestionIndex = (type: "BACK" | "FORWARD") => {
    const newQuestionIndex =
      type === "BACK" ? questionIndex - 1 : questionIndex + 1;
    onChangeQuestionIndex(newQuestionIndex);

    const article_id = fetchedQuestions.data[newQuestionIndex]["article_id"];

    const foundArticleIdx = chapterData?.articles?.findIndex(
      (article: any) => article.article_id === article_id
    );

    setArticleIndex(foundArticleIdx >= 0 ? foundArticleIdx : 0);
  };

  const handleChangeArticleDropdown = (selectedValue: number) => {
    setArticleIndex(selectedValue);
    const question_id = chapterData?.articles[
      selectedValue
    ]?.questions.toSorted(
      (a: any, b: any) => a.question_number - b.question_number
    )[0].assign_question_id;

    const foundQuestionIdx = fetchedQuestions?.data?.findIndex(
      (d: any) => d?.question_id === question_id
    );

    setQuestionIndex(foundQuestionIdx >= 0 ? foundQuestionIdx : 0);
  };

  const handleObjectiveRadioChange = (option: any) => {
    console.log("option in handleObjectiveRadioChange", option);
    if (option?.child_assign_question_ids) {
      const questionIdArr = JSON.parse(option?.child_assign_question_ids);
      const currentQuestionId =
        fetchedQuestions?.data?.[questionIndex]?.["question_id"];

      const currentFollowup =
        fetchedQuestions?.followUp
          ?.filter(
            (ques: any) =>
              // questionIdArr?.includes(ques.question_id)
              currentQuestionId === ques.parent_assign_question_id
          )
          .map((ques: any) => {
            if (ques?.multi_option_answer) {
              return {
                ...ques,
                multi_option_answer: JSON.parse(ques?.multi_option_answer),
                show: questionIdArr.includes(ques?.question_id),
              };
            } else if (ques?.answer_list) {
              return {
                ...ques,
                answer_list: JSON.parse(ques.answer_list),
                show: questionIdArr.includes(ques?.question_id),
              };
            } else {
              return {
                ...ques,
                show: questionIdArr.includes(ques?.question_id),
              };
            }
          }) || [];

      console.log("current follow up in on change", currentFollowup);

      let newCurrentFollowup: any = [];

      currentFollowup.forEach((ques: any) => {
        if (ques.question_type === "Objective") {
          for (let opt of ques.options) {
            if (opt?.child_assign_question_ids) {
              const child_assign_question_ids = JSON.parse(
                opt.child_assign_question_ids
              );

              opt.currentFollowup = currentFollowup.filter((ques: any) =>
                child_assign_question_ids.includes(ques?.question_id)
              );
            }
          }
        }
        newCurrentFollowup.push(ques);
      });
      newCurrentFollowup = newCurrentFollowup.filter((ques: any) =>
        questionIdArr.includes(ques?.question_id)
      );
      setCurrentFollowup(
        newCurrentFollowup?.toSorted(
          (a: any, b: any) => a.question_number - b.question_number
        ) || []
      );
    } else {
      setCurrentFollowup([]);
    }

    const allFollowups = fetchedQuestions?.followUp;

    console.log("all follow ups", allFollowups);

    setCurrentFollowupAnswers(
      allFollowups.map((ques: any) => {
        if (ques.question_type === "List") {
          return {
            org_assign_assessment_id: ques.org_assign_assessment_id,
            comment_thread_id: ques.comment_thread_id,
            question_type: ques.question_type,
            answer: ques.answer || "",
            answer_subjective: ques.answer_subjective || "",
            answer_list: ques.answer_list || [{ id: uuidv4(), text: "" }], // => [{id: ...,text: ...}]
            multi_option_answer: ques?.multi_option_answer || [], // => [id]
          };
        } else {
          return {
            org_assign_assessment_id: ques.org_assign_assessment_id,
            comment_thread_id: ques.comment_thread_id,
            question_type: ques.question_type,
            answer: ques.answer || "",
            answer_subjective: ques.answer_subjective || "",
            answer_list: ques.answer_list || [], // => [{id: ...,text: ...}]
            multi_option_answer: ques?.multi_option_answer || [], // => [id]
          };
        }
      })
    );
  };
  console.log({ entryComplete, showQuestionTable });
  // (()=> console.log("allTasks",allTasks && allTasks.length > 0 && allTasks.filter(
  //   (task: any) =>
  //     task.question_task_id === taskDetailModal.task.task_id
  // )[0]))()
  console.log("allTasksallTasks", taskDetailModal, "allTasks1", allTasks);
  const onClickAssignQuestion = ({ selectedQuestion, data }: any) => {
    console.log("fetchedQuestions?.data?.[questionIndex]", selectedQuestion);

    let isReassignQuestion = false;
    if (
      selectedQuestion?.assigned_to_count &&
      selectedQuestion?.assigned_to_count > 0
      // selectedQuestion?.assignee &&
      // selectedQuestion?.assigned_by_user_id === userAuthData?.user_id &&
      // selectedQuestion?.assigned_by_user_id !==
      // selectedQuestion?.assigned_to_user_id
    ) {
      isReassignQuestion = true;
    }
    setModal({
      state: true,
      modalName: QUESTION_SCREEN_MODAL_NAMES.ASSIGN,
      type: isReassignQuestion ? "Question_Reassign" : "Question",
      data: data,
    });
  };
  return (
    <>
      {/* <UploadFile heading="Upload File"/> */}
      <div className={styles.questionContainer}>
        <div className={styles.questionMainSection}>
          <div className={styles.questionHeaderSection}>
            <div className="contentHeader">
              <Button
                label=""
                id="backBtn"
                padding="6px 0"
                color="var(--blackColor)"
                background="transparent"
                fontSize="var(--secondaryFont)"
                onClick={() => {
                  goBack();
                  // navigate(
                  //   `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
                  //     assessment_id as string
                  //   )} `
                  // );
                }}
                border="none"
                Icon={<BackIcon className="svg_image_icon" />}
              />
              <div>
                <div className={styles.headingBlock}>
                  <h4>{assessment?.assessment_name}</h4>
                </div>
                <LoadingHoc isLoading={chapterLoadingStatus}>
                  <div className={styles.contentBlock}>
                    <p>
                      Chapter {chapterData && chapterData?.chapter_number}:{" "}
                      <span> {chapterData && chapterData["chapter_name"]}</span>
                    </p>
                    {userAuthData &&
                      !entryComplete &&
                      // get_assessment_name !==
                      //   "Business Impact Analysis (BIA)" &&
                      // get_assessment_name !==
                      //   "Cyber Security Assessment for Medical Facilities" &&
                      // get_assessment_name !== "Software Vendor-Security"
                      
                      chapterData && // Done these changes to show articles where chapter and articles are not same
                        chapterData.articles && 
                        chapterData?.articles[articleIndex][
                          "is_required"
                        ] === "YES"
                      && 
                      (
                        <p className={styles.flexParagraph}>
                          <span>
                            {/* {get_assessment_name ===
                            "Record of Processing Activities"
                              ? "Section"
                              : "Article"} */}
                              Section
                            {chapterData &&
                              chapterData.articles &&
                              chapterData.articles?.length == 1 &&
                              chapterData?.articles[articleIndex][
                                "article_number"
                              ]}
                            :
                          </span>
                          {chapterData &&
                          chapterData.articles &&
                          chapterData.articles?.length > 1 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <MultiSelect
                                  label=""
                                  name="article"
                                  placeholder="Select"
                                  // isRequired={true}
                                  includeNone={true}
                                  values={
                                    chapterData &&
                                    chapterData.articles &&
                                    chapterData.articles?.length > 0 && {
                                      value:
                                        chapterData?.articles[articleIndex][
                                          "article_id"
                                        ] - 1,
                                      label: `${chapterData?.articles[articleIndex]["article_number"]}: ${chapterData?.articles[articleIndex]["article_name"]}`,
                                    }
                                  }
                                  defaultValue={
                                    chapterData &&
                                    chapterData.articles &&
                                    chapterData.articles?.length > 0 && {
                                      value:
                                        chapterData.articles[articleIndex][
                                          "article_id"
                                        ],
                                      label:
                                        chapterData?.articles[articleIndex][
                                          "article_name"
                                        ],
                                    }
                                  }
                                  options={chapterData.articles.map(
                                    (
                                      {
                                        article_name,
                                        article_number,
                                        article_id,
                                      }: any,
                                      index: number
                                    ) => {
                                      return {
                                        value: index,
                                        label: `${article_number}: ${article_name}`,
                                      };
                                    }
                                  )}
                                  onChange={(selectedValue: any) =>
                                    handleChangeArticleDropdown(selectedValue)
                                  }
                                  isMulti={false}
                                ></MultiSelect>
                              </div>
                            </div>
                          ) : chapterData?.articles?.length === 1 ? (
                            <div>
                              {
                                chapterData?.articles[articleIndex]?.[
                                  "article_name"
                                ]
                              }
                            </div>
                          ) : null}

                          <div className={styles.articleInfo}>
                            <Tooltip text="View">
                              <InfoIcon
                                // fill="var(--textColor)"
                                // stroke="black"
                                className={styles.iconPrimary}
                                onClick={() => {
                                  // const currentQuestion = fetchedQuestions?.data[questionIndex];
                                  // const followUpMatches:any = [];
                                  // currentQuestion.options.forEach((option:any) => {
                                  //   const childAssignIds = JSON.parse(option.child_assign_question_ids || '[]');

                                  //   // Check if any childAssignIds exist in followUp array
                                  //   childAssignIds.forEach((childId:any) => {
                                  //     const matchedFollowUp = fetchedQuestions.followUp.find(
                                  //       (followUpItem:any) => followUpItem.question_id === childId
                                  //     );

                                  //     if (matchedFollowUp) {
                                  //       followUpMatches.push(matchedFollowUp);
                                  //     }
                                  //   });
                                  // });

                                  setState((prev: any) => ({
                                    ...prev,
                                    selectedChapters: {
                                      chapter_name: chapterData["chapter_name"],
                                      // chapter_number,
                                      chapter_id: chapterData["chapter_id"],
                                    },
                                    selectedArticles: {
                                      article_name:
                                        chapterData?.articles[articleIndex][
                                          "article_name"
                                        ],
                                      article_id:
                                        chapterData.articles[articleIndex][
                                          "article_id"
                                        ],
                                      article_number:
                                        chapterData.articles[articleIndex][
                                          "article_number"
                                        ],
                                      risk_level:
                                        chapterData.articles[articleIndex][
                                          "risk_level"
                                        ],
                                      risk_description:
                                        chapterData.articles[articleIndex][
                                          "risk_description"
                                        ],
                                      law: chapterData.articles[articleIndex][
                                        "law"
                                      ],
                                    },
                                  }));
                                  setModal({
                                    state: true,
                                    modalName:
                                      ASSESSMENT_MODAL_NAMES.VIEW_ARTICLE,
                                    // type: "UPDATE",
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </p>
                      )}
                  </div>
                  {!entryComplete &&
                    chapterData?.articles?.[articleIndex]?.guidance && (
                      <div>
                        <p className={styles.Guidance}>
                          <span>Guidance: </span>
                          {chapterData?.articles?.[articleIndex]?.guidance}
                        </p>
                      </div>
                    )}
                </LoadingHoc>
              </div>
            </div>
          </div>

          {
          // entryComplete ? (
          //   // <QuestionSuccessScreen
          //   //   chapter_name={chapterData?.chapter_name}
          //   //   assessment_id={assessment?.assessment_id}
          //   //   chapter_id={chapter_id}
          //   //   assessment={assessment}
          //   //   setEntryComplete={setEntryComplete}
          //   //   chapterData={chapterData}
          //   // />
          //   navigate(
          //     `${APP_PATHS.QUESTIONAIRE_SUCCESS}?assessment_id=${
          //       assessment_id && btoa(assessment_id)
          //     }&chapter_id=${
          //       chapter_id &&
          //       btoa(chapter_id)
          //     }&chapter_name=${
          //       chapterData?.chapter_name &&
          //       btoa(chapterData?.chapter_name)
          //     }&assessment_name=${
          //       assessment?.assessment_name &&
          //       btoa(assessment?.assessment_name)
          //     }`
          //   );
          // ) :
           showQuestionTable ? (
            <>
              <LoadingHoc isLoading={chapterLoadingStatus}>
                {fetchedQuestions?.data
                  ?.filter(
                    ({ article_id }: any) =>
                      article_id ===
                      chapterData?.articles[articleIndex]?.["article_id"]
                  )
                  .every(
                    ({ question_type }: any) => question_type === "LIST_PROCESS"
                  ) && (
                  <ProcessesTable
                    questions={fetchedQuestions?.data?.filter(
                      ({ article_id }: any) =>
                        article_id ===
                        chapterData?.articles[articleIndex]?.["article_id"]
                    )}
                    setArticleIndex={setArticleIndex}
                    assessmentData={assessment}
                  />
                )}
                {fetchedQuestions?.data
                  ?.filter(
                    ({ article_id }: any) =>
                      article_id ===
                      chapterData?.articles[articleIndex]?.["article_id"]
                  )
                  .some(
                    ({ question_type }: any) => question_type !== "LIST_PROCESS"
                  ) && (
                  //  &&  chapterData?.articles[articleIndex]["answers"]?.length >0
                  <>
                    <QuestionTable
                      questions={fetchedQuestions?.data?.filter(
                        ({ article_id }: any) =>
                          article_id ===
                          chapterData?.articles[articleIndex]?.["article_id"]
                      )}
                      answers={
                        chapterData?.articles[articleIndex]["answers"] || []
                      }
                      articleIndex={articleIndex}
                      articlesLength={chapterData?.articles?.length}
                      setEntryComplete={setEntryComplete}
                      setArticleIndex={setArticleIndex}
                      handleChangeArticleDropdown={handleChangeArticleDropdown}
                      article={chapterData?.articles[articleIndex]}
                      assessmentData={assessment}
                    />
                  </>
                )}
              </LoadingHoc>
            </>
          ) : (
            <LoadingHoc isLoading={chapterLoadingStatus}>
              <div className={styles.questionAnswerSection}>
                <div className={styles.questionNavigator}>
                  {fetchedQuestions?.length > 0 && (
                    <p className={styles.questionPagination}>
                      <span>
                        {fetchedQuestions?.data?.[questionIndex]?.["assignee"]
                          ? `Assigned To: `
                          : null}
                      </span>
                      {fetchedQuestions?.data?.[questionIndex]?.["assignee"]
                        ? fetchedQuestions?.data?.[questionIndex]?.["assignee"]
                        : null}
                    </p>
                  )}
                  <div className={styles.questionNavigationHead}>
                    {fetchedQuestions?.data?.length > 1 && (
                      <div
                        className={`${styles.backIconParent} ${
                          questionIndex > 0
                            ? styles.notDisabled
                            : styles.disabled
                        }`}
                      >
                        <BackArrow
                          fill="var(--blackColor)"
                          style={{
                            maxHeight: "35px",
                            pointerEvents: questionIndex > 0 ? "all" : "none",
                          }}
                          onClick={() => handleChangeQuestionIndex("BACK")}
                        />
                      </div>
                    )}
                    {/* <div style={{ display: "flex" }}> */}
                    {fetchedQuestions?.data?.length > 0 && (
                      <LoadingHoc isLoading={!fetchedQuestions?.length}>
                        <p>
                          {`Question ${questionIndex + 1}`}{" "}
                          {/* {`Question ${fetchedQuestions.data[questionIndex]['question_number']}`}{" "} */}
                          {fetchedQuestions?.length > 1
                            ? `of ${fetchedQuestions.data?.length}`
                            : ""}
                        </p>
                      </LoadingHoc>
                    )}
                    {fetchedQuestions?.data?.length > 1 && (
                      <div
                        className={
                          questionIndex < fetchedQuestions?.data?.length - 1
                            ? styles.notDisabled
                            : styles.disabled
                        }
                      >
                        <NextArrow
                          fill="var(--blackColor)"
                          style={{
                            maxHeight: "35px",
                            pointerEvents:
                              questionIndex < fetchedQuestions?.data?.length - 1
                                ? "all"
                                : "none",
                          }}
                          onClick={() => handleChangeQuestionIndex("FORWARD")}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.assignBtnContainer}>
                    {isMyAssessment &&
                      userAuthData &&
                      userAuthData.role !== APP_ROLES.ORG_USER &&
                      userAuthData?.role !== APP_ROLES.OBOEDIO_ADMIN &&
                      userAuthData.role !== APP_ROLES.VENDOR_USER &&
                      userAuthData.role !== APP_ROLES.AUDITOR && 
                      assessment?.assessment_status !== ASSESSMENT_STATUS.COMPLETED && (
                        <Button
                          id="assign"
                          label="Assign"
                          Icon={<AssignTask fill="var(--whiteColor)" />}
                          // background="var(--whiteColor)"
                          // border="1px solid var(--secondaryColor)"
                          // color="var(--secondaryColor)"
                          onClick={() => {
                            // Checking that any option in current question contains a child_question_id and if it is then push that question as folloUwp
                            // const currentQuestion =
                            //   fetchedQuestions?.data[questionIndex];
                            // const followUpMatches: any = [];
                            // if (currentQuestion?.options) {
                            //   currentQuestion?.options?.forEach(
                            //     (option: any) => {
                            //       const childAssignIds = JSON.parse(
                            //         option.child_assign_question_ids || "[]"
                            //       );

                            //       // Check if any childAssignIds exist in followUp array
                            //       if (childAssignIds) {
                            //         childAssignIds?.forEach((childId: any) => {
                            //           const matchedFollowUp =
                            //             fetchedQuestions.followUp.find(
                            //               (followUpItem: any) =>
                            //                 followUpItem.question_id === childId
                            //             );

                            //           if (matchedFollowUp) {
                            //             followUpMatches.push(matchedFollowUp);
                            //           }
                            //         });
                            //       }
                            //     }
                            //   );
                            // }

                            // Now We can assign Nested Followup questions till 3 nested levels
                            const currentQuestion =
                              fetchedQuestions?.data[questionIndex];
                            const followUpMatches: any = [];

                            if (currentQuestion?.options) {
                              currentQuestion.options.forEach((option: any) => {
                                const childAssignIds = JSON.parse(
                                  option.child_assign_question_ids || "[]"
                                );

                                if (childAssignIds?.length) {
                                  childAssignIds.forEach((childId: any) => {
                                    const matchedFollowUp =
                                      fetchedQuestions.followUp.find(
                                        (followUpItem: any) =>
                                          followUpItem.question_id === childId
                                      );

                                    if (matchedFollowUp) {
                                      followUpMatches.push(matchedFollowUp);

                                      // Additional logic to check options within each follow-up item
                                      if (matchedFollowUp.options) {
                                        matchedFollowUp.options.forEach(
                                          (followUpOption: any) => {
                                            const nestedChildAssignIds =
                                              JSON.parse(
                                                followUpOption.child_assign_question_ids ||
                                                  "[]"
                                              );

                                            // Check for nested childAssignIds and match with follow-up questions
                                            if (nestedChildAssignIds?.length) {
                                              nestedChildAssignIds.forEach(
                                                (nestedChildId: any) => {
                                                  const nestedMatchedFollowUp =
                                                    fetchedQuestions.followUp.find(
                                                      (
                                                        nestedFollowUpItem: any
                                                      ) =>
                                                        nestedFollowUpItem.question_id ===
                                                        nestedChildId
                                                    );

                                                  if (nestedMatchedFollowUp) {
                                                    followUpMatches.push(
                                                      nestedMatchedFollowUp
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                          }
                                        );
                                      }
                                    }
                                  });
                                }
                              });
                            }

                            onClickAssignQuestion({
                              selectedQuestion:
                                fetchedQuestions?.data?.[questionIndex],
                              data: {
                                org_assign_assessment_id:
                                  fetchedQuestions?.data?.[questionIndex]?.[
                                    "assignee_org_assign_assessment_id"
                                  ],
                                question:
                                  fetchedQuestions?.data?.[questionIndex]?.[
                                    "question"
                                  ],
                                assessment_id,
                                chapter_id,
                                article_id:
                                  chapterData.articles[articleIndex][
                                    "article_id"
                                  ],
                                question_id:
                                  fetchedQuestions?.data?.[questionIndex]?.[
                                    "question_id"
                                  ],
                                chapter_name:
                                  chapterData && chapterData["chapter_name"],
                                assessment_name:
                                  assessment && assessment?.assessment_name,
                                assessment_department_id:
                                  assessment && assessment?.department_id,  
                                article_name:
                                  chapterData?.articles[articleIndex][
                                    "article_name"
                                  ],
                                document_ids:
                                  documentIds && documentIds?.length > 0
                                    ? documentIds
                                    : null,
                                followUp: followUpMatches,
                              },
                            });
                            // onSubmitAnswer("Assigned", false);
                          }}
                        />
                      )}
                  </div>
                </div>
                    {fetchedQuestions &&
                      fetchedQuestions.data &&
                      fetchedQuestions.data?.length > 0 &&
                      fetchedQuestions?.data[questionIndex]?.due_date &&
                      new Date(fetchedQuestions.data[questionIndex].due_date).getTime() < new Date().getTime() && (
                        <span className={styles.dueDateMessage}>
                          Deadline missed for this question!
                        </span>
                      )}
                <div className={styles.questionInnerCommon}>
                  <div className={styles.assignedQuestionSection}>
                    <LoadingHoc isLoading={questionLoadingStatus}>
                      {fetchedQuestions &&
                      fetchedQuestions.data &&
                      fetchedQuestions.data?.length > 0 ? (
                        <>
                          <div>
                            <ListQuestions
                              depth={1}
                              depthStr="1"
                              prefix=""
                              currentFollowupAnswers={currentFollowupAnswers}
                              setCurrentFollowupAnswers={
                                setCurrentFollowupAnswers
                              }
                              topLevel={true}
                              currentQuestions={[
                                fetchedQuestions?.data[questionIndex],
                              ]}
                              // referenceQuestions={
                              //   fetchedQuestions?.length > 0 &&
                              //   fetchedQuestions?.followUp?.length > 0
                              //     ? [
                              //         fetchedQuestions?.data[questionIndex],
                              //         ...fetchedQuestions?.followUp?.filter(
                              //           (ques: any) =>
                              //             ques.parent_assign_question_id ===
                              //             fetchedQuestions?.data[questionIndex][
                              //               "question_id"
                              //             ]
                              //         ),
                              //       ]
                              //     : []
                              // }

                              const
                              referenceQuestions={
                                fetchedQuestions?.length > 0 &&
                                fetchedQuestions?.followUp?.length > 0
                                  ? [
                                      fetchedQuestions?.data[questionIndex],
                                      ...fetchedQuestions?.followUp
                                        ?.filter(
                                          (ques: any) =>
                                            ques.parent_assign_question_id ===
                                            fetchedQuestions?.data[
                                              questionIndex
                                            ]["question_id"]
                                        )
                                        ?.sort((a: any, b: any) => a.question_id - b.question_id)  // Sort followUp questions on the basis of their question_id
                                        .flatMap((followUpQuestion: any) => {
                                          // Retrieve child_assign_question_ids from options and parse them
                                          const childQuestionIds =
                                            followUpQuestion.options?.flatMap(
                                              (option: any) =>
                                                option.child_assign_question_ids
                                                  ? JSON.parse(
                                                      option.child_assign_question_ids
                                                    )
                                                  : []
                                            );

                                          console.log(
                                            "childQuestionIds",
                                            childQuestionIds
                                          );

                                          // Map child question IDs to actual follow-up questions
                                          if (
                                            childQuestionIds &&
                                            childQuestionIds?.length > 0
                                          ) {
                                            const childQuestions =
                                              childQuestionIds
                                                ?.map((id: any) =>
                                                  fetchedQuestions?.followUp.find(
                                                    (ques: any) =>
                                                      ques.question_id === id
                                                  )
                                                )
                                                .filter(Boolean); // Filter out any null/undefined results

                                            console.log(
                                              "Follow Up Questions:",
                                              followUpQuestion,
                                              ">>>>",
                                              childQuestions
                                            );

                                            return [
                                              followUpQuestion,
                                              ...childQuestions,
                                            ];
                                          } else {
                                            return [followUpQuestion];
                                          }
                                        }),
                                    ]
                                  : []
                              }
                              // referenceQuestions={[
                              //   fetchedQuestions?.data[questionIndex],
                              //   ...fetchedQuestions.followUp?.filter(
                              //     (ques: any) =>
                              //       ques.article_id ===
                              //       fetchedQuestions?.data[questionIndex][
                              //         "article_id"
                              //       ]
                              //   ),
                              // ]}
                              questionIndex={questionIndex}
                              checkQuestionLength={fetchedQuestions}
                              assignedStatusDynamic={assignedStatusDynamic}
                              setAnswerOptionId={setAnswerOptionId}
                              handleObjectiveRadioChange={
                                handleObjectiveRadioChange
                              }
                              answerOptionId={answerOptionId}
                              handleGuidance={handleGuidance}
                              showGuidance={showGuidance}
                              errorText={errorText}
                              setAnswerCheckId={setAnswerCheckId}
                              answerCheckId={answerCheckId}
                              isMyAssessment={isMyAssessment}
                              setAnswerText={setAnswerText}
                              answerText={answerText}
                              handleListItemChange={handleListItemChange}
                              setAnswerList={setAnswerList}
                              handleDeleteFromAnswerList={
                                handleDeleteFromAnswerList
                              }
                              assessmentStatus={assessment?.assessment_status}
                            />
                            {/* {isMyAssessment &&
                              fetchedQuestions.data[questionIndex][
                                "question_type"
                              ] === "Subjective" &&
                              isException && (
                                <div>
                                  <div className={styles.isException}>
                                    <p>Exception: </p>
                                    <MultiSelect
                                      name="Exception"
                                      label=""
                                      isRequired={false}
                                      placeholder="Select..."
                                      includeNone={true}
                                      options={[
                                        { value: null, label: "Select" },
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                      
                                      ]}
                                      onChange={(
                                        value: "Yes" | "No" | null
                                      ) => {
                                        console.log(
                                          "value in exception",
                                          value
                                        );
                                        if (!value || value === "No") {
                                          setExceptionText("");
                                        }
                                        setExceptionValue(value);
                                      }}
                                      // setFieldValue={setFieldValue}
                                      // onChange={(e: string) => {
                                      //   handleChange(e);
                                      //   setQuestionType(e);
                                      // }}
                                      isMulti={false}
                                      // defaultValue={{
                                      //   value: values?.question_type,
                                      //   label: values?.question_type,
                                      // }}
                                      values={
                                        exceptionValue
                                          ? {
                                              value: exceptionValue,
                                              label: exceptionValue,
                                            }
                                          : ""
                                      }
                                    />
                                  </div>
                                  {exceptionValue === "Yes" && (
                                    <>
                                      <Textarea
                                        cols={30}
                                        rows={5}
                                        name="answer_input"
                                        placeholder="Reason for the Exception"
                                        isRequired={false}
                                        width="100%"
                                        padding="5px 10px"
                                        maxHeight="250px"
                                        onChange={(e: any) =>
                                          setExceptionText(e.target.value)
                                        }
                                        value={exceptionText}
                                        disabled={
                                          userAuthData?.role ===
                                            APP_ROLES.AUDITOR ||
                                          userAuthData?.role ===
                                            APP_ROLES.OBOEDIO_ADMIN
                                            ? true
                                            : false
                                        }
                                      />
                                      <p style={{ color: "red", fontSize: 12 }}>
                                        {errorText}
                                      </p>
                                    </>
                                  )}
                                </div>
                              )
                              
                              } */}
                          </div>
                        </>
                      ) : (
                        "No question added"
                      )}
                    </LoadingHoc>
                  </div>

                  {fetchedQuestions?.length > 0 &&
                    (fetchedQuestions?.data?.[questionIndex]?.["options"]
                      ?.find(
                        (option: any) =>
                          Number(option.assign_option_id) ===
                          Number(
                            fetchedQuestions?.data?.[questionIndex]?.["answer"]
                          )
                      )
                      ?.option_text.trim()
                      .startsWith("No") ||
                      (tasks &&
                        tasks?.length > 0 &&
                        tasks.find(({ assignee }: any) =>
                          assignee ? true : false
                        )) 
                      // ||  (isException && exceptionValue === "No")
                    ) &&
                    isMyAssessment && (
                      <div className="task-Section">
                        <LoadingHoc isLoading={taskLoadingStatus}>
                          {tasks &&
                            tasks?.length > 0 &&
                            tasks.map(
                              (
                                {
                                  task,
                                  task_id,
                                  assignee,
                                  org_assign_assessment_id,
                                  risk_level,
                                  assigned_to_task_count,
                                  document_ids,
                                  due_date,
                                  assigned_by_user_id,
                                  comment_thread_id,
                                  ...otherProps
                                }: any,
                                index: number
                              ) => (
                                <div
                                  className={styles.remediationSection}
                                  key={index}
                                >
                                  <div className={styles.taskHeadingBlock}>
                                    <p>Remediation Task {index + 1}</p>
                                    <div className={styles.assignee}>
                                      {
                                        // assigned_to_task_count > 0 &&
                                        assignee && (
                                          <p>
                                            Assigned To:{" "}
                                            <span> {assignee}</span>
                                          </p>
                                        )
                                      }
                                      {
                                        assigned_to_task_count > 0 &&
                                        // assignee &&
                                        userAuthData?.user_id == assigned_by_user_id &&
                                        (userAuthData?.role ===
                                          APP_ROLES.ORG_ADMIN ||
                                          userAuthData?.role ===
                                            APP_ROLES.BRANCH_ADMIN )? ( // userAuthData?.role !==
                                          //   APP_ROLES.ORG_USER &&
                                          // userAuthData?.role !==
                                          //   APP_ROLES.VENDOR_USER &&
                                          // userAuthData?.role !==
                                          //   APP_ROLES.OBOEDIO_ADMIN
                                          <>
                                            <Button
                                              id="Track_Task"
                                              label="View Task"
                                              onClick={() => {
                                                console.log(
                                                  ">>>>>>>>>> Task Details:",
                                                  tasks
                                                );
                                                setTaskDetailModal({
                                                  state: true,
                                                  task: {
                                                    task,
                                                    task_id,
                                                    assignee,
                                                    org_assign_assessment_id,
                                                    due_date,
                                                    comment_thread_id,
                                                    ...otherProps,
                                                  },
                                                });
                                              }}
                                              Icon={
                                                <ViewIcon fill="var(--whiteColor)" />
                                              }
                                            />
                                            {userAuthData &&
                                              (userAuthData?.role ===
                                                APP_ROLES.ORG_ADMIN ||
                                                userAuthData?.role ===
                                                  APP_ROLES.BRANCH_ADMIN) && 
                                                  assessment?.assessment_status !== ASSESSMENT_STATUS.COMPLETED &&(
                                                // userAuthData?.role !==
                                                //   APP_ROLES.VENDOR_USER &&
                                                // userAuthData?.role !==
                                                //   APP_ROLES.ORG_USER &&
                                                // userAuthData?.role !==
                                                //   APP_ROLES.OBOEDIO_ADMIN &&
                                                // tasks.some(
                                                //   (task: any) =>
                                                //     task.org_assign_assessment_id ===
                                                //     org_assign_assessment_id &&
                                                //     task.assigned_status !==
                                                //     "Completed"
                                                // )
                                                //  &&
                                                <Button
                                                  id="re-assign"
                                                  label="Re-Assign"
                                                  onClick={() => {
                                                    setModal({
                                                      state: true,
                                                      modalName:
                                                        QUESTION_SCREEN_MODAL_NAMES.ASSIGN,
                                                      type: "Task_Reassign",
                                                      data: {
                                                        org_assign_assessment_id:
                                                          org_assign_assessment_id,
                                                        article_id:
                                                          chapterData.articles[
                                                            articleIndex
                                                          ]["article_id"],
                                                        question_id:
                                                          fetchedQuestions.data[
                                                            questionIndex
                                                          ]["question_id"],
                                                        assessment_department_id:
                                                          assessment && assessment?.department_id,   
                                                      },
                                                    });
                                                  }}
                                                  Icon={
                                                    <AssignTask fill="var(--whiteColor)" />
                                                  }
                                                />
                                              )}
                                          </>
                                        ) : (
                                          userAuthData?.role !==
                                            APP_ROLES.ORG_USER &&
                                          userAuthData?.role !==
                                            APP_ROLES.VENDOR_USER &&
                                          userAuthData?.role !==
                                            APP_ROLES.OBOEDIO_ADMIN &&
                                          userAuthData?.role !==
                                            APP_ROLES.AUDITOR && 
                                          assessment?.assessment_status !== ASSESSMENT_STATUS.COMPLETED && (
                                            <Button
                                              id="assign"
                                              label="Assign"
                                              onClick={() => {
                                                setModal({
                                                  state: true,
                                                  modalName:
                                                    QUESTION_SCREEN_MODAL_NAMES.ASSIGN,
                                                  type: "Task",
                                                  data: {
                                                    org_assign_assessment_id:
                                                      fetchedQuestions.data[
                                                        questionIndex
                                                      ][
                                                        "org_assign_assessment_id"
                                                      ],
                                                      // assigned_to_org_assign_assessment_id:
                                                      // fetchedQuestions.data[
                                                      //   questionIndex
                                                      // ][
                                                      //   "assigned_to_org_assign_assessment_id"
                                                      // ],
                                                    task: task,
                                                    task_id: task_id,
                                                    assessment_id,
                                                    document_ids,
                                                    chapter_id,
                                                    article_id:
                                                      chapterData.articles[
                                                        articleIndex
                                                      ]["article_id"],
                                                    question_id:
                                                      fetchedQuestions.data[
                                                        questionIndex
                                                      ]["question_id"],
                                                    chapter_name:
                                                      chapterData &&
                                                      chapterData[
                                                        "chapter_name"
                                                      ],
                                                    assessment_name:
                                                      assessment &&
                                                      assessment?.assessment_name,
                                                    article_name:
                                                      chapterData?.articles[
                                                        articleIndex
                                                      ]["article_name"],
                                                    assessment_department_id:
                                                      assessment &&
                                                      assessment?.department_id,  
                                                  },
                                                });
                                              }}
                                              Icon={
                                                <AssignTask fill="var(--whiteColor)" />
                                              }
                                            />
                                          )
                                        )
                                      }
                                    </div>
                                  </div>

                                  <p>{task}</p>
                                  <p>
                                    Risk Level:{" "}
                                    <PriorityBadge
                                      priority={risk_level ? risk_level : "Low"}
                                    />
                                  </p>
                                </div>
                              )
                            )}
                        </LoadingHoc>
                      </div>
                    )}

                  {isMyAssessment && (
                    <div className={styles.uploadDocumentContainer}>
                      <div className={styles.taskHeadingBlock}>
                        <p>Documents</p>
                        {userAuthData?.role !== APP_ROLES.OBOEDIO_ADMIN &&
                          userAuthData?.role !== APP_ROLES.AUDITOR &&
                          assessment?.assessment_status !== ASSESSMENT_STATUS.COMPLETED && (
                            <Button
                              Icon={<UploadIcon fill="#ffffff" />}
                              label="Upload Document"
                              id="addDocLibrary"
                              onClick={() => setShowDocument(true)}
                              disabled={documentsLoadingStatus}
                            />
                          )}
                        {showDocument && (
                          <UploadFile
                            openState={showDocument}
                            heading="Upload Document"
                            onClickCancel={() => setShowDocument(false)}
                            setShowDocument={setShowDocument}
                            org_assign_assessment_id={
                              fetchedQuestions?.data[questionIndex][
                                "org_assign_assessment_id"
                              ]
                            }
                            uploadImage={uploadImageAssessment}
                            uploadImageLoading={uploadImageLoading}
                            selectDoc={selectDoc}
                            uploadSelectLoading={uploadSelectLoading}
                          />
                        )}
                      </div>
                      <LoadingHoc isLoading={documentsLoadingStatus}>
                        {documents && documents?.length > 0 ? (
                          documents.map((doc: any) => {
                            console.log("doc", doc);
                            return (
                              <div
                                className={styles.uploadedFile}
                                key={doc.assessment_document_id}
                              >
                                <div className={styles.pdfFile}>
                                  {doc.file_type === "application/pdf" ? (
                                    <PdfIcon
                                      fill="var(--redColor)"
                                      style={{ maxWidth: "20px" }}
                                    />
                                  )  :doc.file_type.startsWith("image/") ?
                                  (
                                    <ImageIcon
                                   
                                      style={{ maxWidth: "20px" }}
                                    />
                                  ) 
                                  
                                  :(
                                    <DocIcon
                                      fill="var(--redColor)"
                                      style={{ maxWidth: "20px" }}
                                    />
                                  )}

                                  {/* <PdfIcon
                            fill="var(--redColor)"
                            style={{ maxWidth: "20px" }}
                          /> */}

                                 <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                 <a
                                    href={`${AWS_DOCUMENT_BASE}${doc.file_signed_url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ flex: "1", wordBreak: "break-word", marginRight: "1rem" }}
                                  >
                                    {doc.file_original_name}
                                  </a>
                                 
                                 </div>
                                
                                </div>
                                <div className={styles.pdfDate}>
                                  Uploaded: {doc.created_at}
                                </div>
                                {userAuthData?.user_id ===
                                  doc?.uploaded_by_id && 
                                  assessment?.assessment_status !== ASSESSMENT_STATUS.COMPLETED && (
                                  <DeleteIcon
                                    fill="var(--blackColor)"
                                    style={{ maxHeight: "15px" }}
                                    className={styles.deleteIcon}
                                    onClick={() =>
                                      handleDeleteImage(
                                        doc.assessment_document_id
                                      )
                                    }
                                  />
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <p>No Document Found</p>
                        )}
                      </LoadingHoc>
                    </div>
                  )}

                  {isMyAssessment && (
                    <div className={styles.submitInputContainer}>
                      <div className={styles.submitInputHeader}>
                        <p>Comment</p>
                      </div>
                      {isMyAssessment && comments && comments?.length > 0 && (
                        <div className={styles.commentContainer}>
                          <LoadingHoc isLoading={commentsLoadingStatus}>
                            {comments.map((comment: ICommentObj) => (
                              <Comment key={comment.id} comment={comment} />
                            ))}
                          </LoadingHoc>
                        </div>
                      )}
                      { assessment?.assessment_status !== ASSESSMENT_STATUS.COMPLETED &&
                        <div className={styles.commentInput}>
                          <Textarea
                            cols={30}
                            rows={3}
                            name="comment_input"
                            // label="Add Comment"
                            placeholder="Write a comment"
                            isRequired={false}
                            width="100%"
                            padding="5px 10px"
                            maxHeight="250px"
                            onChange={(e: any) =>
                              setCommentText(e.target.value)
                            }
                            value={commentText}
                            disabled={
                              userAuthData?.role === APP_ROLES.AUDITOR ||
                              userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN
                                ? true
                                : false
                            }
                          />
                          {userAuthData?.role !== APP_ROLES.AUDITOR &&
                            userAuthData?.role !== APP_ROLES.OBOEDIO_ADMIN && (
                              <Button
                                id="answerquestion"
                                label=""
                                onClick={handleAddComment}
                                padding="8px"
                                Icon={
                                  <SendIcon
                                    fill="var(--whiteColor)"
                                    style={{ maxWidth: "14px" }}
                                  />
                                }
                              />
                            )}
                        </div>
                      }
                    </div>
                  )}

                  {isMyAssessment &&
                    userAuthData?.role !== APP_ROLES.OBOEDIO_ADMIN &&
                    userAuthData?.role !== APP_ROLES.AUDITOR && 
                    assessment?.assessment_status !== ASSESSMENT_STATUS.COMPLETED && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          padding: "20px 30px",
                          gap: 10,
                        }}
                      >
                       

                        <Button
                          id="submitNextBtn"
                          label="Save & Next"
                          background="var(--secondaryColor)"
                          color="var(--whiteColor)"
                          Icon={
                            <NextArrowIcon
                              fill="#ffffff"
                              className="svg_image_icon"
                            />
                          }
                          loading={submitAnswerLoading && submitType === "next"}
                          // disabled={
                          //   questionIndex ===
                          //     fetchedQuestions?.data?.length - 1 ||
                          //   nextEnable === "Disabled"
                          // }
                          disabled={
                            nextEnable === "Disabled"
                          }
                          onClick={() => {
                            const answer: Answer = {
                              question_type:
                                fetchedQuestions?.data?.[questionIndex][
                                  "question_type"
                                ],
                              answer_option_id: answerOptionId,
                              answer_subjective: answerText,
                              answer_objective:
                                fetchedQuestions?.data[questionIndex][
                                  "options"
                                ]?.find(
                                  (id: any) =>
                                    Number(answerOptionId) ===
                                    Number(id.assign_option_id)
                                )?.option_text || "",
                              answer_list: answerList,
                              multi_option_answer: answerCheckId,
                              // exceptions: {
                              //   isException,
                              //   exceptionValue,
                              //   exceptionText,
                              // },
                            };
                            onSubmitAnswerDynamic(
                              answer,
                              true,
                              "next",
                              currentFollowupAnswers,
                            );
                            // setNextIndex(answer);
                          }}
                        />

                        <Button
                          id="submitBtn"
                          label="Save"
                          background="var(--secondaryColor)"
                          color="var(--whiteColor)"
                          Icon={
                            <CopyIcon
                              fill="#ffffff"
                              className="svg_image_icon"
                            />
                          }
                          loading={submitAnswerLoading && submitType === "save"}
                          disabled={
                            (submitAnswerLoading && submitType === "save") ||
                            saveEnable === "Disabled"
                          }
                          onClick={() => {
                            const answer: Answer = {
                              question_type:
                                fetchedQuestions?.data?.[questionIndex][
                                  "question_type"
                                ],
                              answer_option_id: answerOptionId,
                              answer_subjective: answerText,
                              answer_objective:
                                fetchedQuestions?.data[questionIndex][
                                  "options"
                                ]?.find(
                                  (id: any) =>
                                    Number(answerOptionId) ===
                                    Number(id.assign_option_id)
                                )?.option_text || "",
                              answer_list: answerList,
                              multi_option_answer: answerCheckId,
                              // exceptions: {
                              //   isException,
                              //   exceptionValue,
                              //   exceptionText,
                              // },
                            };
                            onSubmitAnswerDynamic(
                              answer,
                              true,
                              "save",
                              currentFollowupAnswers
                            );
                            // setNextEnable("Enabled");
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </LoadingHoc>
          )}
        </div>
      </div>
      {showModal &&
        showModal.state &&
        showModal.modalName === QUESTION_SCREEN_MODAL_NAMES.ASSIGN && (
          <AssignQuestion
            type={showModal.type}
            openState={showModal.state}
            heading={
              showModal.type === "Task"
                ? "Assign The Task"
                : showModal.type === "Question"
                ? "Assign The Question"
                : showModal.type === "Task_Reassign"
                ? "Re-Assign Task"
                : showModal.type === "Question_Reassign"
                ? "Re-Assign Question"
                : "Re-Assign Assessment"
            }
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            data={showModal?.data}
            question_oaa_id={
              fetchedQuestions?.data[questionIndex]["org_assign_assessment_id"]
            }
            assigned_to_org_assign_assessment_id={
              fetchedQuestions?.data[questionIndex]["assigned_to_org_assign_assessment_id"]
            }
            modalName={showModal.modalName}
            onSuccess={onSuccessRemediation}
          />
        )}

      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.VIEW_ARTICLE && (
          <ViewArticle
            openState={showModal}
            heading="View Section"
            // type={showModal?.type}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            data={state && state?.selectedArticles}
          />
        )}

      {showDeleteModal &&
        showDeleteModal.state &&
        showDeleteModal.modalName ===
          ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT && (
          <DeleteDocument
            openState={showDeleteModal}
            heading="Delete Document"
            type={showDeleteModal?.type}
            data={showDeleteModal.data}
            onClickCancel={() => {
              setDeleteModal((prev) => ({ ...prev, state: false }));
            }}
            onSuccess={onUploadDeleteSuccess}
          />
        )}
      {taskDetailModal && taskDetailModal?.state && (
        <UserTaskDetail
        openState={taskDetailModal}
        heading="Task Details"
        onClickCancel={() => {
          setTaskDetailModal({ state: false, task: null });
          }}
          task={
            taskDetailModal &&
            taskDetailModal.task &&
            allTaskData &&
            allTaskData?.length > 0 &&
            allTaskData.filter(
              (task: any) =>
                task.question_task_id === taskDetailModal.task.task_id &&
                task?.assigned_to_user_id  == taskDetailModal.task.assigned_to_user_id &&
                task.question == fetchedQuestions?.data?.[questionIndex]?.["question"]
            )[0]
          }
          isReadOnly={
            allTaskData &&
            allTaskData?.length > 0 &&
            allTaskData?.filter(
              (task: any) =>
                task?.question_task_id === taskDetailModal?.task?.task_id &&
              task?.assigned_to_user_id == taskDetailModal?.task?.assigned_to_user_id 
            )[0]?.assigned_status !== ASSIGNED_STATUS.UNDER_REVIEW
          }
          // task={taskDetailModal.task}
          // isReadOnly={taskDetailModal.task}
          buttonPrimary={({
            org_assign_assessment_id,
            notes,
            handleAddComment,
            comment_thread_id,
          }: any) => {
            const currentQuestion = fetchedQuestions?.data[questionIndex];

            const yesOption = currentQuestion?.options?.find(
              (option: any) => option.option_text === "Yes"
            );

            console.log("Yes Option:", yesOption);

            return (
              <Button
                type="button"
                label="Mark as Complete"
                id="primary"
                fontSize="calc(var(--secondaryFont) + 2px)"
                onClick={() => {
                  handleMarkAsComplete({
                    org_assign_assessment_id,
                    comment_thread_id,
                  });
                  // onSubmitAnswer(`${yesOption?.assign_option_id}`, true);
                  handleAddComment();
                }}
                loading={updateTaskAssignedStatusLoading}
                disabled={updateTaskAssignedStatusLoading}
                Icon={
                  <SendIcon
                    fill="var(--whiteColor)"
                    style={{ maxWidth: "14px" }}
                  />
                }
              />
            );
          }}
          buttonSecondary={({
            org_assign_assessment_id,
            handleAddComment,
            isDisable,
            comment_thread_id,
          }: any) => {
            console.log({ isDisable });

            const currentTask = allTaskData?.filter(
              (task: any) =>
                task?.question_task_id === taskDetailModal?.task?.task_id
            )[0];

            const isButtonVisible =
              currentTask?.assigned_status !== ASSIGNED_STATUS.ASSIGNED &&
              currentTask?.assigned_status !== ASSIGNED_STATUS.IN_PROGRESS;

            return isButtonVisible ? (
              <Button
                label="Send Back for Rework"
                id="secondary"
                fontSize="calc(var(--secondaryFont) + 2px)"
                onClick={() => {
                  handleMarkAsAssigned({ org_assign_assessment_id, comment_thread_id, });
                  handleAddComment();
                }}
                // background="white"
                // border="1px solid var(--secondaryColor)"
                disabled={isDisable}
                Icon={
                  <CancelIcon
                    fill="var(--whiteColor)"
                    style={{ maxWidth: "14px" }}
                  />
                }
              />
            ) : null;
          }}
          isCommentRequired={true}
        />
      )}
    </>
  );
};

export default React.memo(QuestionScreen);
