import React, { useCallback, useMemo, useState } from "react";
import styles from "./style.module.scss";
import { MultiSelect, Button } from "../../../components";
import { generateYears, REQUEST_TYPE } from "../../../../utils/constants";
import { ReactComponent as DocumentIcon } from "../../../../assests/svg/document.svg";

import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import {
  IDuplicateAssessmentModal,
} from "../../../../interfaces/IQuestion";
import {
  APP_ROLES,
  RQ_KEYS,
} from "../../../../utils/constants";
import { Form, Formik } from "formik";
import {
  DuplicateAssessmentInitialValue,
  DuplicateAssessmentSchema,
} from "./validator";
import {
  _getQueryState,
  _useMutation,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import { useQueryClient } from "react-query";
import { Modal } from "@mui/material";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import Toast from "../../../components/Toast";
const DuplicateAssessment: React.FC<IDuplicateAssessmentModal> = ({
  onClickCancel,
  heading,
  data,
  // onSuccess,
  openState,
}) => {
  const queryClient = useQueryClient();
  console.log("data in duplicate assessment modal", data);

  const toastState: ITOAST_STATE | any = _getQueryState({
      key: RQ_KEYS.TOAST_STATE,
    });

  const onSuccess = async (response:any) => {
    console.log(">> response <<" ,response);
    if (response?.response?.data.status === false) {
      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: response?.response?.data?.message,
        toastType: "ERROR",
      });
    }
    queryClient.resetQueries({ queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID });
    queryClient.invalidateQueries({ queryKey: RQ_KEYS.ORG_ADMIN_BY_ID });
    queryClient.invalidateQueries({
      queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID_CATEGORIZED,
    });
    // onClickCancel();
    if (response?.response?.data.status === false) {
      setTimeout(() => {
        queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
        onClickCancel();
      }, 2000)
    } else {
      queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
      onClickCancel();
    }
    };

  const duplicateAssessment = _useMutation({ onSuccess });

  const duplicateAssessmentLoading = duplicateAssessment.isLoading;

    let organizationData: any = _getQueryState({
      key: RQ_KEYS.USER_ORGANIZATION,
    });

  
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`invitePopupContainer ${styles.duplicatePopupContainer}`}>
        <div className={styles.duplicatePopupContainerInner}>
          <div className={styles.duplicateAssessmentHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Formik
            initialValues={DuplicateAssessmentInitialValue}
            validationSchema={DuplicateAssessmentSchema}
            onSubmit={async (values) => {
              console.log("values++", values, "assessment Data:", data);
              await duplicateAssessment.mutate({
                url: API_ROUTE.DUPLICATE_ORG_ASSESSMENT,
                requestType: REQUEST_TYPE._PATCH,
                requestData: {
                  assign_assessment_id: data?.assessment_id ,
                  assessment_type_id: data?.assessment_type_id,
                  assigned_to_user_id: organizationData?.org_admin_id,
                  department_id: data?.department_id,
                  department_name: data?.department_name,
                  assessment_year: values.assessmentYear,
                  assessment_id: data?.original_assessment_id,
                  assessment_type: data?.assessment_type
                },
              }, {
                onSuccess: (response: any) => {
                  onSuccess(response);
                }
            });
            }}
          >
            {({ setFieldValue, handleChange, handleBlur }) => (
              <Form>
                <div className={styles.duplicateAssessmentBody}>
                  {toastState && toastState.message && (
                    <Toast toastState={toastState} />
                  )}
                  <div className={styles.duplicateAssessmentContent}>
                    <p>
                      <label>Assessment Name:</label> {data?.assessment_name}
                    </p>
                    <p>
                      <label>Note:</label> {`On clicking Duplicate, a new assessment will be created for the selected year, including all questions and answers from the original assessment.`}
                    </p>
                  </div>
                  <div className={styles.mainBlock}>
                    <MultiSelect
                      label="Target Assessment Year"
                      name="assessmentYear"
                      placeholder="Select Assessment Year"
                      isRequired={true}
                      options={generateYears(20, data?.assessment_year + 1)}
                      // values={
                      //   values?.assessmentYear
                      //     ? { value: values?.assessmentYear, label: values?.assessmentYear }
                      //     : null
                      // }
                      setFieldValue={setFieldValue}
                      isMulti={false}
                    />

                  </div>
                  <div className={styles.buttonPanel}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    <Button
                      type="submit"
                      label={
                        "Duplicate"
                      }
                      id="createDuplicateBtn"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      fontSize="var(--secondaryFont)"
                      Icon={<DocumentIcon style={{
                        fill: "var(--whiteColor)",
                        width: 18,
                        stroke: "#0097ce",
                      }} />}
                   disabled= {duplicateAssessmentLoading}
                   loading={duplicateAssessmentLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicateAssessment;
