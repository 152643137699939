import React, { useEffect } from "react";
import { ReactComponent as CancelIcon } from "../../../../assests/svg/close1.svg";
import { ReactComponent as PdfIcon } from "../../../../assests/svg/pdf.svg";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as UploadIcon } from "../../../../assests/svg/upload_icons.svg";
import { ReactComponent as DeleteIcon } from "../../../../assests/svg/delete_icon.svg";

import { Button, Textarea } from "../../../components";
import styles from "./style.module.scss";
import { IModal } from "../../../../interfaces/IModal";
import { IUserTaskDetail } from "../../../../interfaces/IQuestion";
import {
  _getQueryState,
  _useMutation,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import {
  ASSESSMENT_MODAL_NAMES,
  ASSESSMENT_STATUS,
  ASSIGNED_STATUS,
  AWS_DOCUMENT_BASE,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import Comment from "../../../components/Comment";
import FileInput from "../../../components/FileInput/input";
import DeleteDocument from "../../ModalPopups/AssessmentModals/DeleteDocument";
import LoadingHoc from "../../../components/LoadingHoc";
import { Formik } from "formik";
import { useQueryClient } from "react-query";
import UploadFile from "../../ModalPopups/UploadFile";
import { Modal } from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../../assests/svg/i_icon.svg";
import Tooltip from "../../ToolTip";
const UserTaskDetail: React.FC<IUserTaskDetail> = ({
  heading,
  onClickCancel,
  task,
  openState,
  buttonPrimary,
  buttonSecondary,
  buttonStartTask,
  isReadOnly,
  isCommentRequired,
}) => {
  console.log("TASK duedate", task);
  // const {
  //   due_date,
  //   assessment_name,
  //   chapter_name,
  //   task: taskName,
  //   org_assign_assessment_id,
  //   description,
  //   comment_thread_id,

  //   assigned_by,
  //   assigned_status,
  // } = task;
  const {
    // Default to null if undefined
    assessment_name = "", // Default to a placeholder string
    chapter_name = "", // Default to a placeholder string
    task: taskName = "", // Default to a placeholder string
    org_assign_assessment_id = null, // Default to null if undefined
    description = "", // Default to an empty string
    comment_thread_id = null, // Default to null if undefined
    assigned_by = "", // Default to a placeholder string
    assigned_status = "", // Default to 'Pending' if undefined
    assigned_to = "",
    assigned_at = "",
    question_oaa_id = null,
    assigned_to_user_id,
    assigned_by_user_id,
    question = "",
    assessment_status = "",
  } = task || {};

  console.log("Task in User Task Details Modal:",task);

  // const assigned_to = task?.assigned_to ? task?.assigned_to : "";
  // const assigned_at = task?.assigned_at ?  task?.assigned_at : ""
  const [commentText, setCommentText] = React.useState("");
  const [commentError, setCommentError] = React.useState("");
  // const onClickCancelButton = () => onClickCancelButton(false);

  const [showDocument, setShowDocument] = React.useState(false);

  const [showDeleteModal, setDeleteModal] = React.useState<IModal>({
    state: false,
    modalName: "",
    data: null,
    type: "DELETE",
  });

  const statusMapping: any = {
    In_Progress: "In Progress",
    Completed: "Completed",
    Assigned: "Assigned",
    Unassigned: "Unassigned",
    Under_Review: "Under Review",
  };

  const queryClient = useQueryClient();
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const documents: any = _getQueryState({ key: RQ_KEYS.TASK_DOCUMENTS });
  console.log("documents in usertaskdetail", documents);

  const comments: any = _getQueryState({ key: RQ_KEYS.TASK_COMMENTS });

  const { mutate: getDocuments } = _useMutation({
    queryKey: RQ_KEYS.TASK_DOCUMENTS,
  });

  const documentsLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.TASK_DOCUMENTS}_loading`,
  });

  const { mutate: getComments } = _useMutation({
    queryKey: RQ_KEYS.TASK_COMMENTS,
  });

  const commentsLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.TASK_COMMENTS}_loading`,
  });

  const { mutate: addComment } = _useMutation({
    onSuccess: () => {
      getComments({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_COMMENTS}/${comment_thread_id}`,
      });
    },
    queryKey: RQ_KEYS.ADD_COMMENT,
  });

  const { mutate: uploadImage } = _useMutation({
    onSuccess: () => {
      getDocuments({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_DOCUMENTS}/${org_assign_assessment_id}`,
      });
    },
    queryKey: RQ_KEYS.UPLOAD_DOCUMENT,
  });

  const uploadLoadingStatus = _getQueryState({
    key: `${RQ_KEYS.UPLOAD_DOCUMENT}_loading`,
  });

  const handleFileChange = async (file: File | null) => {
    //here we will get the selected file
    console.log("file in handle file change", file);
    let fd = new FormData();
    fd.append("image", file as any);
    uploadImage({
      url: `${API_ROUTE.UPLOAD_ASESSEMENT_IMAGE}/${org_assign_assessment_id}`,
      Headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
      requestType: REQUEST_TYPE._POST,
      requestData: fd,
    });
  };

  const handleDeleteImage = (document_id: any) => {
    setDeleteModal({
      state: true,
      modalName: ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT,
      type: "DELETE",
      data: { org_assign_assessment_id, document_id },
    });
  };

  const onUploadDeleteSuccess = () => {
    console.log("delete doc successfull");
    getDocuments({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_DOCUMENTS}/${org_assign_assessment_id}`,
    });
  };

  const handleAddComment = () => {
    if (commentText.length > 0) {
      addComment({
        url: API_ROUTE.ADD_COMMENT,
        requestType: REQUEST_TYPE._POST,
        requestData: {
          comment_thread_id,
          text: commentText,
        },
      });
    } else if (isCommentRequired && !isReadOnly) {
      // commentError
      // setCommentError("Add a comment before sending for rework!");
    }

    setCommentText("");
  };

  useEffect(() => {
    getDocuments({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_DOCUMENTS}/${org_assign_assessment_id}`,
    });
  }, [getDocuments, org_assign_assessment_id, task]);

  useEffect(() => {
    getComments({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_COMMENTS}/${comment_thread_id}`,
    });
  }, [getComments, comment_thread_id, task]);

  const { mutate: uploadImageAssessment, isLoading: uploadImageLoading } =
    _useMutation({
      onSuccess: () => {
        queryClient
          .refetchQueries({ queryKey: RQ_KEYS.DOCUMENT_LIB })
          .then((data: any) => {
            getDocuments({
              requestType: REQUEST_TYPE._GET,
              url: `${API_ROUTE.GET_DOCUMENTS}/${org_assign_assessment_id}`,
            });
            queryClient.refetchQueries({ queryKey: RQ_KEYS.DOCUMENTS }).then();
            setShowDocument(false);
          });

        queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
          message: "",
          toastType: "",
        });
      },
      queryKey: RQ_KEYS.UPLOAD_DOCUMENT,
    });

  const { mutate: selectDoc, isLoading: uploadSelectLoading } = _useMutation({
    onSuccess: () => {
      queryClient
        .refetchQueries({ queryKey: RQ_KEYS.DOCUMENT_LIB })
        .then((data: any) => {
          getDocuments({
            requestType: REQUEST_TYPE._GET,
            url: `${API_ROUTE.GET_DOCUMENTS}/${org_assign_assessment_id}`,
          });
          queryClient.refetchQueries({ queryKey: RQ_KEYS.DOCUMENTS }).then();
          setShowDocument(false);
        });

      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: "",
        toastType: "",
      });
    },
    queryKey: RQ_KEYS.SELECT_DOCUMENT,
  });

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Formik
        initialValues={{ comment_input: "" }}
        // validationSchema={{}}
        onSubmit={async (values) => {}}
      >
        <div className={styles.invitePopupContainer}>
          <div className={styles.invitePopupContainerInner}>
            <div className={styles.uploadFileHeader}>
              <h2>{heading}</h2>

              <img
                className="svg_image_icon"
                src={cancel_icon}
                alt="invite"
                onClick={onClickCancel}
              ></img>
            </div>  

            <div className={styles.uploadFileBody}>
            {/* <div className={styles.dueDateMessage}> */}
            {
              task?.due_date &&
              new Date(task?.due_date).getTime() < new Date().getTime() && (
                <span className={styles.dueDateMessage}>
                  Deadline missed for this Task!
                </span>
              )}
            {/* </div> */}
              <div
                className={`${styles.assignmentDetails} ${styles.withBorder}`}
              >
                <div className={styles.assignmentDetailCard}>
                  <h3>Assessment</h3>
                  <h2>{assessment_name}</h2>
                </div>
                <div className={styles.assignmentDetailCard}>
                  <h3>Chapter</h3>
                  <h2>{chapter_name}</h2>
                </div>
                <div className={styles.assignmentDetailCard}>
                  <h3>Assignment date</h3>
                  <h2>{assigned_at ? assigned_at : ""}</h2>
                </div>
                <div className={styles.assignmentDetailCard}>
                  <h3>Due date</h3>
                  <h2>{task?.due_date ? task?.due_date : "N/A"}</h2>
                </div>

                <div className={styles.assignmentDetailCard}>
                  <h3>Assigned By</h3>
                  <h2>{assigned_by}</h2>
                </div>
                {assigned_to && (
                  <div className={styles.assignmentDetailCard}>
                    <h3>Assigned To</h3>
                    <h2>{assigned_to}</h2>
                  </div>
                )}
                <div className={styles.assignmentDetailCard}>
                  <h3>Task Status</h3>
                  <h2>{statusMapping[task?.assigned_status]}</h2>
                </div>
                <div className={styles.assignmentDetailCard}>
                  <h3>Assessment Status</h3>
                  <h2 className={
                    task?.assessment_status === ASSESSMENT_STATUS.COMPLETED
                      ? styles.assessmentStatus
                      : ""
                  } >{task?.assessment_status}</h2>
                </div>
              </div>

              <div
                className={`${styles.assignmentDetailCard} ${styles.withBorder}`}
              >
                <h3>Question</h3>
                <h2>{question}</h2>
              </div>
              <div
                className={`${styles.assignmentDetailCard} ${styles.withBorder}`}
              >
                <h3>Task</h3>
                <h2>{taskName}</h2>
              </div>
              {task?.assigned_status !== "Assigned" && (
                <div
                  className={`${styles.assignmentDetailCard} ${styles.attachFileFlex}`}
                >
                  <h3>
                    Attach Documents<sup style={{ color: "red" }}>*</sup>{" "}
                  </h3>
                  {!isReadOnly && (
                    // <FileInput
                    //   Icon={<UploadIcon fill="#ffffff" />}
                    //   onFileChange={handleFileChange}
                    //   Label="Upload Document"
                    //   Id="userTaskDoc"
                    //   loading={uploadLoadingStatus as boolean}
                    // />
                    <Button
                      Icon={
                        <UploadIcon fill="#ffffff" width={15} height={15} />
                      }
                      label="Upload Document"
                      id="addDocLibrary"
                      onClick={() => setShowDocument(true)}
                      disabled={documentsLoadingStatus}
                    />
                  )}
                  <UploadFile
                    openState={showDocument}
                    heading="Upload Document"
                    onClickCancel={() => setShowDocument(false)}
                    setShowDocument={setShowDocument}
                    org_assign_assessment_id={org_assign_assessment_id}
                    uploadImage={uploadImageAssessment}
                    uploadImageLoading={uploadImageLoading}
                    selectDoc={selectDoc}
                    uploadSelectLoading={uploadSelectLoading}
                  />
                </div>
              )}
              {documents?.length > 0 &&
                documents.map((doc: any) => {
                  console.log("doccc", doc);
                  return (
                    <div
                      className={`${styles.assignmentDetailCard} ${styles.withBorder}`}
                    >
                      <LoadingHoc isLoading={documentsLoadingStatus}>
                        <>
                          <h2
                            className={styles.uploadedDocAssign}
                            key={doc.assessment_document_id}
                          >
                            <PdfIcon fill="var(--redColor)" />
                            <a
                              href={`${AWS_DOCUMENT_BASE}${doc.file_signed_url}`}
                              target="_blank"
                              rel="noreferrer"
                              className={styles.uploadedFileName}
                            >
                              {doc.file_original_name}
                            </a>
                            <span>{doc.created_at}</span>
                            {!isReadOnly &&
                              userAuthData?.user_id === doc?.uploaded_by_id && (
                                <DeleteIcon
                                  fill="var(--blackColor)"
                                  style={{
                                    maxHeight: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDeleteImage(
                                      doc.assessment_document_id
                                    )
                                  }
                                />
                              )}
                          </h2>
                        </>
                      </LoadingHoc>

                      {/* {documentsLoadingStatus ? <h5>Loading...</h5> : null} */}
                    </div>
                  );
                })}

              {!isReadOnly && (
                
                <div className={styles.commentSectionTaskDetail}>
                  <label style={{ display: "flex", alignItems: "center" }}>
                    Comment
                    <sup style={{ color: "red" }}>*</sup>
                   <Tooltip text="Comment length should not be exceed 250 characters.">
                      <InfoIcon style={{ height: "12px", marginLeft:"2px", marginBottom:"4px" }} />
                    </Tooltip>
                  </label>
                  <Textarea
                    maxLength={"250"}
                    cols={30}
                    rows={3}
                    name="comment_input"
                    // label={`Comment ${(
                    //   <Tooltip text={"Comments are limited to 250 characters."}>
                    //     <InfoIcon />
                    //   </Tooltip>
                    // )}`}
                    placeholder="Write a comment..."
                    isRequired={true}
                    width="100%"
                    padding="5px 0px"
                    maxHeight="250px"
                    onChange={(e: any) => setCommentText(e.target.value)}
                  ></Textarea>

                  {commentError ? (
                    <p style={{ color: "red" }}>{commentError}</p>
                  ) : isCommentRequired ? (
                    <p
                      style={{
                        fontSize: 12,
                        color: "red",
                        paddingTop: "10px",
                      }}
                    >
                      Note: Please add a comment before sending for rework.
                    </p>
                  ) : assigned_by_user_id &&
                    assigned_to_user_id &&
                    assigned_by_user_id === assigned_to_user_id ? (
                    <p style={{ fontSize: 12, color: "red" }}>
                      Note: Please add a comment before Mark as complete.
                    </p>
                  ) : (
                    <p style={{ fontSize: 12, color: "red" }}>
                      Note: Please add a comment before sending for review.
                    </p>
                  )}
                </div>
              )}

              {/* Show all Comments */}
              {comments && comments.length > 0 && <h5>Comments</h5>}

              <LoadingHoc isLoading={commentsLoadingStatus}>
                {comments &&
                  comments.length > 0 &&
                  comments.map((comment: any) => (
                    <Comment key={comment.id} comment={comment} />
                  ))}
              </LoadingHoc>

              {task?.assigned_status === ASSIGNED_STATUS.ASSIGNED &&
              task?.assessment_status !== ASSESSMENT_STATUS.COMPLETED && (
                <div className={styles.assignmentDetailInput}>
                  {buttonSecondary &&
                    buttonSecondary({
                      org_assign_assessment_id,
                      handleAddComment,
                      comment_thread_id: task?.comment_thread_id,
                      notes: "assignee_notes",
                    })}
                  {buttonStartTask &&
                    buttonStartTask({
                      org_assign_assessment_id,
                      comment_thread_id: task?.comment_thread_id,
                    })}
                </div>
              )}

              {!isReadOnly && (
                <div className={styles.assignmentDetailInput}>
                  {buttonSecondary &&
                    buttonSecondary({
                      org_assign_assessment_id,
                      handleAddComment,
                      isDisable:
                        isCommentRequired && !isReadOnly && commentText == ""
                          ? true
                          : false,
                      comment_thread_id: task?.comment_thread_id,
                    })}
                  {buttonPrimary &&
                    buttonPrimary({
                      notes: "assignee_notes",
                      org_assign_assessment_id,
                      handleAddComment,
                      question_oaa_id,
                      assigned_to_user_id,
                      assigned_by_user_id,
                      comment_thread_id: task?.comment_thread_id,
                      documents,
                      comments,
                      isDisable: commentText == "" ? true : false,
                    })}
                </div>
              )}
            </div>
          </div>

          {showDeleteModal &&
            showDeleteModal.state &&
            showDeleteModal.modalName ===
              ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT && (
              <DeleteDocument
                openState={showDeleteModal}
                heading="Delete Document"
                type={showDeleteModal?.type}
                data={showDeleteModal.data}
                onClickCancel={() => {
                  setDeleteModal((prev) => ({ ...prev, state: false }));
                }}
                onSuccess={onUploadDeleteSuccess}
              />
            )}
        </div>
      </Formik>
    </Modal>
  );
};

export default UserTaskDetail;
