import Excel from 'exceljs';
import { AWS_DOCUMENT_BASE } from '../../../../utils/constants';

interface AssessmentInfo {
  assessment_name: string;
  assign_assessment_id: string;
  assessment_year: any;
}

interface ArticleData {
  article_name: string;
  article_number: any;
  article_question_type: "TABLE_TYPE_ADD_ROW" | "QnA";
  answer: any[];
}

interface AssessmentExportData {
  assessment_info: AssessmentInfo;
  article_data: ArticleData[];
}

export const exportTableAssessmentComparisonToExcel = async (
  first_assessment_data: AssessmentExportData, 
  second_assessment_data: AssessmentExportData,
  assessment_name: any
) => {
    const firstAssessmentyear: any = first_assessment_data.assessment_info.assessment_year;
    const secondAssessmentyear: any = second_assessment_data.assessment_info.assessment_year;
  const workbook = new Excel.Workbook();

  const getUniqueWorksheetName = (workbook: Excel.Workbook, baseName: string): string => {
    let uniqueName = `${baseName}`;
    // let counter = 1;
    
    while (workbook.getWorksheet(uniqueName)) {
      uniqueName = `${baseName}`;
    //   counter++;
    }
    
    return uniqueName;
  };

  const formatComments = (comments: {comment_text: string, created_at: string}[]) => {
    if (!comments || comments.length === 0) return '';
    
    const sortedComments = comments.sort((a, b) => 
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );

    return sortedComments.map((comment, index) => 
      `${index + 1}. ${comment.comment_text}`
    ).join('\n');
  };

  const formatDocuments = (docs: {file_signed_url: string, file_name: string}[]) => {
    if (!docs || docs.length === 0) return '';
    
    return docs.map((doc, index) => {
      const fullUrl = `${AWS_DOCUMENT_BASE}${doc.file_signed_url}`;
      return `${index + 1}. ${fullUrl}`;
    }).join('\n');
  };

  const createAssessmentWorksheet = (
    workbook: Excel.Workbook, 
    assessmentData: AssessmentExportData,
    isFirstAssessment: boolean = true
  ) => {
    const worksheetName = getUniqueWorksheetName(
      workbook, 
      assessmentData.assessment_info.assessment_year
    );

    const worksheet = workbook.addWorksheet(worksheetName);

    worksheet.columns = [
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    const headerStyle = {
      font: { bold: true },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F0F0F0' }
      }
    };

    const sortedArticles = [...assessmentData.article_data].sort((a, b) => 
      (a.article_number || 0) - (b.article_number || 0)
    );

    sortedArticles.forEach((article, articleIndex) => {
      const sectionArticleName = `Section ${article.article_number}: ${article.article_name}`;
      const articleNameRow = worksheet.addRow([sectionArticleName]);
      articleNameRow.font = { bold: true, size: 12 };
      articleNameRow.height = 10;

      worksheet.mergeCells(`A${articleNameRow.number}:E${articleNameRow.number}`);

      const articleNameCell = worksheet.getCell(`A${articleNameRow.number}`);
      articleNameCell.alignment = { 
        vertical: 'middle', 
        horizontal: 'center',
        wrapText: true 
      };

       if (!article.answer || article.answer.length === 0) {
        worksheet.addRow([]);
      }else
      {
      if (article.article_question_type === "TABLE_TYPE_ADD_ROW") {
        if (article.answer.length > 0) {
          const filteredHeaders = Object.keys(article.answer[0])
            .filter(key => key.toLowerCase() !== 'id' && key.toLowerCase() !== 'process_name');

          const headerRow = worksheet.addRow(filteredHeaders);
          headerRow.eachCell((cell:any) => {
            cell.font = headerStyle.font;
            cell.fill = headerStyle.fill;
          });

          article.answer.forEach(rowData => {
            worksheet.addRow(filteredHeaders.map(header => rowData[header]));
          });

          worksheet.addRow([]);
          worksheet.addRow([]);
        }
      } else if (article.article_question_type === "QnA") {
        const sortedQnAEntries = article.answer.sort((a, b) => 
          parseInt(a.question_number) - parseInt(b.question_number)
        );

        const headers = [
          'Question Number', 
          'Question', 
          'Answer',
          'Comments', 
          'Documents'
        ];

        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell:any) => {
          cell.font = headerStyle.font;
          cell.fill = headerStyle.fill;
        });

        sortedQnAEntries.forEach((qnaEntry) => {
          const answerKey = isFirstAssessment ? 'first_assessment_answer' : 'second_assessment_answer';
          const commentsKey = isFirstAssessment ? 'first_assessment_comments' : 'second_assessment_comments';
          const docsKey = isFirstAssessment ? 'first_assessment_doc' : 'second_assessment_doc';

          worksheet.addRow([
            qnaEntry.question_number,
            qnaEntry.question,
            qnaEntry[answerKey],
            formatComments(qnaEntry[commentsKey]),
            formatDocuments(qnaEntry[docsKey])
          ]);
        });

        worksheet.addRow([]);
        worksheet.addRow([]);
      }
    }
    });

    worksheet.eachRow((row) => {
      row.height = 25;
      row.alignment = { 
        vertical: 'middle', 
        horizontal: 'left', 
        wrapText: true 
      };
    });

    return worksheet;
  };

  createAssessmentWorksheet(workbook, first_assessment_data, true);
  createAssessmentWorksheet(workbook, second_assessment_data, false);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { 
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
  });
  
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${assessment_name} ${firstAssessmentyear}-${secondAssessmentyear}.xlsx`;
  link.click();
};