import React from "react";
import styles from "./style.module.scss";
import { InputField } from "../../../../components";
import { IInviteUser } from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import { ReactComponent as CancelIcon } from "../../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as EditIcon } from "../../../../../assests/svg/edit_icon.svg";

import { AddDepartmentSchema, AddDepartmentValue } from "./validator";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import {
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import { useQueryClient } from "react-query";
import { API_ROUTE } from "../../../../../utils/api";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { Modal } from "@mui/material";

const AddDepartmentPopup: React.FC<IInviteUser> = ({
  onClickCancel,
  heading,
  openState,
  data,
  onSuccess,
  type

}) => {
  console.log("<< Data in Modal >>", data);
  const queryClient = useQueryClient();

  const departments = useApiQuery({
    queryKey: RQ_KEYS.DEPARTMENT_LIST,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_DEPARTMENT_LIST}`,
  });

  console.log("<< departments >>", departments);

  const onApiSuccess = async () => {
    console.log("<< On Success API calling >>");
      onSuccess();
      onClickCancel();
    // queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
    // queryClient.refetchQueries({ queryKey: RQ_KEYS.DEPARTMENT_LIST });
    onClickCancel();
  };
  const addAssessment = _useMutation({ onApiSuccess });
  const editDepartment = _useMutation({ onApiSuccess });
  const isAddAssessmentLoading = addAssessment.isLoading;
  const isEditDepartmentLoading = editDepartment.isLoading;

  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  const initialValues = type === "UPDATE" 
  ? { ...AddDepartmentValue, department_name: data?.department_name || "" }
  : AddDepartmentValue;

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.addDepartmentContainer}>
        <div className={styles.addDepartmentContainerInner}>
          <div className={styles.invitePopupRow1}>
            <p>{heading}</p>
            <CancelIcon className="svg_image_icon" onClick={onClickCancel} />
          </div>
          <Toast toastState={toastState} />
          <div >
            <Formik
              initialValues={initialValues}
              validationSchema={AddDepartmentSchema}
              enableReinitialize={true}
              // onSubmit={async (values) => {
              // }}
              onSubmit={async (values) => {
                console.log("<< On Submit values of Add Department>>", values);

                if (type === "UPDATE") {
                  await editDepartment.mutate({
                    url: API_ROUTE.EDIT_DEPARTMENT_LIST,
                    requestType: REQUEST_TYPE._PATCH,
                    requestData: {
                      department_name: values.department_name,
                      department_id: data?.department_id,
                      action: "EDIT"
                    }
                  },
                  {
                    onSuccess: () => {
                      onApiSuccess();
                    }
                  }
                );
                } else {
                  await addAssessment.mutate({
                    url: API_ROUTE.UPDATE_DEPARTMENT_LIST,
                    requestType: REQUEST_TYPE._POST,
                    requestData: {
                      department_name: values.department_name
                    },
                  },
                  {
                    onSuccess: () => {
                      onApiSuccess();
                    }
                  }
                );
                }

              }}
            >
              {({ handleChange, isValid, handleBlur, values }) => {
                console.log("values", values);
                return (
                  <div className={styles.invitePopupRow2}>
                    <Form className={styles.invitePopupForm}>
                      <div className={styles.userLogin}>
                        <div className={styles.userLoginInner}>
                          <InputField
                            name="department_name"
                            alignItems="left"
                            type="text"
                            label="Department Name"
                            placeholder="Enter Department Name"
                            isRequired={true}
                            value={values?.department_name}
                            display="flex"
                            fontWeight="600"
                            fontSize="12px"
                            gap="5px"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className={styles.inviteUserBtn}>
                        <Button
                          label="Cancel"
                          id="cancelbtn"
                          fontWeight="bold"
                          type="button"
                          // maxWidth="100px"
                          fontSize="var(--secondaryFont)"
                          background="var(--whiteColor)"
                          border="1px solid var(--secondaryColor)"
                          color="var(--secondaryColor)"
                          Icon={
                            <CancelBtn
                              fill="#0097ce"
                              style={{ maxWidth: "12px" }}
                            />
                          }
                          onClick={onClickCancel}
                        />
                        <Button
                          label={
                            (type === "CREATE") ? "Add Department" : "Edit Department"
                          }
                          id="add department btn"
                          fontWeight="bold"
                          color="var(--whiteColor)"
                          maxWidth="180px"
                          fontSize="var(--secondaryFont)"
                          type="submit"
                          Icon={
                            (
                              <EditIcon fill="var(--whiteColor)" className={`${styles.EditIcon} svg_image_icon`} />
                            )
                          }
                          loading={loadingState || isAddAssessmentLoading || isEditDepartmentLoading}
                          disabled={!isValid || loadingState || isAddAssessmentLoading || isEditDepartmentLoading}
                        />
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>

        </div>
      </div>
    </Modal>
  );
};

export default AddDepartmentPopup;