import { useEffect, useState } from "react";
import styles from "./table.module.scss";
import { v4 as uuidv4 } from 'uuid';

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { columns, createColumns } from "./columns";
import { FooterCell } from "./FooterCell";
import useStudents from "./useStudents";
import { Button } from "../../components";
import { ReactComponent as SaveIcon } from "../../../assests/svg/save_close.svg";
import { ReactComponent as CopyIcon } from "../../../assests/svg/copy_data.svg";
import { ReactComponent as NextArrowIcon } from "../../../assests/svg/next_arrow_fill.svg";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  ASSESSMENT_MODAL_NAMES,
  ASSESSMENT_STATUS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../utils/constants";
import { API_ROUTE } from "../../../utils/api";
import LoadingHoc from "../../components/LoadingHoc";
import { useQueryClient } from "react-query";
import DeleteProcess from "../ModalPopups/AssessmentModals/DeleteProcess";
import { useNavigate } from "react-router-dom";

export const EditableProcessesTable = ({ questions, setArticleIndex, assessmentData }: any) => {
  const assessmentStatus:any = assessmentData?.assessment_status ? assessmentData?.assessment_status : "In Progress";
  const assessment_id:any = assessmentData?.assessment_id;
  const [nextEnable, setNextEnable] = useState("Disabled");
  const [saveEnable, setSaveEnable] = useState("Disabled");
  const queryClient = useQueryClient();
  const {
    //   data: originalData,
    //   isValidating,
    addRow,
    updateRow,
    deleteRow,
  }: any = useStudents();

  const [data, setData] = useState<any[]>([]);
  const [editedRows, setEditedRows] = useState({});
  const [validRows, setValidRows] = useState({});
  const [showDeleteModal, setDeleteModal] = useState<any>({
    state: false,
    modalName: "",
    data: null,
    type: "DELETE",
  });

  const [submitType, setSubmitType] = useState("");

  const isEditingRows = Object.values(editedRows).some((val: any) => val);

  console.log("valid Rows", validRows);

  const navigate = useNavigate();
  console.log("data in editable table", data);
  const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const orgData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  const { data: processesData, isLoading: processesIsLoading }: any =
    useApiQuery({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_PROCESSES}/${orgData?.org_id}/${assessment_id}`,
      queryKey: RQ_KEYS.ALL_PROCESSES,
    });

  console.log("processess data", processesData, processesIsLoading);

  const isButtonDisabled =
    processesIsLoading ||
    data?.length <= 0 ||
    data?.[data.length - 1]?.["business function"]?.length <= 0 ||
    data?.[data.length - 1]?.["process name"]?.length <= 0;

  console.log("isButton disabled", isButtonDisabled);

  const onDeleteSuccess = () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_PROCESSES }).then();
  };

  useEffect(() => {
    if (processesData?.length > 0) {
      const newData = processesData
        .toSorted((a: any, b: any) => a.row_no - b.row_no)
        .map((tData: any) => ({
          process_id: tData.process_id,
          "business function": tData.process_name,
          "process name": tData.process_name,
          "process description": tData.process_description,
          "number of employees": tData.no_of_employees,
        }));
      setData(newData);
    }else{
      setData([])
    }
  }, [processesData]);

  const updateData = (
    rowIndex: number,
    columnId: string,
    value: string,
    isValid: boolean
  ) => {
    // Update the main `data` array with the new value
    setData((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,  // Update the cell value
          };
        }
        return row;
      })
    );
  
    // Update `validRows` based on the new value
    setValidRows((oldValidRows) => {
      const updatedValidRows:any = { ...oldValidRows };
  
      if (value.trim() === "") {
        // If the value is empty, remove the columnId from validRows for this row
        if (updatedValidRows[rowIndex]) {
          delete updatedValidRows[rowIndex][columnId];
        }
  
        // If there are no valid columns left for this row, remove the row from validRows
        if (Object.keys(updatedValidRows[rowIndex] || {}).length === 0) {
          delete updatedValidRows[rowIndex];
        }
      } else {
        // If the value is not empty, add/update the validity of the column
        updatedValidRows[rowIndex] = {
          ...updatedValidRows[rowIndex],
          [columnId]: isValid,
        };
      }
  
      return updatedValidRows;
    });
  };

  const table = useReactTable({
    data,
    columns: createColumns(questions, assessmentStatus),
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
    meta: {
      editedRows,
      setEditedRows,
      validRows,
      setValidRows,
      // updateData,
      revertData: (rowIndex: number) => {
        setData((old) => old.map((row: any, index: number) => row));
      },
      updateRow: (rowIndex: number) => {
        updateRow(data[rowIndex].id, data[rowIndex]);
        setData((old: any) =>
          old.map((r: any, index: number) => {
            if (index === rowIndex) {
              return data[rowIndex];
            } else {
              return r;
            }
          })
        );
      },
      // updateData: (
      //   rowIndex: number,
      //   columnId: string,
      //   value: string,
      //   isValid: boolean
      // ) => {
      //   setData((old) =>
      //     old.map((row, index) => {
      //       if (index === rowIndex) {
      //         return {
      //           ...old[rowIndex],
      //           [columnId]: value,
      //         };
      //       }
      //       return row;
      //     })
      //   );
      //   setValidRows((old: any) => ({
      //     ...old,
      //     [rowIndex]: { ...old[rowIndex], [columnId]: isValid },
      //   }));
      // },
      updateData: (
        rowIndex: number,
        columnId: string,
        value: string,
        isValid: boolean
      ) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      
        setValidRows((old: any) => {
          const updatedRow = { ...old[rowIndex] };
      
          if (value?.trim().length > 0) {
            updatedRow[columnId] = isValid;
          } else {
            delete updatedRow[columnId];
          }
      
          // Update the row in validRows or remove the row entirely if it has no valid keys left
          return Object.keys(updatedRow).length > 0
            ? { ...old, [rowIndex]: updatedRow }
            : Object.fromEntries(Object.entries(old)?.filter(([key]) => key !== String(rowIndex)));
        });
      },
      
      addRow: () => {
        console.log("in add row", data);
        if (
          data?.[data?.length - 1]?.["business function"]?.length <= 0 ||
          data?.[data?.length - 1]?.["process name"]?.length <= 0
        ) {
          // TODO: show a message or something
          return;
        }
        // const id = Math.floor(Math.random() * 10000);
        const id = uuidv4();
        const newRow: any = {
          id,
        };
        addRow(newRow);
        setData([...data, newRow]);
      },
      removeRow: (rowIndex: number) => {
        if (assessmentStatus === ASSESSMENT_STATUS.COMPLETED) {
          return; // Don't allow deleteing rows if assessment is submitted
        }
        setDeleteModal({
          state: true,
          modalName: ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT,
          type: "DELETE",
          data: {
            id: data[rowIndex].id,
            process_id: data[rowIndex].process_id,
            deleteRow,
            tableData: data,
            setData,
            rowIndex,
          },
        });
        // deleteRow(data[rowIndex].id);
        // setData((old: any) =>
        //   old.filter((r: any, index: number) => index !== rowIndex)
        // );
      },
      // removeSelectedRows: (selectedRows: number[]) => {
      //   selectedRows.forEach((rowIndex) => {
      //     deleteRow(data[rowIndex].id);
      //   });
      // },
    },
  });

  const handleButtonState = () => {
    const isListProcess = questions.every(
      ({ question_type }: any) => question_type === "LIST_PROCESS"
    );

  //   if (!Array.isArray(currentFollowupAnswers)) {
  //     console.warn("Expected an array for currentFollowupAnswers, received:", currentFollowupAnswers);
  //     setNextEnable("Disabled");
  //     setSaveEnable("Disabled");
  //     return;
  // }

    if(isListProcess){
      if(Object.keys(validRows)?.length > 0 || processesData?.length > 0){
        setSaveEnable("Enabled");
        setNextEnable("Enabled");
      }else{
        setSaveEnable("Disabled");
        setNextEnable("Disabled");
      }
    }

    // if(currentQuestion && currentQuestion?.question_type === "List"){

    // }else if(currentQuestion && currentQuestion?.question_type === "TABLE_TYPE"){

    // }else if(currentQuestion && currentQuestion?.question_type === "LIST_PROCESS"){

    // }
  }

  useEffect(() =>{
    handleButtonState();
  }, [validRows,processesData]);

  const { mutate: postTableData, isLoading: postTableLoading } = _useMutation({
    onSuccess: (data: any) => {
      setSubmitType("");
      console.log("data in post table Data success", data);
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_PROCESSES }).then();
      queryClient.refetchQueries({ queryKey: RQ_KEYS.CHAPTER_BY_IDS }).then();
      queryClient.refetchQueries({ queryKey: RQ_KEYS.QUESTIONS }).then();
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS }).then();

      // navigate(0);
      if (data?.data === "next") {
        navigate(
          `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
            questions?.[0]?.assessment_id
          )} `
        );
        setArticleIndex((prev: any) => {
          // localStorage.setItem("articleIndex", prev + 1);
          return prev + 1;
        });
      }
    },
    queryKey: RQ_KEYS.POST_TABLE_DATA,
  });

  // useEffect(() => {
  //   setArticleIndex(localStorage.getItem("articleIndex") || 0);
  // }, [setArticleIndex]);

  const handleSubmitTable = (type: string) => {
    console.log("handle submit table", data, userData, orgData, questions);

    setSubmitType(type);

    const dataToSend = data
      .map((tData: any, idx: number) => ({
        process_id: tData["process_id"] || null,
        row_no: idx + 1,
        process_name: tData["process name"]
          ? tData["process name"]
          : tData["business function"],
        process_description: tData["process description"] ? tData["process description"] : '-',
        no_of_employees: tData["number of employees"] ? tData["number of employees"] : '-',
        org_id: orgData?.org_id,
        org_user_id: userData?.user_id,
        assign_article_id: questions[0]?.article_id,
        assessment_id: assessment_id,
      }))
      .filter((tData: any) => tData?.process_name?.length > 0);

    console.log("data to send", dataToSend);

    const isListProcess = questions.every(
      ({ question_type }: any) => question_type === "LIST_PROCESS"
    );

    postTableData({
      url: API_ROUTE.POST_TABLE_DATA,
      requestType: REQUEST_TYPE._POST,
      requestData: { tableData: dataToSend, isListProcess, type, assessment_id: assessment_id },
    });
  };

  const meta: any = table?.options?.meta;

  const handleAddRow = () => {
    meta?.addRow();

    setEditedRows((prev) => ({ ...prev, [data.length]: true }));
  };

  return (
    <LoadingHoc isLoading={processesIsLoading}>
      <article className={styles.tableContainer}>
        <div className={styles.tableQuestionContainer}>
          <div>
            This section includes questions {questions[0]?.question_number} -{" "}
            {questions[questions.length - 1]?.question_number}.
          </div>
          <table>
            <tfoot>
              <tr>
                <th colSpan={table.getCenterLeafColumns().length} align="right">
                  {(userData?.role !==APP_ROLES.AUDITOR && 
                    userData?.role !==APP_ROLES.OBOEDIO_ADMIN && 
                    assessmentStatus !== ASSESSMENT_STATUS.COMPLETED) && 
                    (<FooterCell table={table} handleAddRow={handleAddRow} />)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <table className={styles.table}>
          <thead className={styles.tableHeading}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.tableBody}>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                  // <td key={cell.id}>
                  //   {cell.column.columnDef.cell ? (
                  //     <input
                  //       type="text"
                  //       value={cell.getValue() !== undefined ? String(cell.getValue()) : ''}
                  //       onChange={(e) => {
                  //         const value = e.target.value;
                  //         const isValid = value.length > 0 ? true : false;  // Define your own validation function if needed

                  //         updateData(row.index, cell.column.id, value, isValid);
                  //       }}
                  //     />
                  //   ) : (
                  //     flexRender(cell.column.columnDef.cell, cell.getContext())
                  //   )}          
                  // </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {data?.length <= 0 && (userData?.role !== APP_ROLES.AUDITOR && userData?.role !== APP_ROLES.OBOEDIO_ADMIN)&& (
          <p
            style={{
              paddingTop: 24,
              textAlign: "center",
            }}
          >
            Click 'Add Row' to add data.
          </p>
        )}
      </article>

     {(userData?.role!==APP_ROLES.AUDITOR && 
     userData?.role!==APP_ROLES.OBOEDIO_ADMIN && 
     assessmentStatus !== ASSESSMENT_STATUS.COMPLETED ) &&(
       <div
       style={{
         display: "flex",
         flexDirection: "row-reverse",
         padding: "10px 0px",
         gap: 10,
        //  position: "fixed", // Makes the button fixed
        //  right: "10px", // Adjust position as needed
        //  bottom: "10px", // Adjust position as needed
         zIndex: 1000, // Ensures it stays on top of other elements
       }}
     >
       {(
         <Button
           id="saveNextProcessBtn"
           label="Save & Next"
           background="var(--secondaryColor)"
           color="var(--whiteColor)"
           disabled={
             ((isButtonDisabled || postTableLoading) && submitType === "next") || nextEnable === "Disabled"
           }
           loading={
             (processesIsLoading || postTableLoading) && submitType === "next"
           }
           Icon={<NextArrowIcon fill="#ffffff" className="svg_image_icon" />}
           onClick={() => handleSubmitTable("next")}
         />
       )}
       {(
         <Button
           id="saveProcessBtn"
           label="Save"
           background="var(--secondaryColor)"
           color="var(--whiteColor)"
           disabled={
             ((isButtonDisabled || postTableLoading) && submitType === "save") || saveEnable === "Disabled"
           }
           loading={
             (processesIsLoading || postTableLoading) && submitType === "save"
           }
           Icon={<CopyIcon fill="#ffffff" className="svg_image_icon" />}
           onClick={() => handleSubmitTable("save")}
         />
       )}
     </div>
     )}

      {showDeleteModal &&
        showDeleteModal.state &&
        showDeleteModal.modalName ===
          ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT && (
          <DeleteProcess
            openState={showDeleteModal}
            heading="Delete Process"
            type={showDeleteModal?.type}
            data={showDeleteModal.data}
            onClickCancel={() => {
              setDeleteModal((prev: any) => ({ ...prev, state: false }));
            }}
            onSuccess={onDeleteSuccess}
          />
        )}
    </LoadingHoc>
  );
};
