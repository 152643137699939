import * as Yup from "yup";
const InviteVendorSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("This field is required")
    .matches(
      /^[a-zA-Z]*$/,
      "Please enter a valid name without numbers or special characters."
    ),
  last_name: Yup.string()
    .matches(
      /^[a-zA-Z]*$/,
      "Please enter a valid name without numbers or special characters."
    ),
  role: Yup.string(),
  branch_name: Yup.string().required("This field is required"),
  email: Yup.string()
    // .email("Please enter a valid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/,
      "Please enter a valid email"
    )
    .required("This field is required"),
    // department: Yup.number().required("This field is required"),
  // vendor_org_name: Yup.string().required("This field is required"),
});

const InviteVendorInitialValue = {
  first_name: "",
  last_name: "",
  email: "",
  role: "VENDOR_USER",
  vendor_org_name: "",
  // department: 0,
  // branch_name: "",
};
export { InviteVendorSchema, InviteVendorInitialValue };
