import React, { useState } from "react";
import { useQueryClient } from "react-query";
import styles from "./styles.module.scss";
import { Button, Table } from "../../../../common/components";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  REQUEST_TYPE,
  RQ_KEYS,
  ORGANIZATION_MODAL_NAMES,
  LIMIT
} from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../../assests/svg/backArrow.svg";
import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assests/svg/delete_icon.svg";
import { Breadcrumb } from "../../../../common/Parts";
import { IModal } from "../../../../interfaces/IModal";
import AddDepartmentPopup from "../../../../common/Parts/ModalPopups/OrganizationModals/AddDepartmentPopup";
import { Spinner } from "react-bootstrap";
import DeleteDepartment from "../../../../common/Parts/ModalPopups/OrganizationModals/DeleteDepartmentPopup";
import { Pagination } from "../../../../common/components/Pagination";

const ManageDepartmentPage = () => {  
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [dataLimit, setDataLimit] = useState({ limit: LIMIT, offset: 0 });
  
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  
  const { mutate } = _useMutation({ queryKey: RQ_KEYS.DRAFT_ASSESSMENT });

  const [showModal, setModal] = React.useState<IModal>({
    state: false,
    modalName: "",
  });

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const departments = useApiQuery({
    queryKey: RQ_KEYS.DEPARTMENT_LIST,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_DEPARTMENT_LIST}?limit=${LIMIT}&offset=0`,
    onSuccess: (data:any) => {
      setTableData(data?.data?.data);
      setTotalCount(data?.data?.totalCount);
    }
  });

  const onPaginzationChange = ({ offset }: any) => {
    setDataLimit(prev => ({
      ...prev,
      offset,
    }));
    
    setIsLoading(true);
    
    mutate(
      {
        queryKey: RQ_KEYS.DEPARTMENT_LIST,
        url: `${API_ROUTE.GET_DEPARTMENT_LIST}?limit=${dataLimit.limit}&offset=${offset}`,
        requestType: REQUEST_TYPE._GET,
      },
      {
        onSuccess: (response) => {
          setTableData(response?.data?.data);
          setTotalCount(response?.data?.totalCount);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        }
      }
    );
  };

  const onDeleteSuccess = () => {
    console.log("delete department successful");
    
    setIsLoading(true);
    mutate(
      {
        queryKey: RQ_KEYS.DEPARTMENT_LIST,
        url: `${API_ROUTE.GET_DEPARTMENT_LIST}?limit=${dataLimit.limit}&offset=${dataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      },
      {
        onSuccess: (response) => {
          setTableData(response?.data?.data);
          setTotalCount(response?.data?.totalCount);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        }
      }
    );
  };

  const refreshData = () => {
    setIsLoading(true);
    mutate(
      {
        queryKey: RQ_KEYS.DEPARTMENT_LIST,
        url: `${API_ROUTE.GET_DEPARTMENT_LIST}?limit=${dataLimit.limit}&offset=${dataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      },
      {
        onSuccess: (response) => {
          setTableData(response?.data?.data);
          setTotalCount(response?.data?.totalCount);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        }
      }
    );
  };

  const departmentLoading = isLoading || departments.isLoading;
  
  const displayData = tableData || (departments?.data?.data || []);
  const displayTotalCount = totalCount || (departments?.data?.totalCount || 0);

  return (
    <React.Fragment>
      <div className={styles.mainContainer}>
        <div className={styles.upperContainer}>
          <div className="contentHeader" style={{ width: "100%" }}>
            <div className={styles.backBtn}>
              <Button
                label=""
                id="backBtn"
                color="var(--blackColor)"
                background="transparent"
                fontSize="var(--secondaryFont)"
                onClick={() => {
                  navigate(`${APP_PATHS.MANAGE_ORGANIZATION}`);
                }}
                border="none"
                Icon={<BackIcon className="svg_image_icon" />}
              />
            </div>
            <Breadcrumb
              pageName={`Manage Departments`}
              showBtn="true"
            />
            <div></div>
          </div>
          <div className={styles.mainContainerInner}>
            <div className={styles.buttonContainer}>
              <Button
                label="Add Department"
                onClick={() => {
                  setModal({
                    state: true,
                    modalName: ORGANIZATION_MODAL_NAMES.ADD_DEPARTMENT,
                    type: "CREATE",
                  });
                }}
                id="editbtn"
                Icon={
                  <EditIcon fill="var(--whiteColor)" className={`${styles.EditIcon} svg_image_icon`} />
                }
              />
            </div>
          </div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.tableContainer}>
            {!departmentLoading ? (
              <>
                <Table
                  tableHeaders={[
                    "Department ID",
                    "Department Name",
                  ]}
                  tableData={displayData && displayData.map((item: any) => ({ ...item }))}
                  hideColumns={[
                    "org_id",
                    "disabled"
                  ]}
                  action={{
                    name: "Action",
                    buttonOne: (data: any) => (
                      <Button
                        onClick={() => {
                          console.log("Edit Clicked", data)
                          setModal({
                            state: true,
                            modalName: ORGANIZATION_MODAL_NAMES.ADD_DEPARTMENT,
                            type: "UPDATE",
                            data: data
                          });
                        }}
                        gap="10px"
                        label="Edit"
                        id="1"
                        Icon={
                          <EditIcon fill="#ffffff" className="svg_image_icon" />
                        }
                      />
                    ),
                    buttonTwo: (data: any) => (
                      <Button
                        onClick={() => {
                          console.log("Delete Clicked")
                          setModal({
                            state: true,
                            modalName: ORGANIZATION_MODAL_NAMES.DELETE_DEPARTMENT,
                            type: "DELETE",
                            data: data
                          });
                        }}
                        gap="10px"
                        label="Delete"
                        background="#fd3a3a"
                        id="2"
                        Icon={
                          <DeleteIcon fill="#ffffff" />
                        }
                      />
                    ),
                  }}
                />
              </>
            ) : (
              <div className={styles.departmentLoader}>
                <Spinner animation="border" variant="primary" />
              </div>

            )}
            {displayTotalCount === 0 && (
              <div style={{ textAlign: "center", margin: "30px" }}>
                No Department Found
              </div>
            )}
          </div>
          {!departmentLoading && (
            <div className={styles.pagination}>
              <Pagination
                data={displayData}
                totalCount={displayTotalCount}
                onPaginzationChange={onPaginzationChange}
                setDataLimit={setDataLimit}
                dataLimit={dataLimit}
              />
            </div>
          )}
        </div>
      </div>

      {showModal.state &&
        showModal?.modalName === ORGANIZATION_MODAL_NAMES.ADD_DEPARTMENT && (
          <AddDepartmentPopup
            heading={showModal?.type === "UPDATE" ? "Edit Department" : "Add Department"}
            openState={showModal}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            onSuccess={refreshData}
            type={showModal?.type}
            data={showModal?.data}
          />
        )}
      {showModal &&
        showModal.state &&
        showModal.modalName === ORGANIZATION_MODAL_NAMES.DELETE_DEPARTMENT && (
          <DeleteDepartment
            openState={showModal}
            heading="Delete Department"
            type={showModal?.type}
            data={showModal.data}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            onSuccess={onDeleteSuccess}
          />
        )}
    </React.Fragment>
  );
};

export default ManageDepartmentPage;