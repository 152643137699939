import * as Yup from "yup";
const AddDepartmentSchema = Yup.object().shape({
  department_name: Yup.string()
    .required("Department Name is required")
    // .matches(
    //   /^[a-zA-Z\s&]*$/,
    //   "Please enter a valid name with only alphabets and spaces."
    // ),
});

const AddDepartmentValue = {
  department_name: "",
};
export { AddDepartmentSchema, AddDepartmentValue };
